@import "assets/scss/variables";

.h1 {
  color: $white;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  margin: 40px 0;
}

.h2 {
  color: $white;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 24px 0;
}

.innerHeading {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}

.lineIcon {
  display: block;
  margin: 6px auto 0;
  max-width: 100%;
  width: 130px;
}

.subtitle {
  color: $wcm_yellow;
  font-family: $font-family-chappell;
  font-size: 29px;
  font-weight: 400;
  margin-top: -7px;
}

.subtitleRu {
  font-size: 18px;
  margin-top: 0;
}

.withGoldBorder,
.withSubtitle {
  font-family: $font-family-plaak;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
}
