@import 'assets/scss/functions';

$animation-duration: 0.2s;



.toggle {
  cursor: pointer;
  flex-shrink: 0;
  flex-wrap: wrap;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  gap: 10px;
  font-size: toRem(13px);
  line-height: toRem(15px);
  font-weight: 800;
  font-family: "Plaak 6 Ney";

  input {
    display: none;
  }

  .sliderContainer {
    display: flex;
    justify-content: center;
    width: 30px;
  }

  input:checked + .sliderContainer .slider {
    background-color: #00813D;
    border-color: #00813D;

    &:before {
      align-self: flex-end;
    }
  }

  input.isLoading + .sliderContainer .slider {
    padding: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 3px solid;
    border-right-color: gray;
    animation: loading 1s infinite cubic-bezier(0, .1, 1, .9);
    animation-delay: $animation-duration;

    &:before {
      width: 19px;
      height: 19px;
      align-self: center;
    }
  }

  .slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    padding: 0 5px;
    border: 0 solid #ccc;
    border-radius: 25px;
    background-color: #ccc;
    transition-property: background-color, width, height, border-width, border-radius, padding;
    transition-duration: $animation-duration;
    transition-timing-function: ease-in-out;

    &:before {
      content: '';
      display: block;
      background: black;
      height: 10px;
      width: 10px;
      align-self: flex-start;
      border-radius: 50%;
      transition-property: width, height;
      transition-duration: $animation-duration;
      transition-timing-function: ease-in-out;
    }

  }

}


@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}