@import "assets/scss/variables";

.checkbox {
  align-items: center;
  display: flex;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  @media (min-width: 640px) {
    align-items: center;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
  }
}

.emailField {
  margin-top: 24px;
}

.forgotLink {
  color: $wcm_yellow;
  display: inline-block;
  font-size: 11px;
  margin-top: 5px;
  &:focus,
  &:hover {
    color: $wcm_yellow_hover;
  }
}

.form {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 20px;
  color: $white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 40px 30px;
  max-width: 580px;
  @media (min-width: 768px) {
    margin-top: 0;
    padding: 100px;
  }
}

.h2 {
  margin: 0;
}

.passwordField {
  margin-top: 20px;
}

.responseAlert {
  margin-top: 20px;
  max-width: 100%;
}

.toolbar {
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}

.toolbarLangSpecific {
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 640px) {
    align-items: center;
    flex-direction: row;
    gap: 30px;
  }
}

.troubleLoginLink {
  @media (min-width: 640px) {
    text-align: right;
  }
}
