@import "assets/scss/variables";
.container {
  //margin-bottom: 20px;
}
.arrow {
  width: 12px;
  height: 13px;
  //position: absolute;
  right: 15px;
  margin-left: 22px;
  transform-origin: center;
  top: 50%;
  //transform: translateY(-50%);
  vertical-align: middle;
  &_open {
    transform:rotate(180deg);
  }
}
.select {
  position: relative;
  //min-width: 148px;
  padding: 10px 11px 10px 15px;
  background-color: #fff;
  color: black;
  border-radius: 6px;
  margin-bottom: 20px;
  display: inline-block;
  &_title {
    font-weight: 600;
  }
}
.container {
  position: relative;
}
.menu {
  position: absolute;
  top: -15px;
  width: 148px;
  left: 0;
  z-index: 2;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: -1px 1px 20px -10px black;
}
.item {
  padding: 18px 20px;
  border-top: 1px solid rgb(146, 146, 146);
  color: #000;
  &_active {
    color: rgba(0, 0, 0, 0.5);
  }
  &:nth-of-type(1){
    border-top:none;
  }
}

.loading{
  filter: blur(1.5px);
  background-color: rgba(255,255,255,0.95);
}