@import "assets/scss/variables";

.chartCard {
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 20px 30px 30px;
  max-height: 363px;
  background-color: $black_0;
  max-width: 580px;
  min-height: 363px;

  .title {
    color: $white;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0 10px 0 0;
    @media (max-width: 768px) {
      width: calc(100% - 120px);
    }
  }
}

.wrap {
  display: flex;
  justify-content: space-between;

  .circle {
    position: relative;
    min-width: 50%;

    .total {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 80px;
      left: 90px;
    }
  }

  .isList {
    min-width: 100%;
  }
}

.declarerCode {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-left: 33px;
  max-width: 239px;
  font-size: 14px;
  margin-top: 5px;

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-top: 1px solid $gray-146;

    .value {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: $white;
    }

    .count {
      color: $white;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }

    .percent {
      color: $wcm_green;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;

      .upIcon {
        margin-left: 3px;
      }
    }

    .isIncrement {
      color: $wcm_danger
    }

    .line {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .laneWrap {
      display: flex;
      align-items: center;
    }
  }
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
}

.wrapCount {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.itemsHeader {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: $white;
  font-size: 13px;
  border-bottom: 1px solid $white;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid $orange;
  }
}

.load {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20% auto;
}

@media (max-width: 1280px) {
  .chartCard {
    max-width: 100%;
    max-height: fit-content;
    margin-bottom: 20px;
  }
  .header {
    margin-bottom: 29px;
  }
}

@media (max-width: 568px) {
  .chartCard {
    padding: 20px 0 0 0;
    border-radius: 0;
    border: none;
    border-top: 1px solid $gray-146;
    min-height: 200px;
    font-size: 11px !important;
  }
  .declarerCode {
    margin-left: 0;
    max-width: 100%;
  }
  .wrap {
    flex-direction: column;
    width: calc(100% - 6px);
  }
  .circle {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    width: 100%;
  }
}