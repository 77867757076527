@import "../../../../assets/scss/variables";

.menu {
  background: $wcm_dark;
  position: absolute;
  bottom: toRem(30);
  color: $white;
  max-width: toRem(500);
  z-index: 10000;

  div {
    padding: toRem(5) toRem(20);
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: $gray_lighter;
      color: $black;
    }
  }
}

.dropInDown {
  top: toRem(30);
  bottom: auto;
}

.dropdown {
  position: relative;
}

.controlClassName {
  display: flex;
  cursor: pointer;
}

.arrowClassName {
  margin-left: toRem(8);
}

.closedArrowClassName {
  margin-left: toRem(8);
  transform: rotate(180deg);
}