@import "assets/scss/variables";
.table {
  border-spacing: 0;
  background: #fff;
  position: relative;
  height: fit-content;
  table-layout: fixed;
  width: 100%;
  font-size: 13px;
  tbody {
    tr:hover {
      background-color: $gray-249;
    }
  }
  :global(.table_heading) {
    text-overflow: ellipsis;
    overflow: hidden;
    width:fit-content;
    white-space: nowrap;
    @media (max-width: 1181px) {
      text-overflow: inherit;
      overflow: visible;
      white-space: inherit;
    }
  }
}

.loading{
  position: absolute;
  top:0;
  backdrop-filter: blur(2px);
  background-color: rgba(0,0,0,0.2);
  font-size: 25px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row{
  border-bottom: 1px solid $gray-146;
}

.table tr:last-child td {
  border-bottom: 0;
}
.table th,
.table td {
  margin: 0;
  padding: 0 20px;
  position: relative;
  font-size: 13px;
  line-height: 16px;
  color: #383838;
  font-weight: 400;
  @media (max-width: 500px) {
    padding: 0 10px;
  }
}
.table th {
  text-align: left;
  text-transform: capitalize;
  color: black;
  cursor: pointer;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  button {
    @media (max-width: 500px) {
      //position: absolute;
      //right: 10px;
    }
  }
  span {
    //position: absolute;
  }
}
.table tr {
  min-height: 54px;
  td{
    height: 54px;
  }
}

thead{
  background-color: $gray-244;
  th{
    border-bottom: 2px solid $gray-146 !important;
    color: #181d1f;
    font-weight: 800 !important;
    height: 46px !important;
    display: flex;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    @media (max-width: 1181px) {
      white-space: inherit;
    }
  }
  tr{
    min-height: 46px !important;
  }
}
.table td {
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.table th:last-child,
.table td:last-child {
  border-right: 0;
}

.table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.lg {
  td {
    height: 60px;
  }
}

.md {
  td {
    height: 45px;
  }
}

.isOpen{
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.open{
  max-height: 500px;
  transition: all 1s ease;
}

.sm {
  width: 960px;
  border: 1px solid $gray-146;
  td {
    height: 35px;
  }
  th,
  td {
    padding: 5px 5px;
    font-size: 13px;
  }
}

.paginationWrap{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  font-size: 13px;
  .totalItems{
    color: red;
    margin-left: 4px;
  }
  .records{
    color: red;
  }
}

.noResult{
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
