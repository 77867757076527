@import "../../../../assets/scss/variables";

.backButton {
  margin-top: 16px;
}

.checkboxContainer {
  margin-top: 32px;
}

.codeInput {
  margin-top: 32px;
}

.form {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 8px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  max-width: 100%;
  min-height: 360px;
  padding: 32px;
  text-align: center;
  width: 400px;
}

.error {
  margin-bottom: 16px;
  max-width: 100%;
  text-align: initial;
}

.header {
  font-family: $font-family-plaak;
  font-size: 22px;
  line-height: 26px;
  margin: 8px 0 20px;
}

.pushSentIcon {
  color: #00813d;
  height: 32px;
  width: 32px;
}

.pushSentMessage {
  font-family: $font-family-plaak;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 0;
}
