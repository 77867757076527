.modal {
  display: inline-flex;
  padding: 25px 32px;
  flex-direction: column;
  align-items: flex-start;
  width: 363px;
  gap: 10px;

  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: bold;

    svg {
      fill: red;
    }
  }

  .list {

    .listItem {
      display: flex;
      gap: 10px;

      .fileName {
      }

      .fileError {
        color: red;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0) !important;
  z-index: 1;
}
