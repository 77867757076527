//Colors
$black: #000000;
$white: #ffffff;
$gray: rgba(100, 100, 100, 1);
$gray_hover: rgba(20, 20, 20, 1);
$orange: rgba(217, 168, 27, 1);
$wcm_gray: #C1C4CC;
$wcm_dark: rgba(56, 56, 56, 1);
$primary_btn_hovered: rgba(17, 24, 39, 1);
$wcm_gray_darker: rgba(63, 63, 63, 1);
$wcm_yellow: #D9A81BFF;
$wcm_green: rgba(0, 134, 66, 1);
$wcm_green174: rgba(0, 174, 82, 1);
$wcm_danger: rgba(215, 46, 61, 1);
$wcm_danger2: rgba(212, 43, 58, 1);
$wcm_warning: rgba(180, 84, 0, 1);


$black: #000;
$black_56: rgba(56, 56, 56, 1);
$black_0: rgba(0, 0, 0, 1);
$white: #fff;
$gray_lighter: rgba(214, 220, 227, 1);
$gray_light: rgba(203, 203, 203, 1);
$wcm_yellow_hover: rgba(186, 152, 3, 1);
$gray-146: rgba(146, 146, 146, 1);
$gray-244: rgba(244, 244, 244, 1);
$gray-249: rgba(249, 249, 249, 1);
$gray-229: rgba(229, 229, 229, 1);
$gray-22: rgba(22, 17, 3, 1);
$gray-203: rgba(203, 203, 203, 1);
$gray-0: rgba(0, 0, 0, 0.3);
//Fonts
$font-family-plaak: Plaak\ 6 Ney, sans-serif, Arial;
$font-family-suisse: "Suisse Int'l", 'sans-serif', 'Arial';
$font-family-chappell: "Chappell Longhand", 'sans-serif', 'Arial';
$font-size: 13;
//MEDIA
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}


//FUNCTIONS
@function toRem($wanted) {
  @return ($wanted / $font-size) + rem;
}
