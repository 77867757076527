@import "assets/scss/variables";

.container {
  display: flex;
  font-size: 13px;
  gap: 48px;
  line-height: 16px;
  margin: 20px 0;
  @media (max-width: 767px) {
    display: block;
  }
}

.controlLabel {
  @media (min-width: 768px) {
    margin-top: 10px;
  }
}

.controlValue {
  align-items: center;
  display: flex;
  @media (min-width: 768px) {
    min-height: 35px;
  }
}

.label {
  color: $wcm_gray;
  flex-shrink: 0;
  font-weight: 600;
  width: 180px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
