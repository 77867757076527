@import "assets/scss/variables";

.container {
  .h2 {
    margin: 0;
  }
}

.details {
  display: flex;
  gap: 12px;
  flex-direction: column;
  @media (min-width: 768px) {
    display: grid;
    gap: 20px 24px;
    grid-template-columns: 1fr 1fr;
  }
}

.header {
  display: flex;
  flex-direction: column;
  @media (min-width: 640px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.hr {
  border: none;
  border-top: 1px solid #ffffff1f;
  margin: 24px 0;
}

.paymentScheduled {
  font-style: italic;
  margin-top: 32px;
  @media (min-width: 768px) {
    text-align: right;
  }
}

.period {
  color: $white;
  margin-top: 4px;
}

.row {
  display: flex;
  gap: 30px;
  flex-direction: column;
  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
