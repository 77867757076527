.filterBar {
  display: flex;
  margin-bottom: 25px;

  div {
    margin-right: 28px;
  }
}
@media (max-width: 1024px) {
  .filterBar {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}