@import "../../../../assets/scss/variables";

.wrapper {
  position: relative;
}

.icon {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0;
  bottom: 0;
  margin: auto;

  &_search {
    right: 10px;

    &_notempty {
      path {
        fill: $gray-203
      }
    }
  }

  &_clear {
    right: 33px;
    cursor: pointer;

    :global(.notHoveredIcon) {
      z-index: 1;
    }

    :global(.hoveredIcon) {
      z-index: 2;
      display: none;
    }

    &:hover {
      :global(.notHoveredIcon) {
        display: none;
      }

      :global(.hoveredIcon) {
        display: block;
      }
    }
  }
}

.field {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 2px solid $gray-146;
  height: 35px;
  color: $gray-203;
  font-size: 13px;
  width: 280px;
  padding-left: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  text-overflow: ellipsis;
  padding-right: 30px;
  &_hasValue {
    padding-right: 55px;
  }

  &::-webkit-input-placeholder {
    color: $gray-146;
    font-style: italic;
    font-size: 13px;
    text-overflow: ellipsis;
  }

  &:focus {
    border-bottom: 2px solid $gray-203;

    &::-webkit-input-placeholder {
      color: $gray-203;
      font-style: normal;
    }

    + svg {
      path {
        fill: $gray-203
      }
    }
  }

  @media (max-width: 990px) {
    width: 240px !important;
  }
  @media (max-width: 750px) {
    width: 200px !important;
  }

  &_light {
    border-bottom: 2px solid $gray;
    background-color: #fff;
    color: $black_0;
    width: 180px;

    &::-webkit-input-placeholder {
      color: $gray;
      font-size: 11px;
    }

    &:focus {
      border-bottom: 2px solid $black_0;

      &::-webkit-input-placeholder {
        color: $gray;
      }

      + svg {
        path {
          fill: $gray
        }
      }
    }
  }
}