@import "assets/scss/variables";

.actions {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 10px;
  text-align: left;
}

.confirmPassword {
  margin-top: 5px;
}

.container {
  background-color: $black;
  border: 1px solid $orange;
  border-radius: 20px;
  color: $white;
  max-width: 580px;
  margin: 64px auto;
  padding: 100px 16px;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.error {
  margin-bottom: 20px;
  max-width: 100%;
  text-align: left;
}

.form {
  margin: auto;
  max-width: 380px;
}

.h2 {
  margin-top: 0 !important;
}

.link {
  border-bottom: 1px solid $wcm_yellow;
  color: $wcm_yellow;
  &:focus,
  &:hover {
    border-color: $wcm_yellow_hover;
    color: $wcm_yellow_hover;
  }
}

.password {
  margin-top: 36px;
}

.success {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 20px;
  margin: 64px auto;
  max-width: 580px;
  padding: 50px 47px;
  text-align: center;
}

.successCloseButton {
  margin-top: 20px;
}
