@import "assets/scss/variables";

.wrapper {
  text-align: center;
  z-index: 10000000;
}

.popup {
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #D9A81B;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000000000;
  color: white;
  background: #000;

  &_big {
    max-width: 480px;
  }

  &_small {
    max-width: 435px;
    @media (max-width: 650px) {
      text-align: center;
    }
  }

  &_medium {
    max-width: 473px;
    @media (max-width: 650px) {
      text-align: center;
    }

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 650px) {
    max-width: 300px;
    padding: 40px 15px;
    top: 50%;
    p {
      font-size: 13px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  background-color: rgba(20, 20, 20, 0.3);
}

.title {
  color: white;
  font-size: toRem(26);
  margin-bottom: toRem(30);
  margin-top: 0;
  position: relative;
  @media (max-width: 650px) {
    font-size: toRem(20);
  }
}

.title_small {
  color: white;
  font-size: toRem(20);
  margin-bottom: toRem(20);
  margin-top: 0;
  position: relative;
}

.success_text {
  font-size: toRem(16);
}

.questions {
  margin-top: toRem(40);
  display: flex;
  gap: 100px;

  a {
    text-decoration: underline;
  }

  @media (max-width: 650px) {
    display: block;
    p {
      max-width: 200px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.info {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: toRem(20);
}

.input {
  width: 280px;
  position: relative;
  z-index: 3;

  :global(.inputLabel) {
    font-weight: 600;
    color: white;
  }

  &_field {
    position: relative;
  }

  &_currency {
    //position: absolute;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }

  &_withCurrency {
    input {
      padding-left: 5px;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
  }
}

.shadow {
  position: absolute;
  width: 100%;
  transition: opacity 150ms ease-in-out;
  height: auto;
  left: 0;
  z-index: 2;
  opacity: 0;

  &_bottom {
    top: 130px;
    box-shadow: 0 0px 70px 60px black;
  }

  &_top {
    top: 0;
    box-shadow: 0px 15px 70px 65px black;
  }
}

.wrap {
  &_shadowUp {
    position: relative;

    .shadow_top {
      opacity: 1;
    }
  }

  &_shadowDown {
    position: relative;

    .shadow_bottom {
      opacity: 1;
    }
  }
}

.textarea {
  label {
    margin-bottom: toRem(5);
    display: inline-block;
    margin-top: toRem(20);
    position: relative;
    z-index: 3;
  }

  textarea {
    border: 1px solid white;
    background-color: transparent;
    color: white;
    padding-left: 1rem;
    padding-top: toRem(10);
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: toRem(80);
    max-height: 130px;
    font-family: inherit;

    &::-webkit-scrollbar-thumb {
      background-color: #a0a0a0;
      border-radius: 4px;
    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 8px;
      height: 0 !important;
    }

    &::placeholder {
      color: $gray-146;
    }
  }
}

.information {
  max-height: 130px;
  overflow-y: auto;

  &::-webkit-scrollbar-track-piece {
    background: #e2e2e2;
    border-radius: 4px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 35px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: toRem(30);
  gap: toRem(15);
  position: relative;
  z-index: 3;
}

.icon {
  width: 16px;
  height: 16px;
  flex-shrink: 1;
}