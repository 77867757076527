@import "assets/scss/variables";

.name {
  line-height: 16px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  //display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 16px;
  font-weight: 600;
}

.PayeeCodeInfo {
  margin-right: toRem(100);

  :global(.payeeCodeLabel) {
    text-transform: capitalize;
  }

  &_small {
    margin-right: toRem(122);

    :global(.payeeCodeLabel) {
      text-transform: capitalize;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}


.code {
  min-width: calc(20% - 5px);
  max-width: calc(20% - 5px);
  border: 1px solid #383838;
  border-radius: 10px;
  font-size: 11px;
  line-height: 11px;
  color: $black;
  padding: 5px 10px;
  margin: 0 5px 0 0;
  display: none;
  align-items: center;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  justify-content: center;

  .desc {
    height: 11px;
    font-size: 11px;
    line-height: 11px;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }

  &:hover {
    color: $white;
    background-color: $wcm_dark;

    .desc {
      color: $white;
      border-bottom: 1px solid $white;
      max-width: 100%;
      overflow: hidden;
      text-align: left;
    }
  }
}

.table {
  &_nodata {
    :global(.ag-body-viewport.ag-row-animation.ag-layout-auto-height) {
      background-color: rgba(255, 255, 255, 1);
    }

    :global(.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper) {
      font-size: 16px;
      font-style: italic;
      color: black;
    }
  }

  div {
    overflow: revert !important;
  }

  :global(.ag-body-viewport) {
    overflow: revert !important;
  }

  :global(.ag-center-cols-container) {
    div {
      overflow: revert !important;
    }

    min-height: 55px !important;
    height: 55px !important;
  }

  :global(.ag-center-cols-viewport) {
    min-height: 55px !important;
  }

  :global(.ag-center-cols-clipper) {
    min-height: 55px !important;
  }

  &_title {
    color: white;
    font-size: toRem(20);
    margin-bottom: toRem(25);
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_filter {
    width: 280px;
    padding: toRem(9) 20px;
    color: white;
    border: none;
    border-bottom: 2px solid #929292;
    outline: none;
    background-color: transparent;
    background-image: url("../../icons/filter_search.svg");
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: 90% center;

    &::-webkit-input-placeholder {
      color: $gray-146;
      font-style: italic;
      font-size: 13px;
    }
  }

  :global(.ag-row-group-expanded) {
    border-bottom: none;
  }

  :global(.ag-group-contracted) {
    :global(.ag-icon ) {
      background-image: url("../../icons/chevrons_down.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-group-expanded ) {
    :global(.ag-icon ) {
      background-image: url("../../icons/chevrons_up.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-theme-alpine) {
    font-family: $font-family-suisse;
  }

  :global(.ag-header) {
    border-bottom: 2px solid $gray-146;
    background-color: $gray-244;
    overflow: visible;
    color: black;

    :global(.ag-header-viewport) {
      overflow: visible;
    }
  }

  :global(.ag-header-cell) {
    padding-left: toRem(35);
    padding-right: 0;

    &:first-child {
      padding-left: toRem(35);
    }
  }

  &_detail {
    display: flex;
    padding-left: toRem(150);
    //justify-content: space-between;
    height: 55px;
    font-size: 11px;
    align-items: center;

    svg {
      width: 11px;
      height: 11px;

      path {
        fill: black;
      }
    }
  }
}

.headerClass {
  padding-left: 55px !important;
}


.tableReact {
  tbody {
    tr {
      position: relative;
      cursor: pointer;

      &:hover {
        .code {
          display: flex;
        }

        .countLength {
          display: none;
        }

        .nextIcon {
          display: flex;
        }

        .backIcon {
          display: flex;
        }
      }
    }
  }

  &_detail {
    display: flex;
    padding-left: toRem(150);
    //justify-content: space-between;
    height: 55px;
    font-size: 11px;
    align-items: center;

    svg {
      width: 11px;
      height: 11px;

      path {
        fill: black;
      }
    }
  }

  &_row {
    border-bottom: 1px solid $gray-146;
    background-color: #fff !important;
    cursor: pointer;

    &:hover {
      background-color: $gray-249 !important;
    }

    :global(.ag-cell) {
      display: flex;
      align-items: center;
      white-space: break-spaces;
      font-size: 13px;
      padding-left: toRem(35);
      color: black;
      background-color: transparent !important;
      border: none !important;
    }
  }
}

.select {
  //background: #000000;
  //border: 1px solid #CBCBCB;
  min-width: 270px;
  height: 50px;

  :global(.select__menu-notice--no-options) {
    color: white;
    text-align: left;
  }

  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    border-left: 0;
    background-image: url("../../img/option-check.svg");
    background-repeat: no-repeat;
    background-position: 1.5rem 0.8rem;
    padding-left: calc(1.5rem + 24px);
    position: relative;

    &:hover {
      background-color: $wcm_dark;
      border-left: 0;

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $orange;
      }
    }
  }

  :global(.select__menu) {
    top: calc(100% + 5px);
    border-top: 1px solid #CBCBCB;
    border-radius: toRem(6);
  }
}

.payeeCodeDropDown {
  width: 270px;
}

.menu {
  width: 270px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.wrap {
  display: flex;
}

.ledger_block {
  display: flex;
  color: white;
  padding-left: toRem(100);
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button {
  &:hover {
    background-color: $wcm_yellow;
  }
}

.payeeCodeBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: toRem(100);
  color: white;

  &_big {
    padding: toRem(40);
    margin-top: toRem(50);
    border: 1px solid #929292;
    border-radius: 6px;
    background-color: #000000;
  }
}

.value {
  position: absolute;
  margin-left: 35px;
  text-overflow: ellipsis;
  overflow: hidden !important;
  width: calc(100% - 108px);
  white-space: nowrap;
  height: 60px;
  display: flex;
  align-items: center;

  .tool {
    p {
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 4px;
    }
  }
}

.test {
  background-color: red !important;
}


.countLength {
  display: flex;
}

.accountTitle {
  z-index: 1000;
  height: 100%;
  width: calc(100% + 40px);
}


.elements {
  display: flex;
  width: 100%;
  transition: all 0.5s ease;
}

.backIcon {
  margin-right: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  height: 25px;
  fill: black;
  transform: rotate(-90deg);
  min-width: 40px;

  path {
    fill: black;
    height: 11px;
  }

  &:hover {
    path {
      fill: $orange;
    }
  }
}

.payeeTitle {
  position: fixed;
  padding-left: calc(39% + 35px);
  left: 0;
}

.nextIcon {
  position: absolute;
  right: 8px;
  display: none;
  align-items: center;
  justify-content: center;
  height: 25px;
  fill: black;
  min-width: 50px;

  .arrowNext {
    transform: rotate(90deg);
  }

  path {
    fill: black;
    height: 11px;

    &:hover {
      fill: $orange;
    }
  }

  .count {
    margin-right: 8px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    padding: 0;
    border-bottom: 1px solid $black;

    &:hover {
      border-bottom: 1px solid $orange;
      color: $orange
    }
  }
}

.nextWrap {
  padding: 0;

  &:hover {
    path {
      fill: $orange
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.active {
  display: flex !important;
}

@media only screen and (max-width: 768px) {
  .payeeTitle {
    padding-left: calc(70% + 35px);
    right: 0;
  }
}
