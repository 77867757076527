@import "../../../assets/scss/variables";

.privacy {
  .underline {
    text-decoration: underline;
  }
  .marginTop0 {
    margin-top: 0 !important;
  }
  .border {
    border: 1px solid $gray-146;
  }

  width: 100%;
  z-index: 100000000000000 !important;

  .header {
    margin: auto;
    width: 75%;
    text-align: center;
    margin-top: toRem(30);
    font-weight: 800;
    font-size: toRem(22);
    line-height: toRem(26);
    font-family: $font-family-plaak;
    color: $white;
  }

  .content {
    z-index: 100000000000000 !important;
    max-width: toRem(979);
    width: 100%;
    margin: toRem(28) auto toRem(150) auto;
    box-sizing: border-box;

    .updateTxt {
      text-align: end;
      font-weight: 600;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .headTxt {
      margin-top: toRem(10);
      font-weight: 600;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .headTxt2 {
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(24);
      font-family: $font-family-suisse;
      color: $white;
    }

    .orangeTxt {
      color: $wcm_warning;
    }

    .txt {
      margin-top: toRem(10);
      font-weight: 400;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .txtUnderline {
      text-decoration: underline;
    }

    .toggleRows {
      margin-top: toRem(60);

      .rowWrapper {
        border-bottom: 1px solid $gray-146;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: toRem(21) toRem(15);
          cursor: pointer;
          color: $white;

          .firstPart {
            display: flex;
            flex-direction: row;
            align-items: center;

            .numTxt {
              width: toRem(20);
              font-weight: 400;
              font-size: toRem(13);
              line-height: toRem(16);
              font-family: $font-family-suisse;
              color: $white;
            }

            .nameTxt {
              margin-left: toRem(30);
              font-weight: 600;
              font-size: toRem(16);
              line-height: toRem(20);
              font-family: $font-family-suisse;
              color: $white;

              &.opened {
                color: $orange;
              }
            }
          }

          &:hover {
            background-color: $wcm_dark;

            .firstPart {
              .nameTxt {
                color: $orange !important;
              }
            }
          }
        }

        .rowContent {
          padding-bottom: toRem(20);
          padding-left: toRem(65);

          .marginTop {
            margin-top: toRem(40);
          }

          .subContent {
            margin-left: toRem(25);
            width: 100%;

            .subsSubContentWrapper {
              display: flex;
              flex-direction: row;
              padding: toRem(15);
              border-bottom: 1px solid $gray-146;

              .subSubContent {
                width: 50%;
                padding-right: toRem(15);

                .smallBoldTxt {
                  font-weight: 600;
                  font-size: toRem(13);
                  line-height: toRem(16);
                  font-family: $font-family-suisse;
                  color: $white;
                }

                .italicTxt {
                  font-style: italic;
                  font-weight: 600;
                  font-size: toRem(13);
                  line-height: toRem(16);
                  font-family: $font-family-suisse;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .privacy {
    .content {
      //max-width: rem(800);
    }
  }
}

@media screen and (max-width: 768px) {
  .privacy {
    margin-top: 20px;

    .header {
      max-width: 100%;
      margin: auto;
      width: 100%;
    }

    .content {
      //max-width: rem(620);
    }
  }
}

@media screen and (max-width: 660px) {
  .privacy {
    .header {
      margin-top: toRem(50);
    }

    .content {
      //max-width: rem(340);

      .updateTxt {
        font-size: toRem(13);
        line-height: toRem(16);
      }

      .headTxt {
        margin-top: toRem(14);
        font-size: toRem(13);
        line-height: toRem(16);
      }

      .toggleRows {
        .rowWrapper {
          .row {
            .firstPart {
              .numTxt {
                width: toRem(5);
              }

              .nameTxt {
                font-size: toRem(13);
                line-height: toRem(16);
              }
            }
          }

          .rowContent {
            padding-bottom: toRem(20);
            padding-left: toRem(26);
            font-size: toRem(13);
            font-weight: 400;
            line-height: toRem(16);

            .subContent {
              margin-left: toRem(10);

              .subsSubContentWrapper {
                .subSubContent {
                  font-size: toRem(11);
                  line-height: toRem(16);
                  font-weight: 400;

                  .smallBoldTxt {
                    font-size: toRem(11);
                    line-height: toRem(13);
                  }

                  .italicTxt {
                    font-size: toRem(11);
                    line-height: toRem(16);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
