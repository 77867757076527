@import "assets/scss/variables";

.setContactUsModal {
  text-align: center;
  //width: fit-content;
}

.container {
  border: 1px solid $orange;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: white;
  background-color: black;
  position: relative;
  max-width: 550px;
  min-width: 550px;
  font-size: toRem(11);
  padding: toRem(40);
  @media (max-width: 750px) {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);

    padding: toRem(20);
    //margin: 30% auto;
    //max-height: 485px;
    //overflow: hidden;
  }
}

.title {
  font-size: toRem(26);
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-bottom: toRem(20);
  max-width: calc(100% - 40px);
  @media (max-width: 750px) {
    font-size: toRem(20);
  }
}

.wrap {
  max-height: 300px;
  overflow-y: auto;
}

.row {
  display: flex;
  margin-bottom: 10px;
  font-size: toRem(13);

  p {
    width: 250px;
    margin-right: 40px;

    &:nth-of-type(2) {
      margin-right: 0;
      width: auto;
    }
  }

  &_select {
    margin-top: 20px;
  }

  @media (max-width: 750px) {
    display: block;
    p {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
}

.body {
  padding: toRem(40);
}

.subtitle {
  font-weight: 600;
  font-size: toRem(11);
  margin-top: toRem(10);
  margin-bottom: toRem(15);
}

.optionGroup {
  &:nth-of-type(1) {
    padding-top: 1.2rem;
  }

  &_item {
    padding: .6rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 13px;
    }

    svg {
      transform: rotate(180deg);
    }

    &_open {
      svg {
        transform: rotate(0);
      }
    }
  }
}

.contactUsModal {
  width: fit-content !important;
  //padding: 0 !important;
  //margin: 0 auto;
  //height: fit-content;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  @media (max-width: 750px) {
    width: 100% !important;
  }
}

.wrapper {
  position: relative;
  @media (max-width: 1024px) {
    left: 50% !important;
    top: 50% !important;
    position: absolute;
    transform: translate(-50%, -50%);
    max-width: 375px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.select {
  :global(.select__menu-list) {
    max-height: 218px;
  }

  &_menuOpened {
    :global(.select__control--menu-is-open) {
      :global(.select__dropdown-indicator) {
        transform: rotate(180deg);
      }
    }
  }

  width: 250px;
  //height: 50px;
  :global(.select__value-container.select__value-container--has-value) {
    padding-right: 0;
  }

  :global(.select__menu-notice--no-options) {
    color: white;
    text-align: left;
  }

  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    border-left: 0;
    background-image: url("../../../../img/option-check.svg");
    background-repeat: no-repeat;
    background-position: 1.5rem 0.8rem;
    padding-left: calc(1.5rem + 24px);
    position: relative;

    &:hover {
      background-color: $wcm_dark;
      border-left: 0;

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $orange;
      }
    }
  }

  :global(.select__menu) {
    top: calc(100% + 5px);
    border-top: 1px solid #CBCBCB;
    border-radius: toRem(6);
    @media (max-width: 990px) {
      width: 215px !important;
    }
    @media (max-width: 750px) {
      min-width: 100%;
    }
  }

  @media (max-width: 990px) {
    min-width: 175px;
    height: 40px;
  }
  @media (max-width: 750px) {
    min-width: 100%;
  }
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  color: $white;
  @media (max-width: 750px) {
    top: 20px;
    right: 20px;
  }
}

.hide {
  display: none;
}

.buttonsWrap {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
  }
}

.show {
  display: block;
}

.infoModal {
  border: 1px solid #D9A81B;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background-color: $black;
  width: 430.5px;
  height: 179px;
  margin-top: 50%;
  padding: 40px;
  color: $white;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  overflow-y: auto;

  &_zIndex {
    z-index: 99 !important;
  }

  @media (max-width: 1024px) {
    background-color: rgba(20, 20, 20, 0.65) !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  z-index: 1000 !important;
  background-color: rgba(20, 20, 20, 0.65) !important;
  border: none !important;
  overflow-y: auto;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
