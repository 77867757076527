@import "../../../../assets/scss/variables";

.terms {
  z-index: 100000000000000 !important;
  margin: toRem(44) toRem(19) toRem(120) toRem(19);
  box-sizing: border-box;

  .updateTxt {
    text-align: end;
    font-weight: 600;
    font-size: toRem(13);
    line-height: toRem(16);
    font-family: $font-family-suisse;
    color: $white;
  }

  .headTxt {
    margin-top: toRem(14);
    font-weight: 600;
    font-size: toRem(16);
    line-height: toRem(20);
    font-family: $font-family-suisse;
    color: $white;
  }

  .txt {
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(20);
    font-family: $font-family-suisse;
    color: $white;
  }

  .txtUnderline {
    text-decoration: underline;
  }
}

.buttons {
  padding: 60px 25px 30px;
  text-align: center;
}

.acceptButton {
  background-color: $orange;
  color: $white;
  font-family: $font-family-suisse;
  font-weight: 600;
  width: 100%;
  max-width: 290px;
  height: 50px;
  font-size: toRem(16);
  line-height: toRem(20);
}

.acceptButton:active {
  border: 4px solid $orange;
  background-color: $wcm_yellow_hover;
}

.cancelButton {
  margin-top: 30px;
  color: $white;
  font-family: $font-family-suisse;
  font-weight: 600;
  font-size: toRem(16);
  line-height: toRem(20);
}