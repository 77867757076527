@import 'assets/scss/functions';
@import 'assets/scss/variables.scss';
.files {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;

  flex: 0 1 auto;
  &_count {
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
  }
  &_download {
    svg{
      max-width: 100%;
      max-height: 100%;
      path {
        fill: black;
      }
    }
  }

  div {
    display: flex;
    flex: 0 auto;
    min-width: 0;
    .file {
      border-radius: 12px;
      border: 1px solid #000;
      font-size: 11px;
      padding: 2px 10px;
      display: block;
      max-width: 120px;
      flex: 0 1 auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        border: 1px solid $orange;
        a {
          color: $orange;
        }
      }
    }
  }
}

.payeeCodeMatch {
  background: #f5e4b1;
}
.filter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_chips {
    display: flex;
    gap: 25px;
    align-items: center;
    width: 85%;
    position: relative;
    margin-top: 10px;

    .chips {
      display: flex;
      gap: 5px;
      max-width: 90%;
      overflow-x: auto;
      overflow-y: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 100%;
        z-index: 1;
        right: 114px;
        top: 0;
        pointer-events: none;
        right: 0;
        /* Created with https://www.css-gradient.com */
        background: #FFFFFF;
        background: -webkit-linear-gradient(to right, transparent, #FFFFFF 100%);
        background: -moz-linear-gradient(to right, transparent, #FFFFFF 100%);
        background: linear-gradient(to right, transparent, #FFFFFF 100%);
      }

      ::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &_clear {
        font-style: italic;
        color: $gray;
        cursor: pointer;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  justify-content: flex-start;
  height: 100%;
  font-size: toRem(13px);
  line-height: toRem(16px);
}