.loader circle {
  stroke-dasharray: 2 * 6 * 3.14;
  stroke-dashoffset: 0;
  animation: dash 2s linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes dash {
  to {
    stroke-dashoffset: 10;
    stroke-dashoffset: -4 * 6 * 3.14;
  }
}