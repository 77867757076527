.textareaContainer {

  position: relative;
  .alertMessage {
    height: 70px;
    width: 100%;
    border: 1px solid #CBCBCB;
    padding: 10px 15px;
    border-radius: 0;
    resize: none;
    &::placeholder {
      color: #646464;
      font-style: italic;
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
    }

  }
  .limit {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

}