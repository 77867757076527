@import "assets/scss/variables";

.container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  min-width: 130px;
}

.icon {
  color: #929292;
  cursor: pointer;
  vertical-align: middle;
  width: 16px;
}

.labelText {
  margin-right: 4px;
  vertical-align: middle;
}

.largeContainer {
  gap: 16px;
}

.largeIcon {
  height: 16px;
  margin: 2px;
  width: 16px;
}

.largeValue {
  font-size: 48px;
  line-height: 38px;
}

.smallContainer {
  background-color: #232323;
  border-radius: 4px;
  gap: 4px;
  padding: 12px;
}

.smallIcon {
  height: 11px;
  margin: 1px;
  width: 11px;
}

.smallLabel {
  font-size: 11px;
  line-height: 13px;
}

.value {
  color: $white;
  font-weight: 600;
}