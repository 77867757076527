@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/functions';

.button {
  padding: toRem(6px) toRem(10px);
  display: inline-flex;
  gap: 10px;
  height: toRem(35px);
  padding: 0 toRem(15px);
  line-height: toRem(16px);
  font-size: toRem(13px);
  font-weight: 600;
  box-sizing: content-box;
  align-items: center;
  border-color: $wcm_dark;

  &.small {
    height: toRem(25px);
    line-height: toRem(13px);
    font-size: toRem(11px);
    padding: 0 toRem(10px);
  }

  &.large {
    height: toRem(35px);
    line-height: toRem(16px);
    font-size: toRem(13px);
    padding: 0 toRem(15px);
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.outlined {
    border-radius: 6px;
    border: 1px solid !important;
    text-decoration: none;
    &:enabled:hover {
      background: black;
      color: white;
      box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.20);

      svg path {
        fill: white !important;
      }
    }
  }

  &.text {
    padding: 0;
    border: none;
    background: none;
    color: $wcm_dark;
  }

  &.contained {
    border: none;
    background: none;
    color: $wcm_dark;
  }

  &.warning {
    background: none;
    font-size: 13px;
    line-height: 15px;
    font-weight: 600;
    color: #D42B3A;
    border-color: #D42B3A;

    &.outlined {
      &:enabled:hover {
        background: #D42B3A;
        color: white;
        box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.20);

        svg path {
          fill: white !important;
        }
      }
    }

    &.text {
      text-decoration: underline 2px;
    }
    path {
        fill: #D42B3A;
    }
  }

  &[disabled] {
    border-color: rgba(203, 203, 203, 1);
    color: rgba(146, 146, 146, 1);

    svg {
      path {
        fill: rgba(146, 146, 146, 1) !important;
      }
    }
  }

  svg {
    width: 14px;
    height: 14px;
  }

}


@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
