@import "assets/scss/variables";

.line {
  height: 10px;
  border-radius: 2px;
  max-width: 0;
  transition: all 0.9s ease;
}

.active{
  max-width: 500px;
}

.lineWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.wrapCount {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80px;
}

.count {
  color: $white;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.percent {
  color: $wcm_green;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  .upIcon {
    margin-left: 3px;
  }

  .downIcon{
    margin-left: 3px;
  }
}

.down{
  color: $wcm_danger
}

.hide{
  display: none;
}

.info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 13px;
  color: $white;
  line-height: 16px;
  margin-bottom: 5px;
  max-width: 420px;
  width: 100%;
  text-overflow: ellipsis;
  p{
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .desc {
    color: $gray_light;
    margin-left: 5px;
    display:inline-block;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    white-space: nowrap;
  }
}

.item {
  width: 100%;
  margin-right: 25px;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
}

.innerPageInfo{
  max-width: 280px;
}

.total{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  font-size: 11px;
  border-top: 1px solid $gray-146;
  padding: 10px 0;
}

.load{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10% auto;
}

@media (max-width: 768px) {
  .info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;
    .desc {
      max-width: 140%;
      margin-left: 0;
      white-space: normal;
    }
  }
  .total{
    display: none;
  }
}

@media (max-width: 500px) {
  .info {
    .desc {
      max-width: 100%;
    }
  }
}
