@import "assets/scss/variables";

.shortNumber {
  display: flex;
  align-items: center;

  &_inline {
    display: inline-block;
  }

  &_underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $orange;
    }
  }
}

.block {

}

.head {
  //display: flex;
  margin-bottom: toRem(5);
  align-items: center;
}

.iconHovered {
  display: none;
}

.iconBlock {
  margin-left: toRem(5);
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: toRem(16);
  height: toRem(16);
  &:hover {
    .icon {
      display: none;
    }

    .iconHovered {
      display: block;
    }
  }
}

.iconBlock_small {
  width: toRem(11);
  height: toRem(11);
}

.iconBlock_big {
  width: toRem(16);
  height: toRem(16);
}

.icon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.title {
  vertical-align: middle;
  max-width: calc(100% - 21px);

  &_small {
    font-size: 11px;
  }

  &_middle {
    font-size: 13px;
  }

  &_big {
    font-size: 16px;
  }
}

.value {
  font-size: toRem(20);
  font-weight: 600;
  display: flex;
  align-items: center;

  > * {
    font-size: inherit;
  }
}

.tooltip_big {
  max-width: 255px;
}

.tooltip_small {
  max-width: 171px;
}

.currentTitle {
  text-decoration: underline;
  margin-bottom: 3px;

  &:hover {
    color: $orange;
    cursor: pointer;
    text-decoration: none;
  }
}