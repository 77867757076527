@import "../../../../assets/scss/variables";

.container {
  //position: absolute;
  z-index: 100;
  border-radius: toRem(6) !important;
  border: 1px solid #CBCBCB !important;
  background-color: #fff !important;
  color: $black_56 !important;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
  opacity: 1 !important;

  &:after, &:before {
    display: none;
  }
}

.wrapper {
  width: 100%;
}