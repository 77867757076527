@import 'assets/scss/variables';

@mixin font {
  font-family: $font-family-suisse;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

@mixin inputContainer {
  align-items: center;
  padding: 0 15px;
  display: flex;
  flex-direction: row-reverse;
  height: 35px;
  background: white;
  border: 1px solid #CBCBCB;
}

:global(.react-datepicker__tab-loop) {
  margin-left: -10px;
}

.timepickerWrapper,
.datepickerWrapper {

  .input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
  }

  .icon {
    position: static;
    cursor: pointer;
    padding: 0;
  }

}

.timepickerWrapper {

  .icon {
    transform: rotateX(180deg);

    &:global(.false) {
      transform: rotateX(0deg);
    }
  }

  & > div {
    @include inputContainer;
    width: 100px;
  }

}

.datepickerWrapper {

  & > div {
    @include inputContainer;
    width: 150px;
  }
}


.calendarWrapper,
.timesheetWrapper {
  @include font;
  border: 1px solid #CBCBCB;
  overflow: hidden;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, .2) 3px 3px 6px 0;
}

.timesheetWrapper {
  scrollbar-width: none;
  padding: 0;
  width: 120px;

  .time {
    padding: 0 15px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid transparent;;
    color: #383838;

    &:hover {
      border-left: 1px solid black;
      background: #F4F4F4;
    }

    &:hover {
      background: #F4F4F4;

    }
  }

  :global {
    .react-datepicker {
      &__time-list::-webkit-scrollbar {
        display: none;
      }

      &__time-container,
      &__time-box {
        width: 100% !important;
      }

      &__header {
        display: none;
      }
    }
  }
}

.calendarWrapper {
  :global(.react-datepicker__header) {
    background: transparent;
    border: none;
    padding: 0;

    .headerContainer {
      display: flex;
      flex-direction: column;
      gap: 0;

      .commonPeriods {
        max-width: 100%;
        //gap: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .commonPeriod {
          display: flex;
          align-items: center;
          height: 25px;
          gap: 5px;
          cursor: pointer;
          padding: 0 5px;
          border-radius: 6px;
          border: 1px solid transparent;
          background: #F4F4F4;

          &:hover {
            border: 1px solid #D9A81B;
          }
        }
      }

      .header {
        display: flex;
        align-items: center;
        height: 35px;
        margin-bottom: 20px;
        justify-content: space-between;

        .arrow {
          padding: 0 15px;
          box-sizing: content-box;
          cursor: pointer;
        }

        .month,
        .year {
          flex: 1;
          user-select: none;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
        }
      }
    }
  }


  :global {
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selecte {
      border-radius: 6px;
      border: 1px solid #D9A81B;
      background: inherit;
      color: black;
      font-weight: 600;
      outline: none;
    }

    .react-datepicker {

      &__month {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &__day--selected {
        background: #D9A81B;
        border-radius: 6px;
        color: white;
        outline: none;
      }

      &__day-names,
      &__week {
        margin: 0;
        display: flex;
        gap: 5px;
      }

    }

  }

  .day, .weekDay {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0;
    user-select: none;
  }

  .day:hover {
    border-radius: 6px;
    border: 1px solid #D9A81B;
    background: inherit;
    color: black;
    font-weight: 600;
  }


  .weekDay {
    font-weight: 600;
  }
}

.selectedPeriod {
  &:enabled,
  &:enabled:hover {
    background-color: black;
    border-color: black !important;
    color: white;
  }
}
