@import "../../../../assets/scss/variables";

.textArea {
  border: 1px solid $gray_light;
  outline: none;
  background-color: $black;
  color: $white;
  font-size: 1rem;
  padding-left: 1rem;
  width: 100%;
  padding-top: 1rem;
  resize: none;
  padding-right: 3rem;
  font-family: $font-family-suisse;

  &_container {
    display: flex;
    flex-direction: column;
  }

  &_wrapper {
    width: 100%;
    position: relative;
  }

  &_label {
    color: $wcm_gray;
    font-size: 1rem;
    margin-bottom: .55rem;
  }

  &_icon {
    position: absolute;
    right: 1rem;
    width: .82rem;
    height: .82rem;
    top: 1rem;
    display: none;

    &_warning {
      path {
        fill: $wcm_danger;
      }
    }
  }

  &_inValid {
    border-bottom: 1px solid $wcm_danger;

    + span {
      background-color: red;

      .textArea_icon_warning {
        display: block;
      }
    }
  }

  &_isValid {
    &:not(:placeholder-shown) {
      border-bottom: 1px solid $wcm_green;

      + span {
        .textArea_icon_success {
          display: block;
        }
      }
    }
  }

  &_errorMessage {
    color: $wcm_danger;
    font-size: 0.875rem;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus, {
    border: 1px solid $gray_light;
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0 1000px $black inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.limit {
  position: absolute;
  right: 8px;
  bottom: 10px;
  font-size: 0.82rem;
  color: #929292;
}

.errorLimit {
  color: $wcm_danger
}