@import "../../../assets/scss/variables";

.terms {
  width: 100%;
  z-index: 100000000000000 !important;

  .header {
    text-align: center;
    margin-top: toRem(30);
    font-weight: 800;
    font-size: toRem(28);
    line-height: toRem(34);
    font-family: $font-family-plaak;
    color: $white;
  }

  .content {
    z-index: 100000000000000 !important;
    max-width: toRem(979);
    width: 100%;
    margin: toRem(20) auto toRem(160) auto;
    box-sizing: border-box;

    .updateTxt {
      text-align: end;
      font-weight: 600;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .headTxt {
      margin-top: toRem(10);
      font-weight: 600;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .txt {
      font-weight: 400;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .txtUnderline {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .terms {
    .content {
      //max-width: rem(800);
    }
  }
}

@media screen and (max-width: 768px) {
  .terms {
    .content {
      //max-width: rem(620);
    }
  }
}

@media screen and (max-width: 660px) {
  .terms {
    .header {
      margin-top: toRem(50);
    }

    .content {
      //max-width: rem(340);

      .updateTxt {
        font-size: toRem(13);
        line-height: toRem(16);
      }

      .headTxt {
        margin-top: toRem(14);
      }
    }
  }
}
