@import "assets/scss/variables";

.chartContainer {
  height: 280px;
  margin-top: 20px;
}

.chartIcon {
  color: #383838;
  height: 90%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.container {
  .h2 {
    margin: 0;
  }
}

.description {
  margin-top: 4px;
}

.emptyChart {
  align-items: center;
  background-color: #101010;
  color: $white;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 180px;
  justify-content: center;
  line-height: 20px;
  margin-top: 32px;
  position: relative;
  text-align: center;
}

.emptyChartDescription {
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  margin-top: 4px;
}

.emptyChartRadioLabel {
  background-color: #383838;
  border-radius: 4px;
  height: 11px;
}

.emptyChartText {
  background-color: #101010;
  color: #878787;
  max-width: 230px;
  padding: 12px;
  z-index: 1;
}

.fieldset {
  border: none;
  color: $white;
  display: grid;
  font-size: 11px;
  gap: 12px;
  line-height: 13px;
  margin: 32px auto 0;
  padding: 0;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    max-width: 452px;
  }
}

.radioInput {
  appearance: none;
  border: 1px solid;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  height: 11px;
  margin: 0;
  position: relative;
  width: 11px;
  &:checked {
    background-color: currentColor;
    &::after {
      border: solid;
      border-color: black;
      border-width: 0 2px 2px 0;
      content: "";
      height: 5px;
      left: 3px;
      position: absolute;
      top: 1px;
      transform: rotate(45deg);
      width: 2px;
    }
  }
}

.radioLabel {
  align-items: center;
  background-color: #232323;
  border: 1px solid #232323;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-size: 9px;
  font-weight: 600;
  gap: 8px;
  line-height: 11px;
  justify-content: space-between;
  padding: 8px;
}

.radioLabelDescription {
  color: #878787;
  font-weight: 400;
  margin-top: 2px;
}

.balanceRadioLabelChecked {
  border-color: #ad076a;
}

.royaltyRadioLabelChecked {
  border-color: #007bd9;
}
