@import "assets/scss/variables";

.SetUserControlModal {
  text-align: center;
  z-index: 10000000;
}

.mymodal {
  max-width: 697px;
  height: 288px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1000000000;
}

.suspendModal {
  height: 268px;
}

.roleModal {
  max-width: 329px;
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.title {
  margin: 0 0 17px 0;
  line-height: 30px;
  font-size: 26px;
  color: $black;
}

.description {
  font-size: 13px;
  color: $wcm_dark;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .button {
    margin-left: auto;
    background-color: $wcm_gray_darker;
    border: 1px solid $wcm_gray_darker;
    height: 35px;
    color: $white;
  }

  .loadingCircle {
    height: 35px;
  }
}


.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  //background-color: rgba(0, 0, 0, 0.75) !important;
  background-color: rgba(20, 20, 20, 0.3) !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.error {
  font-size: 13px;
  font-weight: 600;
  color: $black;
  margin-left: 20px;
}

.userError {
  display: flex;
  align-items: center;
  color: $wcm_danger;
  font-size: 11px;

  .warning {
    margin-right: 10px;
    width: 13px;
  }
}

.success {
  max-width: 277px;
  padding: 26px;
  justify-content: center;
  height: 69px;
  top: 30%
}

.successModal {
  display: flex;
  width: 100%;
  justify-content: center;

  span {
    font-size: 13px;
    font-weight: 600;
    color: $black;
    margin-right: 4px;
  }

  .userIcon {
    width: 17px;
    margin-right: 11px;
  }
}

.userRole {
  font-size: 16px;
  line-height: 20px;
  color: $gray;
}

.userEmail {
  font-size: 13px;
  line-height: 20px;
  color: $wcm_dark;
}

.description {
  font-size: 13px;
  line-height: 20px;
  color: $wcm_dark;
  margin: 20px 0 40px 0;
}

.suspendWrap {
  display: flex;
  align-items: flex-end;

  p {
    font-weight: 600;
    font-size: 20px;
    color: $wcm_dark;
    margin-right: 10px;
    line-height: 20px;
  }

  span {
    font-size: 16px;
    color: $gray;
    line-height: 16px;
  }
}


.impersonateModal {
  min-width: 527px;
  max-width: 680px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1000000000;
  @media (max-width: 750px) {
    position: absolute;
    transform: translate(0%, -50%);
    left: 0;
    min-width: auto;
    margin: 0 auto;
    width: 97%;
    right: 0;
  }
}


.impersonationDate {
  margin-bottom: 15px;
}

.impersonationText {
  margin-bottom: 15px;
  max-width: 360px;
  width: 100%;
}

.fullName {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: $black_0;
  margin: 10px 20px 20px 0;

}

.userName {
  font-weight: 600;
  font-size: 20px;
  color: $wcm_dark;
}

.dataRow {
  margin: 10px 0;
}

.userValue {
  font-size: 13px;
  line-height: 13px;
  margin-right: 8px;
  color: $black
}

.userLabel {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-right: 8px;
  color: $black
}

.tag {
  display: inline;
  border-radius: 10px;
  padding: toRem(2) toRem(10);
  border: 1px solid #383838;
  background: #FFF;
}


.userEmail {
  font-size: 13px;
  line-height: 20px;
  color: $wcm_dark;
}

.actionWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  margin-bottom: 20px;

  .action {
    display: flex;
    align-items: center;
  }

  .status {
    padding: 2px 10px;
    font-size: 11px;
    line-height: 13px;
    border-radius: 12px;
    background-color: $gray-146;
    color: $white;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }

    &_inactive {
      background-color: $gray-229;
      color: $black_56;
    }

    &_active {
      background-color: $wcm_green;
      color: $white;
    }

    &_registered {
      background-color: $wcm_green;
      color: $white;
    }


    &_suspended {
      background-color: rgba(180, 84, 0, 1);
      color: white;
    }

    &_invited {
      background-color: $black_56;
      color: $white;
    }
  }

  .iconsWrap {
    display: flex;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    .editIcon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }

    .slashIcon {
      width: 17px;
      height: 13px;
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $wcm_dark;
    }
  }

}

