@import "../../../../../assets/scss/variables";

.container {
  position: relative;
}

.menu {
  position: absolute;
  left: -220px;
  top: 5px;
  min-width: 280px;
  background-color: #fff;
  border-radius: 6px;
  color: black;
  border: 1px solid #CBCBCB;
  box-shadow: 6px 6px 12px rgb(0 0 0 / 20%);
  padding-top: 30px;
  padding-bottom: 12px;
  z-index: 2; // To be above the alert banner
  @media (max-width: 750px) {
    left: -238px;
  }

  & > .item {
    &:last-child {
      margin-top: 20px;
    }
  }
}

.item {
  padding-left: 20px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: black;
    opacity: 0;
  }

  &:hover {
    background-color: $gray-244;

    &:before {
      opacity: 1;
    }

    a {
      color: inherit;
    }
  }

  &_active {
    background-color: $gray-244;

    &:before {
      opacity: 1;
    }

    a {
      color: inherit;
    }
  }

  a {
    width: 100%;
    height: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.email {
  color: black;
  font-weight: 600;
  padding-left: 20px;
}

.link {
  color: $gray;
  text-decoration: underline;
  font-weight: 600;
  padding-left: 20px;
  margin-bottom: 25px;
  display: block;
}

.group {
  margin-top: 20px;

  &_title {
    padding: 9px 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &_arrow {
    width: 12px;
    height: 13px;

    &_rotate {
      transform: rotate(180deg);
    }
  }
}

.delete {
  color: $wcm_danger;
  cursor: pointer;
}

.hideLink {
  display: none;
  height: 0;
}

.deleteAccount {
  color: $wcm_danger2;
}