@import "assets/scss/variables";

.PayeeCodeInfo {
  margin-right: toRem(100);

  :global(.payeeCodeLabel) {
    text-transform: capitalize;
  }

  :global(.payeeContent) {
    color: black;
  }

  &_small {
    svg {
      margin-left: 8px;
    }

    margin-right: toRem(122);

    :global(.payeeCodeLabel) {
      //text-transform: capitalize;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      &:nth-last-child(1) {
        //margin-left: rem(100);
      }
    }
    @media (max-width: 990px) {
      margin-bottom: 0 !important;
      &:nth-last-child(1) {
        //margin-left: rem(100);
      }
    }
    @media (max-width: 1024px) {
      margin-right: 0;
      &:nth-last-child(1) {
        //margin-left: rem(62);
      }
    }
    @media (max-width: 750px) {
      &:nth-last-child(1) {
        margin-left: 0;
      }
    }
  }

  &_calendar {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 25px;
      bottom: 0;
      width: calc(100% - 48px);
      height: 1px;
      background-color: $orange;
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 1;
      }

      color: $orange;
      cursor: pointer;
      //text-decoration: underline;
      //border-bottom: 1px solid $orange;
      svg path {
        fill: $orange;
      }
    }
  }

  @media (max-width: 1024px) {
    margin-right: 75px;
  }
  @media (max-width: 790px) {
    margin-right: 35px;
  }

  &_tooltip {
    :global(.payeeCodeLabel) {
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
  position: relative;
  padding: 0;
}

.tableValue {
  color: black;

  &_gray {
    color: $wcm_dark;
  }
}

.table {
  &_nodata {
    :global(.ag-body-viewport.ag-row-animation.ag-layout-auto-height) {
      background-color: rgba(255, 255, 255, 1);
    }

    :global(.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper) {
      font-size: 16px;
      font-style: italic;
      color: black;
    }
  }

  :global(.ag-theme-alpine .ag-root-wrapper) {
    border: none;
  }

  :global(.ag-center-cols-container) {
    min-height: 55px !important;
    height: 55px !important;
  }

  :global(.ag-center-cols-viewport) {
    min-height: 55px !important;
  }

  :global(.ag-center-cols-clipper) {
    min-height: 55px !important;
  }

  &_title {
    color: white;
    font-size: toRem(20);
    margin-bottom: toRem(20);
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_filter {
    width: 280px;
    padding: toRem(9) 20px;
    color: white;
    border: none;
    border-bottom: 2px solid #929292;
    outline: none;
    background-color: transparent;
    background-image: url("../../icons/filter_search.svg");
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: 90% center;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;

    &::-webkit-input-placeholder {
      color: $gray-146;
      font-style: italic;
      font-size: 13px;
    }

    @media (max-width: 750px) {
      width: 200px;
      &::-webkit-input-placeholder {
        opacity: 0;
      }
    }
  }

  :global(.ag-row-group-expanded) {
    border-bottom: none;
  }

  :global(.ag-group-contracted) {
    :global(.ag-icon ) {
      background-image: url("../../icons/chevrons_down.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }

    @media (max-width: 750px) {
      :global(.ag-icon ) {
        background-image: url("../../icons/chevron_right_small.svg");
        background-size: contain;
      }
      pointer-events: none;
    }
  }

  :global(.ag-group-expanded ) {
    :global(.ag-icon ) {
      background-image: url("../../icons/chevrons_up.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-theme-alpine) {
    font-family: $font-family-suisse;
  }

  :global(.ag-header) {
    border-bottom: 2px solid $gray-146;
    background-color: $gray-244;
    overflow: visible;
    color: black;

    :global(.ag-header-viewport) {
      overflow: visible;
    }
  }

  :global(.ag-header-cell) {
    padding-left: toRem(35);
    padding-right: 0;
    @media (max-width: 1024px) {
      padding-left: 15px;
    }

    &:first-child {
      padding-left: toRem(35);
      @media (max-width: 1024px) {
        padding-left: 15px;
      }
    }
  }

  &_detail {
    display: flex;
    padding-left: toRem(128);
    font-size: 11px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    svg {
      width: 11px;
      height: 11px;

      path {
        fill: black;
      }
    }

    @media (max-width: 1024px) {
      padding-left: 20px;
    }
  }

  &_row {
    border-bottom: 1px solid $gray-146;
    background-color: #fff !important;

    &:hover {
      background-color: $gray-249 !important;
    }

    :global(.ag-cell) {
      display: flex;
      align-items: center;
      white-space: break-spaces;
      font-size: 13px;
      padding-left: toRem(35);
      color: black;
      background-color: transparent !important;
      border: none !important;
      @media (max-width: 1024px) {
        padding-left: 15px;
      }
    }
  }
}

.container {
  margin: 0 auto;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.cursor {
  transform: rotate(-90deg);
  width: 15px;
  height: 15px;
}

.underlineonhover {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.value {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  //height: 16px;
  white-space: nowrap;
}


@media (max-width: 750px) {
  .container {
    margin: 0 auto;
  }
}