@import "assets/scss/variables";

.container {
  color: $white;
  padding-bottom: 10px;
}

.languageContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.languageSelect {
  width: 200px;
  :global(.select__option) {
    font-size: 13px;
  }
  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    background-image: url("../../img/option-check.svg");
    background-position: 1.5rem 0.8rem;
    background-repeat: no-repeat;
    border-left: 0;
    padding-left: calc(1.5rem + 24px);
    position: relative;
    &:hover {
      background-color: $wcm_dark;
      border-left: 0;
      &:after {
        background-color: $orange;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 2px;
      }
    }
  }
  :global(.select__menu) {
    border-radius: 6px;
    border-top: 1px solid #CBCBCB;
    top: calc(100% + 5px);
  }
  :global(.select__menu-list) {
    height: 180px;
    max-height: 180px;
  }
}

.paperlessStatementsValue {
  align-items: center;
  display: flex;
  gap: 10px;
}
