@import "../../../assets/scss/variables";

.analytics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: toRem(20);
}

.hideChard {
  display: none !important;
}

.charge {
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 20px 30px 30px;
  min-height: 350px;
  background-color: $black_0;
}

@media (max-width: 1280px) {
  .analytics {
    grid-template-columns: 1fr;
    display: block;
  }
}