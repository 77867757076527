
.statusBadge {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: inline-flex;
    align-items: center;
    height: 17px;
    border-radius: 12px;
    color: white;
    text-transform: capitalize;
    padding: 0 10px;
    font-size: 11px;
    white-space: nowrap;

    &.Active {
      background: #00813D;
    }

    &.Scheduled {
      background: #929292;
    }

    &.Expired,
    &.ManuallyEnded {
      color: #383838;
      background: #E5E5E5;
    }
  }
}

.deletes {
  color: red;
  font-size: 11px;
}
