@import "../../../../../assets/scss/variables";

.item {
  padding: toRem(10) 0;
  padding-left: toRem(20);
  padding-right: toRem(30);
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    left: 0;
    position: absolute;
    background-color: $black;
    opacity: 0;
    top: 0;
  }

  &:hover {
    background-color: $gray-244;

    &:before {
      opacity: 1;
    }
  }
}