@import "assets/scss/functions";

.tableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 30px;
  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &.sm {

    }

    &.md {

    }

    &.lg {

    }

    td, th {
      padding: 0 15px;
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }


    tbody {
      height: 0;
      min-height: 0;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      td {

      }
      tr {
        display: flex;
        height: 72px;
        max-height: 72px;
        align-items: center;
        background-color: #fff;
        flex-wrap: wrap;
        border-bottom: 1px solid #929292;
        font-size: 13px;
        line-height: 16px;
        color: #383838;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .noResult {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      height: 35px;
      width: 152px;
      border-radius: 6px;
      background-color: #E5E5E5;
      color: #383838;
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

.skeleton {
  height: 25px;
  width: 75%;
  left: 20px;
  top: 0;
  bottom: 0;
  border-radius: toRem(12);
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 19%, #eeeeee 100%);
  background-size: 800px 104px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}