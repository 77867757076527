@import "assets/scss/variables";

.alert {
  color: $white;
  margin-bottom: 20px;
}

.fieldsRow {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.form {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: toRem(20);
  color: $white;
  margin: 20px auto 64px;
  padding: 40px 30px;
  @media (min-width: 640px) {
    max-width: 620px;
  }
  @media (min-width: 768px) {
    max-width: 712px;
  }
  @media (min-width: 1024px) {
    margin-top: 0;
    max-width: 780px;
    padding: 100px;
  }
}

.h1 {
  margin-top: 0 !important;
}

.info {
  margin-top: 40px;
}

.infoLink {
  color: $wcm_yellow;
  text-decoration: underline;
  &:focus,
  &:hover {
    color: $wcm_yellow_hover;
  }
}

.success {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 20px;
  color: $white;
  max-width: 100%;
  margin: 84px auto 64px;
  padding: 50px 24px;
  text-align: center;
  width: 580px;
  @media (min-width: 1024px) {
    margin-top: 64px;
  }
}

.successCloseButton {
  margin-top: 20px;
}

.successDescription {
  font-size: 16px;
  margin: 0 auto;
  max-width: 360px;
}

.textarea {
  width: 100%;
  textarea {
    max-height: 80px;
  }
}

.toolbar {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media (min-width: 320px) {
    align-items: center;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
  }
}

