@import "../../../../assets/scss/variables";
//.menu {
//  background: red;
//  position: absolute;
//  bottom: rem(30);
//  color: $white;
//  max-width: rem(500);
//  z-index: 10000;
//
//  div {
//    padding: rem(5) rem(20);
//    cursor: pointer;
//    white-space: nowrap;
//
//    &:hover {
//      background: $gray_lighter;
//      color: $black;
//    }
//  }
//}
.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: center;

  * svg {
    path {
      fill: black;
    }

    &:hover {
      path {
        fill: $gray;
      }
    }
  }

  .disabled {
    opacity: 0.2;
    fill: black;

    &:hover {
      svg path {
        fill: black;
      }
    }

  }

  .pageNumbers {
    min-width: 35px;
    height: 35px;
    font-weight: 600;
    font-size: 13px;
    margin: 0 5px;
    border: 1px solid transparent;
    @media (max-width: 750px) {
      width: 25px;
      height: 25px;
      margin: 0 3px;
    }

    &_dotes {
      width: 20px;
    }

    &:hover {
      border: 1px solid #383838;
      background: #F4F4F4;
      color: #383838;
      border-radius: 6px;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .selectedPage {
    border: 1px solid #000000;
    border-radius: 6px;
  }
}

.white {
  margin-top: 20px;

  .pageNumbers {
    &_dotes {
      width: 20px;
      padding: 5px 0;
    }

    color: $white;

    &:hover {
      border: 1px solid $orange;
      background: $orange;
      color: $white;
      font-size: 13px;
    }
  }

  .selectedPage {
    border: 1px solid $orange;
    border-radius: 6px;
    color: $orange;
  }
}

.arraw {
  transform: rotate(90deg);


}

.arrawLeft {
  transform: rotate(-90deg);

}

.whiteArrow {
  path {
    fill: $white !important
  }
}

.paginationMenu {
  width: 160px;
  background: $white;
  border: 1px solid $gray-203;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: $black;
  position: absolute;
  padding-top: toRem(5);
  max-height: 250px;
  overflow-y: auto;
  z-index: 10000;

  &::-webkit-scrollbar-thumb {
    background: #CBCBCB;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background: transparent
  }

  :global(.Dropdown-option) {
    white-space: break-spaces;
    padding-top: toRem(10);
    padding-bottom: toRem(10);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 2px;
      height: 100%;
      top: 0;
    }

    &:hover {
      background-color: #F4F4F4;
      //color: white;
      &:before {
        background-color: white;
      }
    }
  }

  @media (max-width: 990px) {
    left: -55px;
  }
}

.select {
  min-width: 100px;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-top: 2px;

  .paginationMenu {
    background-color: $white;

  }


  &:global(.is-open) {
    :global(.Dropdown-arrow-wrapper) svg {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }
}

.checkIconWrap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .check {
    width: 16px;
    margin-right: 10px;
    display: none;
  }

  .value {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: black;
  }
}