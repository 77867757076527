@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/functions';

:global {
  .progress-ring__circle {
    stroke: black;
    transition: stroke-dashoffset 0.35s;
    transform: rotateZ(-90deg);
    transform-origin: center center;
    stroke-dasharray: 40;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 0;
  min-height: 0;
  width: 100%;
  flex: 1 1 auto;
  border-radius: 6px;
  background-color: $gray-244;
  padding: 20px 10px 20px 0;
  color: black;
  margin: 20px 0;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $gray-203;
  }

  .container {
    display: flex;
  }

  .listHeader,
  .uploadItem {
    padding: 0 20px;
  }

  .listHeader {
    font-size: toRem(11px);
    margin-bottom: 25px;
    gap: 5px;
  }

  .statusMessage {
    display: flex;
    gap: 10px;

    span {
      gap: 5px;
      display: flex;
    }
  }

  .statusIcon {
    width: 13px
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: auto;
    height: 100%;

    &.withGuidelines .uploadItem {
      border-bottom: 1px solid #e6e6e6;
      &:hover {
        background-color: #e3e3e3;
      }
    }

    .uploadItem {
      transition: background-color 0.12s ease-in-out;
      height: 35px;
      min-height: 35px;
      max-height: 35px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .statusWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .successWrapper {
      padding-right: 25px;
    }

    .loader {
      width: 13px;
      height: 13px;
      border: 1px solid black;
      border-radius: 50%;
    }

    .error {
      color: rgba(212, 43, 58, 1);
    }

    &::before {
      position: absolute;
      transform: translateX(calc(-100% - 10px));
      font-size: 11px;
      color: $gray-146;
      content: 'Validating...';
      display: block;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    .isPending {
      position: absolute;
      transform: translateX(calc(-100% - 10px));
      font-size: 11px;
      color: $gray-146;
      display: block;
      transition: opacity 0.2s ease-in-out;
    }

    &.isValidating,
    &.isDeleting {
      circle {
        stroke-dasharray: 2 * 6 * 3.14;
        stroke-dashoffset: 0;
        animation: dash 2s linear;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }

    &.isValidating {
      &::before {
        content: 'Validating...';
        opacity: 1;
      }


    }
  }

  .deleteIcon {
    cursor: pointer;
  }

}


@keyframes dash {
  to {
    stroke-dashoffset: 10;
    stroke-dashoffset: -4 * 6 * 3.14;
  }
}