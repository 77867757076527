.dates {
  font-weight: bold;
  margin-bottom: 10px;
}

.fullWidth {
  padding: 0 16px;
  width: 100%;
}

.item {
  align-items: center;
  background-color: #cc0000;
  border-color: #dd5561;
  border-style: solid;
  border-width: 3px 0;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 14px;
  height: 100%;
  line-height: 18px;
  padding: 8px 0;
}

.itemContent {
  display: flex;
  gap: 10px;
}

.itemContentWithNav {
  padding-right: 100px;
  @media (max-width: 750px) {
    padding-right: 45px;
  }
}

.link {
  font-weight: bold;
  pointer-events: all; // To enable it on alert preview page
  text-decoration: underline;
  &:hover {
    color: inherit;
  }
}
