@import "assets/scss/variables";

.closingBalanceLabelClassName {
  margin-right: 4px;
}

.container {
  .h2 {
    margin: 0;
  }
}

.detailsButton {
  color: #878787;
  height: auto;
  margin-top: 8px;
  padding: 0;
  &:focus-visible,
  &:hover {
    color: $wcm_yellow_hover;
  }
}

.detailsButtonIcon {
  height: 10px;
  margin-left: 4px;
  width: 10px;
}

.downloadButtonContainer {
  margin-top: 24px;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  @media (min-width: 500px) {
    align-items: center;
    flex-direction: row;
  }
}

.hr {
  border: none;
  border-top: 1px solid #ffffff1f;
  margin: 24px 0;
}

.inSettlement {
  background-color: #2556ea;
  color: $white;
}

.infoItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.noData {
  font-size: 16px;
  font-style: italic;
  line-height: 20px;
  text-align: center;
}

.noDataPeriod {
  background-color: #383838;
  border-radius: 4px;
  height: 12px;
  margin-top: 12px;
  width: 200px;
}

.noDataState {
  background-color: #383838;
  border-radius: 20px;
  height: 20px;
  width: 70px;
}

.payment {
  font-style: italic;
  margin-top: 12px;
}

.period {
  color: $white;
  margin-top: 4px;
}

.rotate180 {
  transform: rotate(180deg);
}

.settled {
  background-color: #00813d;
  color: $white;
}

.state {
  border-radius: 20px;
  display: inline-block;
  flex-shrink: 0;
  font-size: 11px;
  line-height: 13px;
  padding: 4px 10px;
  white-space: nowrap;
}

.tooltipIcon {
  color: #878787;
  cursor: pointer;
  height: 16px;
  margin: 2px;
  vertical-align: middle;
  width: 16px;
}

.total {
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
}

.totalInfoItem {
  color: $white;
}
