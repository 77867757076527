@import "assets/scss/variables";

.setAddUserModal {
  text-align: center;
}

.mymodal {
  max-width: 500px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.roleModal {
  max-width: 470px;
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.title {
  margin: 0 0 2px 0;
  font-size: 20px;
  color: $black;
}

.description {
  font-size: 13px;
  color: $wcm_dark;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .button {
    margin-left: auto;
  }

  .loadingCircle {
    height: 35px;
  }
}


.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.3) !important;
  z-index: 1;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.inputWrap {
  width: 100%;
  margin: 20px 0 30px 0;
  height: 35px;
  position: relative;

  .user {
    position: absolute;
    top: 40px;
    border: 1px solid $gray-0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    width: 100%;
    height: 75px;
    background-color: $white;
    display: flex;
    align-items: center;
  }

  .loop {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
    width: 13px;

    path {
      fill: $gray-146;
    }
  }

  .clear {
    position: absolute;
    right: 30px;
    top: 11.5px;
    cursor: pointer;
    width: 13px;

    path {
      fill: $gray-146;
    }
  }

  .input {
    border: 1px solid $gray_light;
    height: 35px;
    width: 100%;
    padding: 0 55px 0 15px;
    color: $black;
    font-size: 13px;

    &:focus {
      outline: none;
    }
  }

  .open {
    border-bottom: 1px solid #000000;
  }
}

.loader {
}

.userInfo {
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;
  padding: 0 18px;

  &:hover {
    background-color: $gray-244;
    border-left: 1px solid $orange;
    padding-left: 17px;
  }

  .fullName {
    margin-right: 4px;
    font-weight: 600;
    color: $wcm_dark
  }
}

.error {
  font-size: 13px;
  font-weight: 600;
  color: $black;
  margin-left: 20px;
}

.radio {
  margin-top: 25px;
  margin-bottom: 30px;

  .radioButtons {
    display: flex;

    .radioWrap {
      display: flex;
      margin-right: 40px;
      align-items: center;

      input[type='radio'] {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        margin-top: 2px;

        &:after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #000000;
          background-color: white;
          opacity: 1;
        }
      }

      input[type='radio']:checked {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;

        &:after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #000000;
          background-color: white;
          opacity: 1;
        }

        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          border: 1px solid #000000;
          background-color: white;
          opacity: 1;
          z-index: 2;
        }
      }

    }
  }

  .radioTitle {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.userError {
  display: flex;
  align-items: center;
  color: $wcm_danger;
  font-size: 11px;

  .warning {
    margin-right: 10px;
    width: 13px;
  }
}

.success {
  max-width: 277px;
  padding: 27px 26px;
  justify-content: center;
}

.addUser {
  display: flex;
  height: 16px;
  width: 100%;
  justify-content: center;

  span {
    font-size: 13px;
    font-weight: 600;
    color: $black
  }

  .userIcon {
    width: 17px;
    margin-right: 11px;
  }
}

