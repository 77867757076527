@import "assets/scss/variables";

.table {
  font-size: 13px;
  height: fit-content;
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  background: #fff;

  tbody {
    position: relative;

    tr {

      &:hover {
        background-color: $gray-249;
      }

      td {
        height: 54px;
        white-space: nowrap;
      }
    }

    .noResultRow{
      display: table-row;
      td {
        text-align: center;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
    }

    .loadingRow{
      min-height: 108px;
      position: absolute;
      top: 0;
      backdrop-filter: blur(2px);
      background-color: rgba(0,0,0,0.2);
      font-size: 25px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  thead {
    background-color: $gray-244;
    z-index: 1;

    tr {
      min-height: 46px;

      th {
        font-weight: 800;
        height: 46px;
        padding-top: 24px;
        padding-bottom: 24px;
        cursor: pointer;
        color: #181d1f;
        border-bottom: 2px solid $gray-146;
        @media (max-width: 1181px) {
          white-space: inherit;
        }

        div {
          display: flex;
          gap: 8px;
        }
      }
    }
  }

  tr {
    min-height: 54px;

    th,
    td {
      font-weight: 400;
      line-height: 16px;
      display: table-cell;
      overflow: hidden;
      margin: 0;
      padding: 0 20px;
      user-select: none;
      text-align: left;
      text-transform: capitalize;
      text-overflow: ellipsis;
      color: #383838;
      @media (max-width: 500px) {
        padding: 0 10px;
      }

      &:last-child {
        border-right: 0;
        width: 100% !important;
      }
    }

    &:last-child td {
      border-bottom: 0;
    }
  }

  :global(.table_heading) {
    overflow: hidden;
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 1181px) {
      overflow: visible;
      white-space: inherit;
      text-overflow: inherit;
    }
  }
}

.row {
  td {
    border-bottom: 1px solid $gray-146;
  }
}

.lg {
  td {
    height: 60px;
  }
}

.md {
  td {
    height: 45px;
  }
}

.sm {
  width: 960px;
  border: 1px solid $gray-146;

  td {
    height: 35px;
  }

  th,
  td {
    padding: 5px 5px;
  }
}

.noResult {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  color: #000000;
}

.isOpen {
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease;
}

.open {
  max-height: 500px;
  transition: all 1s ease;
}