@import "assets/scss/variables";

.setEditUserModal {
  text-align: center;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.3) !important;
  z-index: 1;
  @media (max-width: 750px) {
    overflow: scroll;
  }
}

.circle {
  margin: 20px;
}

.mymodal {
  max-width: 623px;
  width: 100%;
  min-height: 356px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.viewUser {
  max-width: 673px;
  @media (max-width: 750px) {
    width: 673px;
    position: absolute;
    transform:translate(-30%, -50%);
  }
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.fullName {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: $black_0;
  margin-bottom: 10px;
}

.actionWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  margin-bottom: 20px;

  .action {
    display: flex;
    align-items: center;
  }

  .status {
    padding: 2px 10px;
    font-size: 11px;
    line-height: 13px;
    border-radius: 12px;
    background-color: $gray-146;
    color: $white;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }

    &_inactive {
      background-color: $gray-229;
      color: $black_56;
    }

    &_active {
      background-color: $wcm_green;
      color: $white;
    }

    &_registered {
      background-color: $wcm_green;
      color: $white;
    }

    &_suspended {
      background-color: rgba(180, 84, 0, 1);
      color: white;
    }

    &_invited {
      background-color: $black_56;
      color: $white;
    }
  }

  .iconsWrap {
    display: flex;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    .editIcon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }

    .slashIcon {
      width: 17px;
      height: 13px;
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $wcm_dark;
    }
  }

}

.role {
  font-size: 16px;
  line-height: 16px;
  color: $gray;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.userEmailLabel {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-right: 8px;
  color: $black
}

.notFound {
  margin: 20px 0;
}

.userEmail {
  font-size: 13px;
  line-height: 13px;
  margin-right: 8px;
  color: $black
}

.emailWrap {
  margin-bottom: 20px;
}

.locationWrap {
  margin-bottom: 41px;
}

.buttonsWrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .cancelButton {
    background-color: inherit;
    color: $black;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    border: none;
    padding: 0;
    margin-right: 25px;
    height: 25px;
  }

  .discardButton {
    background-color: inherit;
    color: $black;
    font-weight: 600;
    line-height: 13px;
    border: none;
    padding: 0;
    margin-right: 25px;
    height: 35px;
  }

  .saveButton {
    height: 25px;
    border-radius: 6px;
    background-color: $wcm_dark;
    color: $white;
    border: 1px solid $wcm_dark;

    &:disabled {
      background-color: #E5E5E5;;
      border: 1px solid $gray-203;
      color: #929292;
    }
  }
}

.radio {
  margin-bottom: 30px;

  .radioButtons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .radioWrap {
      display: flex;
      margin-right: 40px;
      align-items: center;

      input[type='radio'] {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        margin-top: 2px;

        &:after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #000000;
          background-color: white;
          opacity: 1;
        }
      }

      input[type='radio']:checked {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;

        &:after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #000000;
          background-color: white;
          opacity: 1;
        }

        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          border: 1px solid #000000;
          background-color: white;
          opacity: 1;
          z-index: 2;
        }
      }

    }
  }

  .radioTitle {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: $black;
    margin-right: 20px;
  }
}

.input {
  max-width: 250px;
  margin-bottom: 10px;

  input {
    background-color: white !important;
  }

  span {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: $black;
    margin-right: 4px;
    margin-bottom: 5px;
  }
}

.editLabel {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: $black;
  margin-right: 4px;
}

.editEmail {
  font-size: 11px;
  line-height: 13px;
}

.saveModal {
  position: absolute;
  top: 27px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 427px;
  padding: 40px;
  z-index: 20;
  left: 0;
  right: 0;
  margin: 0 auto;

  .saveModalDescription {
    margin: 30px 0 40px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #383838;
  }

  .button {
    background: #3F3F3F;
    border: 1px solid #3F3F3F;
  }
}

.errorWrap {
  display: flex;
  align-items: center;
  margin-right: auto;

  .errorMessage {
    font-size: 11px;
    color: $wcm_danger;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 16px;
    margin-right: 8px;
  }

  .circle {
    height: 35px;
  }
}

.success {
  display: flex;
  width: 250px;
  height: 69px;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 22;
  position: absolute;
  top: 81px;
  margin: 0 150px;

  path {
    fill: $black;
    width: 12px;
    height: 13px;
  }

  p {
    margin-left: 11px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $black
  }
}

.globe {
  width: 11px;
  height: 11px;
  padding: 0;

  path {
    fill: $black;
  }
}

.content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;

  .code {
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    background: #E5E5E5;
    padding: 4px 12px;
    width: fit-content;
    margin: 10px 0;
    color: #000000;
    font-size: 11px;
    line-height: 13px;
  }

  .wraper {
    margin-bottom: 20px;
  }
}

.payeeWrap {
  height: 140px;
  overflow-y: auto;
}

.payeeWrap::-webkit-scrollbar {
  width: 5px;
}

.payeeWrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
}

.payeeWrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #CBCBCB;
  background-color: #CBCBCB;
}

