@import "assets/scss/variables";

.dateInput {
  border: 1px solid $gray_light;
  height: 35px;
  width: 325px;
  border-radius: 0;
  outline: none;
  padding-left: 15px;
  font-size: 13px;
  font-style: italic;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }

  .calendar {
    position: absolute;
    right: 15px;

    path {
      fill: $gray;
    }
  }
}

.SetUserControlModal {
  text-align: center;
  z-index: 10000000;
}

.datePickerModal {
  outline: none;
  max-width: 580px;
  width: 580px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  top: 180px;

  :global(.datePicker-modal) {
    position: initial !important;
  }

  :global(div[class*="materialui-daterange-picker-MuiPaper-elevation5"]) {
    > :global(div[class*="materialui-daterange-picker-MuiGrid-wrap-xs-nowrap"]) {
      flex-direction: column;
      border: none !important;
    }
  }

  :global(div[class*="materialui-daterange-picker-makeStyles-divider"]) {
    + div ul {
      display: none;
    }
  }

  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000000;
    background-color: transparent !important;
  }

  :global(.datePicker-modal) {
    left: 0;

    div[class*="materialui-daterange-picker-MuiPaper-elevation5"] {
      padding-bottom: 50px;
    }

    //height: 100%;
  }

  &_buttons {
    position: absolute;
    bottom: 15px;
    z-index: 100;
    right: 15px;
    display: flex;
    gap: 20px;
    align-items: center;

    button {
      font-weight: 600;
    }
  }
}

.mymodal {
  max-width: 575px;
  overflow: auto;
  //height: 247px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1000000000;
}

.suspendModal {
  height: 268px;
}

.roleModal {
  max-width: 329px;
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
  top: 40px;
}

.title {
  margin: 0 0 17px 0;
  line-height: 30px;
  font-size: 26px;
  color: $black;
}


.buttonWrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;

  .buttonSubmit {
    background-color: $wcm_gray_darker;
    border: 1px solid $wcm_gray_darker;
    padding: 9px 15px;
    color: white;
  }

  .buttonText {
    font-size: 13px;
    color: $black_56;
    padding: 9px 15px;
  }

  .loadingCircle {
    height: 35px;
  }
}


.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  //background-color: rgba(0, 0, 0, 0.75) !important;
  background-color: rgba(20, 20, 20, 0.3) !important
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.filterModal_field {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  &_label {
    margin-right: 50px;
    min-width: 140px;
    font-size: 16px;
    font-weight: 400;
    color: black;
  }

  :global(.wcm-select) {
    width: 325px;
  }
}

.buttonList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  button {
    font-weight: bold;
  }
}
