@import "assets/scss/variables";

.error {
  color: $wcm_danger;
  font-size: 0.875rem;
}

.label {
  color: $wcm_gray;
  display: block;
  margin-bottom: 5px;
}

.phoneNumber {
  :global {
    .PhoneInputCountry {
      display: none;
    }

    input {
      background-color: $black;
      border: 1px solid #cbcbcb;
      color: $white;
      font-size: 1rem;
      height: 35px;
      outline: none;
      overflow: hidden;
      padding: 0 13px;
      text-overflow: ellipsis;
      width: 100%;
      &:autofill {
        -webkit-text-fill-color: white;
        box-shadow: inset 0 0 0px 1000px $black;
      }
    }
  }
}
