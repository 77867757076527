.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.buttonsContainer {
  svg {
    path {
      fill: black;
    }
  }
  display: flex;
  gap: 20px;
}

input[type=checkbox]:before {

}