@import "assets/scss/variables";

.mymodal {
  max-width: 930px;
  min-height: 280px;
  width: calc(100% - 72px);
  //margin: 0 36px;
  position: fixed;
  top: 225px;
  left: 50%;
  transform: translate(-50%, -20%);
  overflow: auto;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.header {
  :global(.ag-header-cell) {
    padding-left: 15px;
    padding-right: 15px;
  }

  border: none !important;

  :global(.ag-header-container) {
    background-color: $gray-229 !important;
    border-bottom: 1px solid $gray-146;
  }

  :global(.ag-root-wrapper) {
    border: none !important;
  }

  :global(.ag-center-cols-container) {
    min-height: 100px !important;
  }

  :global(.ag-center-cols-clipper) {
    min-height: 100px !important;
  }
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.myoverlay {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.index {
  width: 10px;
}

.cursor {
  //transform: rotate(180deg);
}

.value {
  overflow: hidden;
  width: 100%;
  height: 100%;
  //text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    height: 16px !important;
    line-height: 16px;
    width: 100%;
    overflow: hidden;
    @media (max-width: 1024px) {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.accountTitle {
  font-weight: 600;
  color: $black
}

.nameTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
}

.fullName {
  margin: 0 40px 30px 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: $black;
}

.table {
  &_nodata {
    :global(.ag-body-viewport.ag-row-animation.ag-layout-auto-height) {
      background-color: rgba(255, 255, 255, 1);
    }

    :global(.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper) {
      font-size: 16px;
      font-style: italic;
      color: black;
    }
  }

  :global(.ag-center-cols-container) {
    min-height: 55px !important;
    height: 55px !important;
  }

  :global(.ag-center-cols-viewport) {
    min-height: 55px !important;
  }

  :global(.ag-center-cols-clipper) {
    min-height: 55px !important;
  }

  &_title {
    color: white;
    font-size: toRem(20);
    margin-bottom: toRem(25);
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_filter {
    width: 280px;
    padding: toRem(9) 20px;
    color: white;
    border: none;
    border-bottom: 2px solid #929292;
    outline: none;
    background-color: transparent;
    background-image: url("../../../../icons/filter_search.svg");
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: 90% center;

    &::-webkit-input-placeholder {
      color: $gray-146;
      font-style: italic;
      font-size: 13px;
    }
  }

  :global(.ag-row-group-expanded) {
    border-bottom: none;
  }

  :global(.ag-group-contracted) {
    :global(.ag-icon ) {
      background-image: url("../../../../icons/chevrons_down.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-group-expanded ) {
    :global(.ag-icon ) {
      background-image: url("../../../../icons/chevrons_up.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-theme-alpine) {
    font-family: $font-family-suisse;
  }

  :global(.ag-header) {
    border-bottom: 2px solid $gray-146;
    //background-color: $gray-244;
    overflow: visible;

    :global(.ag-header-viewport) {
      overflow: visible;
    }
  }

  :global(.ag-header-cell) {
    //padding-left: rem(35);
    padding-right: 0;

    &:first-child {
      //padding-left: rem(35);
    }
  }

  &_detail {
    display: flex;
    //padding-left: rem(150);
    //justify-content: space-between;
    height: 55px;
    font-size: 11px;
    align-items: center;

    svg {
      width: 11px;
      height: 11px;

      path {
        fill: black;
      }
    }
  }

  &_row {
    border-bottom: 1px solid $gray-146 !important;
    background-color: #fff !important;
    cursor: pointer;

    &:hover {
      background-color: $gray-249 !important;
    }

    :global(.ag-cell) {
      display: flex;
      align-items: center;
      white-space: break-spaces;
      font-size: 13px;
      //padding-left: rem(35);
      color: black;
      background-color: transparent !important;
      border: none !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.table_filter_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.table_filter {
  width: 200px;
  padding: 9px 15px;
  color: black;
  border: none;
  border-bottom: 2px solid #646464;
  outline: none;
  background-color: transparent;
}


:global(.ag-header-cell-text) {
  @media (max-width: 1024px) {
    white-space: break-spaces;
    text-overflow: inherit;
    overflow: visible;
  }
}