@import "../../../../assets/scss/variables";

.header {
  .select {
    border-radius: toRem(6);
    font-weight: 600;
    margin: 0 0 0 toRem(20);
    font-size: toRem(16);

    &:hover {
      background-color: $orange;
    }

    &:global(.is-open) {
      background-color: $orange;
    }

    :global(.Dropdown-control) {
      padding: toRem(12);
      @media screen and (max-width: 750px) {
        padding: .74rem;
      }
    }

    @media screen and (max-width: 990px) {
      margin: 0;
    }

  }

  .lng_menu {
    font-size: toRem(13);
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    border-radius: toRem(6);
    border: 1px solid #CBCBCB;
    box-shadow: 6px 6px 12px rgb(0 0 0 / 20%);
    background: #000000;
    padding-top: toRem(20);
    width: 170px;
    max-height: 250px;
    overflow-y: auto;
    font-weight: 400;
    line-height: 16px;
    z-index: 2; // To be above the alert banner

    &::-webkit-scrollbar-thumb {
      background: #CBCBCB;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 0px;
      background: transparent
    }

    :global(.Dropdown-option) {
      white-space: break-spaces;
      padding-top: toRem(10);
      padding-bottom: toRem(10);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 100%;
        top: 0;
      }

      &:hover {
        background-color: rgba(56, 56, 56, 1);
        color: white;

        &:before {
          background-color: rgba(217, 168, 27, 1);
        }
      }
    }
  }

  //display: flex;
  padding: 0;
  background: linear-gradient(180deg, #000000 73.33%, rgba(0, 0, 0, 0) 100%);
  z-index: 100;
  color: $white;
  align-items: center;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  flex-direction: column;
  top: 0;
  padding-bottom: 40px;

  &_fixheight {
    @media (max-width: 750px) {
      padding-bottom: 0;
      //height: 163.23px;
    }
  }

  &_fixheight {
    @media (max-width: 560px) {
      //height: 100px;
    }
  }

  @media (max-width: 750px) {
    background: black;
    padding-bottom: 10px;
  }

  .bg {
    background-image: url("../../../img/header-pattern.svg");
    background-repeat: no-repeat;
    background-position: calc((100vw - 1180px) / 2 + (1180px - 380px)) 0;
    background-size: contain;
    @media (max-width: 1024px) {
      background-image: url("../../../img/header-pattern_iPad1024.svg");
      background-position: calc((100vw - 800px) / 2 + (800px - 308px)) 0;
    }
    @media (max-width: 790px) {
      background-image: url("../../../img/header-pattern_iPad.svg");
      background-position: calc((100vw - 620px) / 2 + (620px - 300px)) 0;
    }
    @media (max-width: 750px) {
      background-image: url("../../../img/header-pattern_mobile.svg");
      background-position: 100% 0;
    }
  }

  .wrap {
    //background-image: url("../../../img/header-pattern.svg");
    //background-repeat: no-repeat;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 120px;

    .logoTitle {
      font-size: toRem(16);
      margin-left: toRem(7);
      font-family: $font-family-plaak;
    }

    .logo {
      width: 27px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logout {
      cursor: pointer;
    }

    @media (max-width: 1024px) {
      height: 100px;
    }
    @media (max-width: 750px) {
      height: 100px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .brandWrap {
    display: flex;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
    @media (max-width: 750px) {
      margin-left: 18px;
    }
  }
}

.nav {
  //background-image: url("../../../img/header-pattern.svg");
  display: flex;
  align-items: center;
  height: 100%;
  //width: 80%;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100px 0;
  //width: 480px;
  &_list {
    display: flex;
    position: relative;
    padding-bottom: toRem(16);
    margin-top: 25px;
    @media (max-width: 830px) {
      margin-top: 20px;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 0 !important
      }
    }
    @media (max-width: 750px) {
      margin-top: 20px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &_dropdown {
    display: none;
    margin-right: toRem(40);

    &_control {
      text-transform: uppercase;
      font-family: $font-family-plaak;
    }

    &_placeholder {
      text-transform: uppercase;
      color: $wcm-yellow;
    }

    &_menu {
      text-transform: uppercase;
    }

    &_arrow {
      path {
        fill: $wcm-yellow
      }
    }

    //@media (max-width: 1024px) {
    //  display: block;
    //}
  }

  &_item {
    &_link {
      position: relative;
      padding-bottom: toRem(15);
      text-transform: capitalize;
      color: rgba(100, 100, 100, 1);
      white-space: nowrap;

      &_active {
        color: $orange;

        &:after {
          content: "";
          height: 2px;
          width: 100%;
          bottom: 0;
          background-color: $orange;
          position: absolute;
          left: 0;
        }
      }

      @media (max-width: 750px) {
        border: 1px solid #CBCBCB;
        border-radius: 6px;
        padding: 8px 13px;
        &_active {
          color: white !important;
          background-color: $orange;
          border: 1px solid $orange;

          &:after {
            display: none;
          }
        }
      }
    }

    font-size: toRem(16);
    font-family: $font-family-suisse;
    font-weight: 600;
    margin-right: toRem(60);;
    @media (max-width: 990px) {
      //font-size: rem(13);
    }
    @media (max-width: 750px) {
      margin-right: toRem(15);
      font-size: toRem(13);
      display: flex;
      &:nth-last-child(2) {
        margin-right: 0;
      }
    }
  }

  &_icon {
    padding: 0 toRem(12);
    @media (max-width: 1024px) {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    //background-image: url("../../../img/header-pattern_iPad1024.svg");
    //width: 310px;
    //background-position: 0 0;
  }
  @media (max-width: 790px) {
    //background-image: url("../../../img/header-pattern_iPad.svg");
    //width: 300px;
    //background-position: 20px 0;
  }
  @media (max-width: 750px) {
    //background-image: url("../../../img/header-pattern_mobile.svg");
    //width: 167px;
    padding-right: 18px;
    background-position: 0 0;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  padding: 12px;
  margin-left: toRem(20);
  @media (max-width: 750px) {
    margin-left: 2px;
    &_profIcon, &_msg {
      //display: none;
    }
  }

  &_active {
    background-color: $orange;
  }

  &:hover {
    background-color: $orange;
  }
}

.active {
  background-color: $orange;
}

.profileIcon {
  cursor: pointer;
}

.alertBanner {
  margin-bottom: 30px;
}

.navBarContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.greetingText {
  width: 100%;
  margin: 0 !important;
  @media (max-width: 750px) {
    display: none;
  }

}