@import "assets/scss/variables";

.SetUserControlModal {
  text-align: center;
  z-index: 10000000;
}

.mymodal {
  max-width: 470px;
  height: 237px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  padding: 40px;
  background: black;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1000000000;
  color: white;
  border: 1px solid $orange;
  @media (max-width: 750px) {
    max-width: 350px;
  }
}

.suspendModal {
  height: 268px;
}

.roleModal {
  max-width: 329px;
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.title {
  margin: 0 0 17px 0;
  line-height: 30px;
  font-size: 26px;
}


.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  //background-color: rgba(0, 0, 0, 0.75) !important;
  background-color: rgba(20, 20, 20, 0.75) !important
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.error {
  font-size: 13px;
  font-weight: 600;
  color: $black;
  margin-left: 20px;
}


.successModal {
  display: flex;
  width: 100%;
  justify-content: center;

  span {
    font-size: 13px;
    font-weight: 600;
    color: $black;
    margin-right: 4px;
  }

  .userIcon {
    width: 17px;
    margin-right: 11px;
  }
}

.userName {
  font-weight: 600;
  font-size: 20px;
  color: $wcm_dark;
  margin-right: 10px;
}


.description {
  font-size: 13px;
  line-height: 20px;
  margin: 30px 0 40px 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin-left: 15px;

  &_primary {
    &:disabled {
      color: white;
      background-color: black !important;
    }
    &:hover {
      background-color: $orange;
      color: white;
    }
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 11px;
  }
}
