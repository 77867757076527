@import 'assets/scss/variables';
@mixin font {
  font-family: $font-family-suisse;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.selectFieldContainer {
  background: red;
}

.control {
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  display: flex;
  min-height: 35px !important;
  background: white;
  border: 1px solid #CBCBCB;
  width: 100%;
}

.menu {
  margin-top: 2px;
  z-index: 99;
  border: 1px solid #CBCBCB;
  background: white;
  overflow: hidden;
  padding: 15px 0;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, .2) 3px 3px 6px 0;
}

.option {
  padding: 0 15px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 1px solid transparent;;
  color: #383838;
  cursor: pointer;
  &:hover {
    border-left: 1px solid black;
    background: #F4F4F4;
  }
}


.select {
  @include font;


  &:focus {
    outline: none;
  }

  &.error {
    border-color: blue;
  }

  &.disabled {
    background: lightpink;
    color: darkGray;
    cursor: not-allowed;
  }
}

.multiValue {
  align-items: center;
  background-color: $wcm_yellow;
  border-radius: 16px;
  color: $white;
  display: inline-flex;
  font-size: 12px;
  gap: 3px;
  padding: 4px 12px;
}

.multiValueContainer {
  gap: 5px;
  padding: 3px 0;
}
