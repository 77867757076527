@import "assets/scss/variables";

.shortNumber {
  display: flex;
  align-items: center;
}

.payeeCodeBlock_btn {
  background-color: #fff;
  color: black;
  padding: 6px 10px;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 600;
}

.item {
  &_border {
    position: relative;
    min-width: 100%;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 80px;
      background-color: #929292;
      right: 0;
      top: -15px;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    &_long {
      &::after {
        right: 40px;
      }
    }
  }

  :global(.payeeCodeTitle) {
    display: flex;
    align-items: center;
  }

  &_info {
    font-size: toRem(11);
    color: $gray-203;
    margin-top: toRem(8);
    max-width: 450px;
    @media (max-width: 1024px) {
      display: inline-block;
      padding-right: 80px;
    }
  }

  color: inherit;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;

  &_big {
    padding: 0;
    @media (max-width: 1024px) {
      //margin-bottom: rem(30);
    }
  }


  &_name {
    margin-bottom: toRem(5) !important;
    display: flex;
    align-items: center;

    &_big {
      margin-bottom: toRem(10);
      font-size: toRem(13);
      font-weight: 400;
    }
  }

  .percent {
    &_label {
      display: inline-block;
      vertical-align: middle;
    }

    &_text {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
    }
  }

  &_label {
    &_medium {
      font-size: toRem(32);
      @media (max-width: 1024px) {
        font-size: toRem(26) !important;
      }
    }

    &_percentage {
      font-size: toRem(16);
      font-weight: 400;
      margin-left: toRem(5);
      line-height: 20px;
      color: #00AE52;

      &_dec {
        color: red;
      }
    }

    font-weight: 600;
    display: flex;
    align-items: center;

    &_percent {
      @media (max-width: 750px) {
        display: block;
        line-height: 1;
      }
    }

    @media (max-width: 1024px) {
      //font-size: rem(13);
    }
    @media (max-width: 990px) {
      font-size: toRem(11);
    }

    &_big {
      font-size: toRem(40);
      @media (max-width: 1024px) {
        font-size: toRem(36);
      }
    }

    &_middle {
      font-size: toRem(20);
    }

    &_alignRight {
      text-align: right;
      @media (max-width: 1024px) {
        text-align: right;
      }
      @media (max-width: 750px) {
        text-align: left;
      }
    }
  }


  @media (max-width: 1024px) {
    display: inline-block;
    //margin-bottom: 20px;
  }
  @media (max-width: 790px) {
    display: block;
    //margin-bottom: 20px;
    //margin-right: 35px;
  }
}

.payeeCodeLabelChildren {
  &_rotate {
    svg {
      transform: rotate(180deg);
    }
  }
}

.precentageicon {
  width: toRem(16);
  height: toRem(16);
  margin-left: toRem(5);

  &_rotate {
    transform: rotateX(180deg);
  }
}

.icon {
  position: absolute;
  width: toRem(16);
  height: toRem(16);
  left: 0;
  top: 0;

  &_hovered {
    display: none;
  }

  &_wrapper {
    position: relative;
    margin-left: toRem(5);
    width: toRem(16);
    height: toRem(16);
    cursor: pointer;

    &:hover {
      .icon {
        display: none;

        &_hovered {
          display: block;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    width: toRem(16);
    height: toRem(16);
  }
}

.bigIcon {
  svg {
    width: 16px;
    height: 16px;

    path {
      width: 16px;
      height: 16px;
    }
  }
}
