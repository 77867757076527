@import "assets/scss/variables";

.dialog {
  background: black;
  border: 1px solid $orange;
  border-radius: 6px;
  color: $white;
  max-width: 300px;
  padding: 20px 30px;
  width: 100%;
}

.dialogContent {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 40px;
}
