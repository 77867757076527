@import "assets/scss/functions";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: toRem(26);
  font-weight: 600;
  line-height: toRem(32);
}



.rangeContainer {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;

  p {
    flex: 0;
    display: inline-flex;
    white-space: pre;
    text-align: center;

    justify-content: flex-start;
  }
}

.statusContainer {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.messageLink {
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;
}

.tooltipContainer {
  display: flex;
  gap: 15px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  svg {
    path {
      fill: black;
    }
  }
}

.endAnnouncementTooltip {
  background-color: #d42b3a;
  :global {
    .MuiTooltip-arrow::before {
      background-color: #d42b3a;
    }
  }
}
