@import "../../../assets/scss/variables";

.container {
  color: white;
  display: flex;
  height: 100svh;
  flex-direction: column;
}

.content {
  margin-top: -100px; // Align the content by height of the header
}

.description {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0;
}

.header {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  padding: 30px 0;
}

.main {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.siteName {
  font-family: $font-family-plaak;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
}

.title {
  font-family: $font-family-plaak;
  font-size: 36px;
  line-height: 42px;
  margin: 30px 0;
  @media (min-width: 768px) {
    font-size: 47px;
    line-height: 55px;
  }
  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 65px;
  }
}
