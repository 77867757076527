@import "assets/scss/variables";

.topIncomeGroups {
  display: grid;
  grid-template-columns: 1.18fr 2fr;
  grid-gap: 20px;
}

.topIncomeGroupsWrap {
  background: $black;
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 30px 30px 10px 30px;
  margin-top: 20px;
}

.earnings {
  max-width: 430px;
}

.workTitle {
  color: $black;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
}

.creators {
  color: $black;
  font-size: 11px;
}

.previousAmount {
  color: $black;
  margin-bottom: 10px;
  margin-right: 15px;
}

.table {
  min-width: 515px;

  tbody {
    tr {
      min-height: 65px;
    }

    td {
      height: 65px;
    }
  }
  :global(.customHeader) {
    justify-content: flex-end;
    @media (max-width: 1023px) {
      justify-content: flex-start;
    }
  }
}

.chevron {
  margin-bottom: 16px;
}

.end {
  justify-content: flex-end;
  padding-right: 12px !important;
}

.nameWrap {
  margin-bottom: 14px;
}

.tableValue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: 8px;
  margin-right: 15px;
  .amount {
    margin-bottom: 4px;
    color: $black;
  }

  .noPercentage {
    margin-bottom: 18px;
  }

  p {
    margin: 0;
  }
}

.increment {
  color: $wcm_green174;
}

.decrement {
  color: $wcm_danger;
}

.table_detail {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.row {
  height: 65px;
  align-items: center;
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $gray-146;
  padding: 0 72px;
}

.callapsClassName {
  height: fit-content !important;
  color: $black;
}

.infoWrap {
  display: flex;

  .pers {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 138px;

  }

  .prev {
    margin-right: 20px;
  }
}

.wrap {
  font-size: 11px;

  .name {
    font-weight: 600;
    margin: 8px 0;
    text-transform: uppercase;
  }
}

.empty {
  background: black;
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 40px 80px;
}

.hovered {
  color: $white;

  svg {
    path {
      fill: $white !important
    }
  }
}

@media screen and (max-width: 1270px) {
  .topIncomeGroupsWrap {
    padding: 0;
    border: none;
    background-color: inherit;
  }
  //.end {
  //  justify-content: flex-start;
  //}
  .row {
    padding: 0 150px 0 72px;
  }
  //.tableValue {
  //  align-items: flex-start;
  //}
  .topIncomeGroups {
    grid-template-columns: 1fr 3fr;
  }
  .earningsWrap {
    max-width: 100% !important;
    border: none !important;
    background-color: inherit !important;
    padding: 0 !important;
    min-height: fit-content !important;
  }
  .table {
    margin-top: 45px;
  }
  .earnings {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .row {
    padding: 0 120px 0 72px;
  }
}

@media screen and (max-width: 1023px) {
  .tableValue {
    align-items: flex-start;
  }
  .row {
    padding: 0 160px 0 72px;
  }
  .topIncomeGroups {
    grid-template-columns: 1fr;
    display: block;
  }
  .table {
    margin-top: 0;
  }
  .earnings {
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .row {
    padding: 0 16px;
  }
  .pers {
    width: 100px !important;
  }
  .prev {
    display: none;
  }
  .table {
    min-width: 100%;
    th {
      padding: 0 8px !important;
    }

    td {
      padding: 0 8px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .table {
    max-width: 365px;
  }
  .wrap {
    max-width: 90px !important;
    width: 90px !important;
  }
}


