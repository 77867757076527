@import "../../../assets/scss/variables";

.history {
  margin-top: 30px;
  @media (max-width: 650px) {
    padding: 0 toRem(2);
  }
}

.pageTitle {
  color: white;
  font-size: toRem(26);
  margin-bottom: toRem(20);
  margin-top: 0;
  @media (max-width: 750px) {
    font-size: toRem(20);
  }
}

.advanceContainer {
  @media (max-width: 650px) {
    margin-top: 30px;
  }
}

.description {
  color: white;
  font-size: toRem(16);
  max-width: 759px;
  margin-bottom: toRem(30);
  margin-top: 0;
  line-height: toRem(20);
  @media (max-width: 650px) {
    font-size: toRem(13);
  }
}

.historyTitle {
  font-size: toRem(16);
  color: white;
  margin-bottom: toRem(20);
  margin-top: toRem(50);
}

.historyList {
  color: white;
  max-width: 300px;
}

.historyItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-146;
  padding-bottom: toRem(12);
  padding-top: toRem(12);

  &:nth-of-type(1) {
    border-top: 1px solid $gray-146;
  }

  &_date {
    font-weight: bold;
  }
}

.historyBlock {
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding-left: 40px;
  padding-top: 30px;
  max-width: 350px;
  padding-bottom: 40px;
  color: white;
  flex-basis: 350px;
  flex-grow: 0;
  flex-shrink: 0;
  @media (max-width: 750px) {
    flex-basis: 300px;
    max-width: 300px;
  }
  @media (max-width: 650px) {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
    border: none;
  }

  p {
    font-size: 16px;
  }

  .amount {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }

  .date {
    font-size: 20px;
    font-weight: bold;
  }

  &_title {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    p {
      font-size: 16px;
    }

    &_date {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .date {
      color: white;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.firstElement {
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 60px;
  @media (max-width: 650px) {
    display: block;
  }

  &_msg {
    font-size: 16px;
    @media (max-width: 650px) {
      font-size: 13px;
    }

    p:nth-of-type(2) {
      margin-top: 16px;

    }
  }
}

.hideOnMobile {
  @media (max-width: 650px) {
    display: none;
  }
}

.hideOnDesktop {
  @media (min-width: 651px) {
    display: none;
  }
}