@import "assets/scss/variables";
.break {
  display: none;
  @media (max-width: 450px) {
    display: block;
  }
}
.block {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    .text_underline {
      color: $orange;
      &:after {
        opacity: 1;
      }
    }
    .arrowIcon path,.calendarIcon  path{
      fill: $orange;
    }
  }
  @media (max-width: 440px) {
    margin-right: 20px;
  }
}
.text {
  font-size: 13px;
  margin: 0 10px;
  &_underline {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: $orange;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
  @media (max-width: 440px) {
   max-width: 230px;
  }
}
.date {
  font-weight: 600;
  margin-left: 2px;
  @media (max-width: 990px) {
    font-size: 11px;
  }
}

.arrowIcon {
  transform-origin: center;
  &_rotate {
    transform: rotate(180deg);
  }
}