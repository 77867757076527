@import "assets/scss/variables";

.SetUserControlModal {
  text-align: center;
  z-index: 10000000;
}

.mymodal {
  max-width: 697px;
  height: 247px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1000000000;
}

.suspendModal {
  height: 268px;
}

.roleModal {
  max-width: 329px;
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  cursor: pointer;
}

.title {
  margin: 0 0 17px 0;
  line-height: 30px;
  font-size: 26px;
  color: $black;
}

.description {
  font-size: 13px;
  color: $wcm_dark;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .button {
    margin-left: auto;
    background-color: $wcm_gray_darker;
    border: 1px solid $wcm_gray_darker;
    height: 35px;
  }

  .loadingCircle {
    height: 35px;
  }
}


.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  //background-color: rgba(0, 0, 0, 0.75) !important;
  background-color: rgba(20, 20, 20, 0.3) !important
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.error {
  font-size: 13px;
  font-weight: 600;
  color: $black;
  margin-left: 20px;
}

.userError {
  display: flex;
  align-items: center;
  color: $wcm_danger;
  font-size: 11px;

  .warning {
    margin-right: 10px;
    width: 13px;
  }
}

.success {
  max-width: 277px;
  padding: 26px;
  justify-content: center;
  height: 69px;
  top: 30%
}

.successModal {
  display: flex;
  width: 100%;
  justify-content: center;

  span {
    font-size: 13px;
    font-weight: 600;
    color: $black;
    margin-right: 4px;
  }

  .userIcon {
    width: 17px;
    margin-right: 11px;
  }
}

.userName {
  font-weight: 600;
  font-size: 20px;
  color: $wcm_dark;
  margin-right: 10px;
}

.userRole {
  font-size: 16px;
  line-height: 20px;
  color: $gray;
}

.userEmail {
  font-size: 13px;
  line-height: 20px;
  color: $wcm_dark;
}

.description {
  font-size: 13px;
  line-height: 20px;
  color: $wcm_dark;
  margin: 20px 0 40px 0;
}

.suspendWrap {
  display: flex;
  align-items: flex-end;

  p {
    font-weight: 600;
    font-size: 20px;
    color: $wcm_dark;
    margin-right: 10px;
    line-height: 20px;
  }

  span {
    font-size: 16px;
    color: $gray;
    line-height: 16px;
  }
}

