.newDocument {
  display: flex;
  align-items: center;
  gap: 8px;

  &::after {
    display: flex;
    width: 10px;
    height: 10px;
    content: "";
    border-radius: 50%;
    background-color: #D9A81B;
  }
}