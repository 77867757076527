@import "assets/scss/variables.scss";
.ReactModalPortal {
  position: relative;
  z-index: 100;
}
body, html {
  font-size: 13px;
}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray;
  font-family: $font-family-suisse;
  background-color: $black;
}

.select {
  &__control {
    border-bottom: 1px solid blue;
    height: 100% !important;
  }

  &__single-value {
    font-size: toRem(13) !important;
    @media (max-width: 990px) {
      font-size: toRem(11) !important;
      line-height: 1.2;
      margin-left: 0 !important;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $wcm_yellow;
  }
}

.ff-Plaak {
  font-family: $font-family-plaak
}

.ff-Chappell {
  font-family: $font-family-chappell;
}

//FONTS
.font {
  &-bold {
    font-weight: 700;
  }

  &-thin {
    font-weight: 100;
  }

  &-white {
    color: white;
  }

  &-black {
    color: black;
  }

  &-semibold {
    font-weight: 600;
  }
}


//TEXT
.text {
  &-xl {
    font-size: 1.25rem;
  }

  &-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  &-11 {
    font-size: 11px;
    line-height: 13px;
  }

  &-13 {
    font-size: 13px;
    line-height: 16px;
  }

  &-wcm-gray {
    color: $wcm_gray;
  }

  &-gray-darker {
    color: $wcm_gray_darker;
  }

  &-wcm-gray-darker {
    color: rgba(56, 56, 56, 1);
  }

  &-wcm-accent {
    color: $wcm_yellow;;
  }

  &-wcm-green {
    color: $wcm_green;
  }

  &-wcm-danger {
    color: $wcm_danger;
  }

  &-gray-dark {
    color: $gray;
  }

  &-base {
    font-size: 1rem;
  }

  &-wcm-dark {
    color: $wcm_dark;
  }

  &-overflow-unset {
    text-overflow: unset !important;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-black {
    color: black;
  }
}


//FLEX
.d-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}


//SIZES
.w-50 {
  width: 50%;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.w-4 {
  width: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-9 {
  height: 2.5rem;
}

.w-6 {
  width: 1.5rem;
}

.w-3 {
  width: .75rem;
}

.h-3 {
  height: .75rem;
}

.w-100 {
  width: 100%;
}

.d-inline-block {
  display: inline-block;
}


//PADDING-MARGIN
.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .65rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}


.ml-minus-4 {
  margin-left: -1rem;
}


.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}


.mr-1 {
  margin-right: .25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pl-15 {
  padding-left: toRem(15);
}

.p-6 {
  padding: 1.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

//DISPLAY
.hidden {
  display: none;
}

.ag-row-hover {
  .actions-layer {
    display: block;
  }

  .status-updated-by-cell {
    display: none;
  }
}

.-right-2 {
  right: -.5rem;
}

.top-0 {
  top: 0;
}


//POSITIONS
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.ag-root ::-webkit-scrollbar {
  width: 3px;
  height: 0;
}

.ag-root ::-webkit-scrollbar-button {
  //background: #ccc
}

.ag-root ::-webkit-scrollbar-track-piece {
  //background-color: #a0a0a0;
  background: #e2e2e2;
  border-radius: 4px;
  display: none;
}

.ag-root ::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  //background: #888;
  //border: none;
  border-radius: 4px;
  display: none;
}

.hover\:text-wcm-accent:hover {
  color: $wcm_yellow;
}


button {
  background-color: transparent;
  background-image: none;
  border: none;
  outline: none;
}

[role=button], button {
  cursor: pointer;
}


audio, canvas, embed, iframe, img, object, svg, video {
  display: inline-block;
  vertical-align: middle;
}

.remove-after-before {
  &:after,
  &:before {
    display: none;
  }
}

.border-gray-lighter {
  border-color: rgba(229, 229, 229, 1);
}


.ReactModal__Overlay {
  background-color: transparent !important;
}


.bg-wcm-accent {
  background-color: $wcm_yellow
}


.overflow-visible {
  overflow: visible !important;
}


.cursor-pointer {
  cursor: pointer;
}

.d-block {
  display: block;
}

.modal {
  &-btn {
    &-group {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &-sm {
    width: 500px;
  }
}

.z1 {
  z-index: 1;
}

p {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  max-width: 1180px;
  width: 100%;
}

.hideOnDesktop {
  display: none;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

@media (max-width: 1280px) {
  .container {
    padding: 0 toRem(18);
  }
}

@media (max-width: 1024px) {
  .hideOnMobile {
    display: none;
  }
  .hideOnDesktop {
    display: block;
  }
  .container {
    max-width: 100%;
    width: 100%;
    padding: 0 toRem(18);
  }
}

@media screen and (max-width: 990px) {
  .container {
    padding: 0 toRem(18);
  }
}

@media screen and (max-width: 750px) {
  .container {
    padding: 0 toRem(18);
  }
}

.wcm-select__menu-portal {
  z-index: 2200 !important;
}

.searchMarked {
  background-color: lighten($orange, 35);
  border-radius: 3px;
}

// AG grid no-rows overlay styles
.noRowsTitle {
  color: black;
  text-transform: uppercase;
  font-size: 22px
}

.noRowsSubtitle {
  color: #646464;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}