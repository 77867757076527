@import "assets/scss/variables";

.modal {
  max-width: 600px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.3) !important
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: $black;
  }

  .closeIcon {
    cursor: pointer;

    path {
      fill: $black;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .fieldWrapper {
    width: 250px;

    .fieldLabel {
      font-size: 11px;
      font-weight: 600;
      line-height: 13px;
      color: $black;
      margin-bottom: 5px;
    }

    .field {
      border: 1px solid $gray_light;
      height: 38px;
      max-width: 250px;
      width: 100%;
      border-radius: 0;
      outline: none;
      padding-left: 15px;
      font-size: 13px;
      font-style: italic;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.87);
      }

      .calendar {
        position: absolute;
        right: 15px;

        path {
          fill: $gray;
        }
      }
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;

  .downloadButton {
    width: 72px;
    height: 25px;
    border: 1px solid #CBCBCB;
    border-radius: 6px;
    background-color: #E5E5E5;

    &:hover {
      background-color: #CBCBCB;
    }

    span {
      font-size: 11px;
      font-weight: 600;
      line-height: 13px;
      color: #929292;
      padding: 6px 10px 6px 10px;
    }
  }
}

