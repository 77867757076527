.progress {
  margin-bottom: 20px;
}

.progress[value] {
  height: 10px;
  width: 100%;
  appearance: none;

  &::-webkit-progress-bar {
    background: rgba(229, 229, 229, 1);
    border: 1px solid rgba(229, 229, 229, 1);
  }

  &::-webkit-progress-value {
    transition: width 0.1s ease-in-out;
    background: rgba(217, 168, 27, 1);
  }
}