@import "../../../../assets/scss/variables";

.item {
  font-weight: 600;
  font-size: toRem(16);
  margin-right: toRem(60);
  position: relative;
  padding-top: 0.75rem;
  cursor: pointer;
  //min-width: 100px;
  span {
    position: relative;
    display: inline-block;
    padding-bottom: toRem(15);
  }

  &.active {
    color: $orange;

    span {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $orange;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.items {
  display: flex;
  justify-content: space-between;
  margin-bottom: toRem(21);
  &.hidden {
    visibility: hidden;
  }
}

.link {

}

@media (max-width: 1024px) {
  .items {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}