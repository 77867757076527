@import "../../../../assets/scss/variables";

.footer {
  height: toRem(198);
  color: $white;
  display: flex;
  align-items: center;
  position: relative;

  .logoWrap {
    display: flex;
    align-items: center;

    .logo {
      width: toRem(21);
      height: toRem(30);

      path {
        fill: $white,
      }
    }

    .logoTitle {
      font-weight: 600;
      font-size: toRem(16);
      font-family: $font-family-plaak;
      margin-left: toRem(7);
    }
  }

  .info {
    font-weight: 400;
    font-size: toRem(13);
    margin: toRem(20) toRem(31) toRem(20) toRem(31);

    p {
      margin: 0;
    }
  }

  .links {
    margin-left: toRem(31);
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;

    a {
      margin-right: toRem(8);
      margin-bottom: toRem(8);
      padding-right: toRem(8);
      border-right: 1px solid $white;
    }

    a:last-of-type {
      border: none;
    }
  }

  .language {
    display: flex;
    align-items: center;
    margin-left: toRem(28);
    margin-bottom: toRem(25);
    font-size: toRem(13);

    .select {
      height: toRem(17);
      margin-left: toRem(15);
      font-size: toRem(13);
    }
  }

  @media (max-width: 790px) {
    height: auto;
  }

  &_hide {
    display: none;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.scrollWrap {
  position: fixed;
  bottom: 0;
  display: flex;
  height: 35px;
  justify-content: flex-end;
  width: 100%;
  max-width: 1380px;
  margin-left: auto;
  z-index: -1;
}

.scrollTop {
  bottom: 0;
  cursor: pointer;
  z-index: 100;
}

.disableScroll {
  display: none;
}

@media screen and (max-width: 1024px) {
  .scrollWrap {
    right: 0;
    z-index: 1;
    width: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .links {
      flex-direction: column;

      a {
        border: none;
      }
    }
  }
  .scrollTop {
    right: 16px;
  }
}