@import "../../../../assets/scss/variables";

.btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.txt {
  font-weight: 600;
  margin-right: toRem(20);
  color: $gray;
}

.icon {
  width: toRem(10);
  height: toRem(13);
}