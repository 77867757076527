.Architect {

  &_container {
    min-height: calc(100vh);
    background-color: $white;
  }

  &_content {
    //padding-left: 1.25rem;
    //padding-top: 2.5rem;
    //padding-bottom: 2.5rem;
    //min-width: 1000px;
    //margin-left: 264px;
  }

  &_sidebar {
    padding-bottom: 20px;
    padding-left: 30px;
    position: fixed;
    width: 230px;
    top: 135px;
    border-right: 3px solid $wcm_gray;
    min-height: calc(100vh - 270px);
  }

  &_filter {
    &_modal {
      max-width: 800px;
    }
  }
}

.input {
  &-control {
    width: 100%;
    margin-bottom: 1.5rem;
    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }
    .MuiInputBase-root {
      border-radius: 0;

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $orange;
          border-width: 2px;
        }
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: $orange;
    }

    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.find-input {
  .MuiIconButton-root {
    padding: 1px;
  }
  .MuiInputBase-root {
    border-radius: 0;
    margin-left: 1rem;
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #929292;
        border-width: 1px;
      }
      &::after {
        border-bottom: 1px solid #929292;
      }
    }
  }
  .MuiInput-underline{
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #929292;
    }
    &:after {
      border-bottom: 1px solid #929292;
    }
    &:before{
      border-bottom: 1px solid #929292;
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: #929292;
  }

  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-input{
    &::placeholder {
      font-style: italic;
      font-size: 13px;
      font-family: $font-family-suisse;
    }
  }
}

.wcm {
  &-select {
    &__menu {
      top: 100%;
      background-color: hsl(0, 0%, 100%);
      border-radius: 0;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
      margin-bottom: 8px;
      margin-top: 0px;
      position: absolute;
      width: 100%;
      z-index: 1;
      box-sizing: border-box;
    }
  }
}
