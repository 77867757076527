@import "assets/scss/variables";

.icon {
  width: 1rem;
  height: 1rem;

  &_wrapper {
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    &_active {
      background-color: $wcm_yellow;

      svg path {
        fill: white;
      }
    }

    &:hover {
      background-color: $wcm_yellow;

      svg path {
        fill: white;
      }
    }
  }
}

.bar {
  margin-left: auto;
  align-self: flex-end
}

.input {
  width: toRem(220);
  margin-right: 10px !important;

  input {
    padding-left: 11px;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  :global(.MuiInputBase-root) {
    width: 100%;
    margin-left: 0;
  }
}

.dropdown {
  width: 180px;

  :global(.select__control) {
    border-bottom: 1px solid #929292 !important;

    svg path {
      fill: black;
    }
  }

  :global(.select__control.select__control--menu-is-open) {
    box-shadow: none;
  }

  :global(.select__placeholder) {
    color: black;
  }

  :global(.select__single-value), :global(.select__option) {
    color: black;
    font-weight: 600;
  }
}

:global(.wcm-select__control) {
  :global(.wcm-select__dropdown-indicator) {
    transform: rotate(-180deg);
    cursor: pointer;
  }
}

:global(.wcm-select__control--menu-is-open) {
  :global(.wcm-select__dropdown-indicator) {
    transform: rotate(0);
  }
}

:global(.wcm-select__group-heading) {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #383838 !important;
  text-transform: unset !important;
  padding-left: 18px !important
}

:global(.wcm-select__group) {
  :global(.wcm-select__option) {
    padding-left: 45px !important;
  }

  :global(.wcm-select__option--is-selected) {
    padding-left: 70px !important;
    background-position: 45px center;
  }

  //padding-left: 6px !important;
}

:global(.wcm-select__option) {
  //transition: padding 300ms ease-in-out,
  //background-image 300ms ease-in-out 300ms;
}

:global(.wcm-select__indicator-separator) {
  display: none !important;
}

:global(.wcm-select__option--is-selected) {
  background-image: url("../../../icons/checkmark_black.svg");
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 35px !important;
}

.component {
  margin-bottom: 20px;

  b {
    color: black;
    margin-right: 15px;
  }
}

.pointer {
  cursor: pointer;
}

b.underline {
  text-decoration: underline;
  color: $orange;
}