@import '/assets/scss/variables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3) !important;
  z-index: 1;
}

$newFileColumnWidth: 150px;

.modal {
  display: inline-flex;
  padding: 25px 32px;
  flex-direction: column;
  align-items: flex-start;
  min-height: 377px;
  min-width: 660px;
  gap: 10px;
  position: fixed;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: black;

    form {
      width: 100%;
    }

    header {

      .title {
        font-size: 22px;
        margin: 0 0 20px;
      }

      .subtitle {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }

      .description {
        font-family: "Suisse Int'l";
        list-style: decimal-leading-zero inside none;
        padding-left: 14px;
        width: 550px;

        li {
          font-weight: 600;
          list-style-position: outside;
        }
      }
    }

    main {
      margin-top: 15px;

      .duplicateList {
        background-color: #F9F9F9;
        max-height: 253px;
        overflow: auto;

        &::-webkit-scrollbar {
          height: 0 !important;
          width: 0;
        }
      }

      .duplicateHeader {
        padding: 5px 10px;
        display: flex;
        font-weight: 600;
        border-bottom: 2px solid #646464;
        gap: 20px;

        .newFileHeader {
          width: $newFileColumnWidth;
        }

        .existingFileHeader {
          flex: 1 auto;
        }

        .actionHeader {
          display: flex;
          justify-content: flex-end;
        }

      }

      .duplicateRow {
        border-bottom: 1px solid #646464;
        display: flex;
        padding: 10px;
        max-height: 40px;
        box-sizing: content-box;
        gap: 20px;

        .newFileColumn {
          display: flex;
          width: $newFileColumnWidth;
          flex-wrap: nowrap;
          align-items: center;
          gap: 5px;
          height: 15px;


          p {
            display: inline;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: underline;
            cursor: pointer;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &:hover{
            color: $orange;
          }
        }

        .existingFileColumn {
          max-width: 240px;

          .fileName {
            cursor: pointer;
            text-decoration: underline;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fileDescription {
            margin-top: 3px;
            width: 200px;
            display: flex;
            flex-direction: row;

            span {
              color: #383838;
              font-style: italic;
              margin-right: 5px;
              font-size: 11px;
              line-height: 13px;
            }
          }
          &:hover{
            color: $orange;
          }
        }

        .actionColumn {
          display: flex;
          justify-content: flex-end;

          .actionOptions {
            max-width: 180px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .option {
              cursor: pointer;
              display: flex;
              gap: 5px;

              .radioLabel {
                order: 0;
                font-size: 11px;
                font-weight: 600;
                color: #646464;
              }

              .radioButton {
                order: 1;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 1px solid black;
                display: block;
                top: 2px;
                position: relative;
                background-color: #ffffff;

                &:before {
                  content: "";
                  width: 4px;
                  height: 4px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  background-color: #ffffff;
                  border-radius: 50%;
                  opacity: 0;
                }
              }

              .input {
                visibility: hidden;
                width: 0;
                height: 0;

                &:checked ~ .radioLabel {
                  color: black;
                }

                &:checked ~ .radioButton {
                  background-color: #000000;

                  &:before {
                    opacity: 1;
                  }
                }
              }

            }
          }

          .actionOverview {
            display: flex;
            justify-content: flex-end;
            width: 140px;
            &.overwriteWarning {
              color: #D42B3A;
            }
          }

        }
      }

    }

    footer {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 40px;

      .buttonsContainer {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }

}