@import "assets/scss/variables";

.item{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  .label{
    color: $black;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .title{
    color: $wcm_gray_darker;
    margin-bottom: 12px;
  }
  .desc{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .description{
      color: $black;
      font-weight: bold;
    }
  }
}