.inputContainer {
  display: flex;
  flex: 1;
  border: 1px solid #CBCBCB;
  background: white;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  .input {
    align-items: center;
    justify-content: space-between;
    height: 35px;
    display: flex;
    min-height: 35px;
    outline: none;
    border: none;
    flex: 1 auto;
    text-overflow: ellipsis;
    &:focus,
    &:-webkit-autofill{
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
}