
.filterContainer {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;

  section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 150px;
    &:last-child {
      margin-left: auto;
    }
  }

  .downloadButton {
    border-radius: 5px;
    white-space: nowrap;
  }
}

:global {
  .select__menu {
    z-index: 2 !important;
  }
}
// Tablet
@media (max-width: 750px) {
  .filterContainer {
    margin-top: 20px;
  }
}

// Mobile
@media (max-width: 567px) {
  .filterContainer {
    flex-direction: column;
    margin-top: 10px;
    section {
      max-width: 100%;
      &:last-child {
        margin-left: unset;
      }
    }
  }
}