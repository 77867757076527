@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/functions";


:global {
  .select--is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  :local(.dropdown) {
    line-height: toRem(16);
    font-size: toRem(13);
    font-family: $font-family-suisse;
    width: 100%;

    & .select {

      &__control {
        background: white;
        border-bottom: 1px solid #C1C4CC;
        font-style: italic;
        cursor: pointer;
        padding: 10px 15px 8px 15px;
        min-height: toRem(35);

        &--menu-is-open {
          box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.20);
          border-bottom: 1px solid black;
        }
      }

      &__value-container {
        color: $gray;

        &--has-value {
          font-style: normal;
          color: $black;

        }
      }

      &__menu {
        color: $wcm_dark;
        display: flex;
        background: white;
        margin-top: 5px;
        color: black;
        max-height: toRem(170);
        border: 1px solid $gray_light;
        border-radius: 6px;
        padding: toRem(10) 0;
        box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.20);
        z-index: 100;
        &-list {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }

      &__option {
        color: $wcm_dark;
        display: flex;
        padding: 10px 18px 9px 18px;
        cursor: pointer;
        border-left: 2px solid white;
        text-transform: capitalize;

        &:hover {
          border-color: black;
          background-color: $gray-244;
        }
      }
    }
  }
}



