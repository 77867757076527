@import "assets/scss/variables";

.totalEarnings {
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 35px 41px;
  min-height: 148px;
  background-color: $black;
}

.header {
  font-size: 16px;
  line-height: 20px;
  color: $white;
  margin-bottom: 10px;
}

.icon {
  margin-inline-start: 5px;
}

.wrap{
  display: flex;
  align-items: center;
  .value{
    font-weight: 600;
    font-size: 40px;
    line-height: 38px;
    color: $white;
    margin-inline-end: 5px;
  }
  .percent{
    font-size: 16px;
    line-height: 20px;
    color: $wcm_green174
  }
  .isIncrement{
    color: $wcm_danger
  }
  .upIcon{
    width: 16px;
    height: 18px;
    size: 18px;
    margin-inline-start: 3px;
  }
}

@media screen and (max-width: 1270px) {
  .wrap{
    .value{
      font-size: 36px;
    }
  }
}