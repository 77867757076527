@import "assets/scss/variables";

.closeResultButton {
  margin-top: 30px;
}

.commandBar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  @media (min-width: 640px) {
    align-items: center;
    flex-direction: row;
  }
}

.commandBarButtons {
  display: flex;
  flex-shrink: 0;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;
  @media (min-width: 640px) {
    width: auto;
  }
}

.errorAlert {
  margin-bottom: 20px;
}

.errorAlertHint {
  font-size: 11px;
  line-height: 16px;
  margin-top: 5px;
}

.infoIcon {
  height: 12px;
  width: 12px;
}

.infoIconContainer {
  cursor: pointer;
  padding: 0 3px;
}

.form {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 20px;
  color: $white;
  margin: 20px auto;
  max-width: calc(100% - 40px);
  padding: 40px 20px;
  @media (min-width: 768px) {
    max-width: 730px;
    padding: 100px 50px;
  }
  @media (min-width: 1024px) {
    margin: 0 auto 64px auto;
    max-width: 790px;
    padding: 100px;
  }
}

.formBody {
  display: grid;
  gap: 5px 20px;
  grid-template-columns: 1fr;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.h1 {
  margin-top: 0;
  text-align: center;
}

.h1Result {
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
}

.info {
  margin-top: 30px;
  text-align: center;
}

.link {
  border-bottom: 1px solid $wcm_yellow;
  color: $wcm_yellow;
  outline: none;
  &:focus,
  &:hover {
    border-color: $wcm_yellow_hover;
    color: $wcm_yellow_hover;
  }
}

.result {
  background-color: $black;
  border: 1px solid $orange;
  border-radius: 20px;
  color: $white;
  font-size: 16px;
  line-height: 21px;
  margin: 64px auto;
  max-width: 630px;
  padding: 50px 47px;
  text-align: center;
  width: calc(100% - 40px);
}
