@import "assets/scss/variables";

.analyticsNavLink {
  display: flex;
  margin-bottom: 50px;
  margin-top: toRem(40);
  max-width: 100%;
}

.navLink {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $gray-146;
  margin-inline-end: 60px;
  padding-bottom: 10px;

  &:last-child {
    margin-inline-end: 0;
  }

  &:hover {
    border-bottom: 1px solid $orange;
  }
}

.active {
  color: $orange;
  border-bottom: 1px solid $orange;
}

@media screen and (max-width: 1270px) {
  .analyticsNavLink {
    width: 100%;
    margin-bottom: 40px;
    overflow-x: auto;
    white-space: nowrap;

    .navLink {
      display: inline-block;
      margin-inline-end: 40px;
    }
  }
  .analyticsNavLink::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}