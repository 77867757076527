@import "assets/scss/variables";

.flex {
  display: flex;
  height: 50px;
  min-height: 50px;
  align-items: center;
  @media (max-width: 750px) {
    display: block;
    position: sticky;
    position: -webkit-sticky;
    top: 105px;
    z-index: 2;
    align-self: flex-start;
    height: auto;
    min-height: auto;
    &_noSticky {
      top: 55px;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  align-items: center;

  &_wrap {
    @media (max-width: 1120px) {
      display: block;
    }
  }
}

.wrap {
  display: flex;
  @media (max-width: 990px) {
    //align-items: center;
  }
  @media (max-width: 750px) {
    display: block;
    :global(.payeeCodeElement) {
      margin-bottom: 0 !important;
    }
  }

  &_ledger {
    :global(.payeeContent) {
      display: flex;
      flex-direction: row;
      align-items: center;

      div {
        margin-bottom: 0 !important;
        margin-left: 2px !important;
        margin-right: 2px !important;
      }
    }
  }
}

.ledger_block {
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  @media (max-width: 750px) {
    margin-top: 20px;
  }
}

.button {
  display: none;
  @media (max-width: 1024px) {
    //border-radius: 6px;
    //width: rem(60);
    //height: rem(50);
    //padding: 0;
    svg {
      margin-left: 0;
      width: toRem(10);
      border-width: toRem(2);

      path {
        fill: white;
      }
    }
  }
  @media (max-width: 990px) {
    width: toRem(40);
    height: toRem(35);
  }
  @media (max-width: 750px) {
    //transform: translate(100%, -160%);
    right: 0;
    bottom: 0;
    width: auto;
    height: toRem(35);
    padding: 0 toRem(15);
    z-index: 1;
    //position: relative;
    svg {
      //display: none;
    }
    //span {
    //  display: inline-block;
    //}
  }

  &:hover {
    background-color: $wcm_yellow;

    svg path {
      fill: white
    }
  }
}

.payeeCodeBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: toRem(100);
  color: white;

  &_nodata {
    text-align: center;
    width: 100%;

    h1 {
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: 600;
      margin-top: 0;
    }

    p {
      font-size: 16px;
      font-weight: 400;

      a {
        color: white;
        text-decoration: underline;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  &_big {
    padding: toRem(27) toRem(40);
    margin-top: toRem(27);
    border: 1px solid #929292;
    border-radius: 6px;
    background-color: #000000;
    align-items: center;
    @media (max-width: 1024px) {
      margin-top: toRem(20);
      padding: toRem(20);
      align-items: center;
    }
    @media (max-width: 990px) {
      //padding-bottom: 0;
    }
    @media (max-width: 750px) {
      padding: toRem(20);
      display: block;
      //margin-top: -30px;
    }
  }
}

.col_2 {
  //max-width: 35%;
  //flex: 0 0 35%;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    //max-width: 44%;
    //flex: 0 0 44%;
  }
  @media (max-width: 990px) {
    //max-width: 60%;
    //flex: 0 0 60%;
  }
  @media (max-width: 750px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.col_3 {
  @media (max-width: 1024px) {
    //max-width: 50%;
    //flex: 0 0 50%;
    //padding-left: 30px;
  }
  @media (max-width: 990px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
  }
}

.col_5 {
  &_hideOnDesktop {
    display: none;
    @media (max-width: 990px) {
      margin-top: 15px;
      display: block;
    }
  }

  &_hideOnMobile {
    display: block;
    @media (max-width: 990px) {
      display: none;
    }
  }

  @media (max-width: 1280px) {
    padding-right: 20px;
  }
  padding-right: 40px;

  :global(.info-component-head) {
    max-width: 200px;
  }

  @media (max-width: 1120px) {
    //max-width: 100%;
    //flex: 0 0 100%;
    &:nth-of-type(1) {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 990px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 750px) {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 20px;
  }
}


.col_3 {
  padding-left: 40px;

  :global(.info-component-head) {
    max-width: 190px;
  }

  @media (max-width: 1280px) {
    padding-left: 20px;
  }
  @media (max-width: 990px) {
    padding-left: 0;
  }

  &:nth-of-type(1) {
    padding-left: 0;
  }
}

.currentPeriod {
  //max-width: calc(45% + 65px);
  //flex: 0 0 calc(45% + 65px);
  position: relative;

  :global(.info-component-head) {
    max-width: 200px;

  }

  &_col_1 {
    padding-right: toRem(65);
    padding-left: toRem(65);
    //padding-right: rem(65);
    position: relative;

    :global(.info-component-head) {
      //max-width: 165px;
    }

    @media (max-width: 1024px) {
      padding-left: 0;
      padding-right: toRem(30);
      width: 50%;
    }
    @media (max-width: 990px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      margin-bottom: 15px;
    }
    @media (max-width: 790px) {
      margin-bottom: 0;
      padding-bottom: 44px;
      position: relative;
    }

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 80px;
      background-color: #929292;
      right: 0;
      top: -15px;
      @media (max-width: 990px) {
        display: none;
      }
      @media (max-width: 750px) {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #929292;
        left: 0;
        top: auto;
        bottom: 22px;
        display: block;
      }
    }

    //max-width: 50%;
    //flex: 0 0 50%;
  }

  &_col_2 {
    padding-left: toRem(65);
    :global(.payeeCodeName) {
      max-width: 180px;
    }

    @media (max-width: 1024px) {
      width: 50%;
      padding-left: toRem(30);
    }
    @media (max-width: 990px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    //padding-right: rem(65);
    //max-width: 50%;
    //flex: 0 0 50%;
  }

  @media (max-width: 1024px) {
    max-width: 56%;
    flex: 0 0 56%;
  }
  @media (max-width: 750px) {
    margin-top: 30px;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.noCurrentPeriod {
  &_col {
    &_6 {
      position: relative;
      //width: 66%;
      @media (max-width: 1024px) {
        //max-width: 50%;
        //flex: 0 0 50%;
      }
      @media (max-width: 990px) {
        //max-width: 100%;
        //flex: 0 0 100%;
        margin-bottom: 15px;
      }
    }

    &_1 {
      //padding-right: 10%;
      position: relative;
      @media (max-width: 790px) {
        padding-bottom: 20px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 80px;
        background-color: #929292;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 1024px) {
          //top:15px;
        }
        @media (max-width: 990px) {
          display: none;
        }
        @media (max-width: 750px) {
          display: block;
          top: auto;
          bottom: 0;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
}

.col_8 {
  //max-width: 65%;
  //flex: 0 0 65%;
  position: relative;
  @media (max-width: 1024px) {
    padding-right: 0;
    padding-left: 20px;
    //max-width: 55%;
    //flex: 0 0 55%;
  }
  @media (max-width: 990px) {
    //max-width: 40%;
    //flex: 0 0 40%;
  }
  @media (max-width: 750px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
  }
}

.fz38 {
  :global(.info-component-value) {
    font-size: 38px;
  }
}

.fz36 {
  :global(.info-component-value) {
    font-size: 36px;
  }
}

.fz32 {
  :global(.info-component-value) {
    font-size: 32px;
  }
}

.fz30 {
  :global(.info-component-value) {
    font-size: 30px;
  }
}

.fz28 {
  :global(.info-component-value) {
    font-size: 28px;
    @media (max-width: 1024px) {
      font-size: 22px !important;
    }
  }
}

.fz26 {
  :global(.info-component-value) {
    font-size: 26px;
  }
}

.fz22 {
  :global(.info-component-value) {
    font-size: 22px;
    @media (max-width: 1024px) {
      font-size: 16px !important;
    }
  }
}

.fz20 {
  :global(.info-component-value) {
    font-size: 20px;
  }
}

.fz18 {
  :global(.info-component-value) {
    font-size: 20px;
  }
}

.fz16 {
  :global(.info-component-value) {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .payeeCodeItem {
    &_first {
      :global(.info-component-value) {
        font-size: toRem(36);
      }

      :global(.info-component-value) {
        font-size: toRem(16);
      }

      padding-right: 0 !important;
      margin-bottom: 0 !important;

      :global(.payeeContent) {
        width: 100%;

        &:after {
          right: toRem(-90) !important;
          display: block !important;
          height: 114px;
          top: toRem(-20);
        }
      }
    }
  }
  .fz25 {
    :global(.info-component-value) {
      font-size: 17px !important;
    }
  }
  .fz22 {
    :global(.info-component-value) {
      font-size: 16px !important;
    }
  }

  .fz16 {
    :global(.payeeCodeLabel) {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 990px) {
  .payeeCodeItem {
    &_first {
      :global(.payeeContent) {
        &:after {
          right: toRem(-40) !important;
          height: 114px;
          top: toRem(-20);
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .container {
    margin: 0 auto;
  }
  .payeeCodeItem {
    &_first {
      :global(.payeeContent) {
        &:after {
          display: none !important;
        }
      }
    }
  }
}
