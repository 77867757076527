@import "assets/scss/variables";

.arrow {
  width: 90%;
  height: 90%;

  &_rotate {
    transform: rotate(180deg);
  }
}

.impersonate {
  display: flex;
  align-items: center;

  .circle {
    position: absolute;
    right: 2px;
    width: 23px;
    height: 23px;
  }
}

.filter {
  display: flex;
  gap: 25px;
  align-items: center;
  width: 85%;
  position: relative;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
}

.chips {
  display: flex;
  gap: 5px;
  max-width: 90%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 100%;
    z-index: 1;
    right: 114px;
    top: 0;
    pointer-events: none;
    right: 0;
    /* Created with https://www.css-gradient.com */
    background: #FFFFFF;
    background: -webkit-linear-gradient(to right, transparent, #FFFFFF 100%);
    background: -moz-linear-gradient(to right, transparent, #FFFFFF 100%);
    background: linear-gradient(to right, transparent, #FFFFFF 100%);
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &_clear {
    font-style: italic;
    color: $gray;
    cursor: pointer;
  }
}

.grid {
  //height: calc(100vh - 200px) !important;
  //background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  :global(.ag-row) {
    &:hover {
      :global(.actions-layer) {
        display: block !important;
      }
    }
  }

  &_checkbox {
    :global(.checkbox_marker) {
      background-color: #fff;
    }

    :global(svg path) {
      fill: black !important;
    }
  }

  :global(.__react_component_tooltip.show) {
    & + :global(.cell-arrow) {
      transform: rotate(180deg);
    }
  }
}

.container {
  //padding-top: rem(50);
}

.content {
  //max-width: calc(100% - 200px);
  margin: 0 auto;
  padding: 0;
}

.tag {
  background-color: $gray-229;
  padding: toRem(2) toRem(10);
  border-radius: toRem(12);
  font-size: toRem(11);

  &_inactive {
    background-color: $gray-229;
    color: $black_56;
  }

  &_active {
    background-color: $wcm_green;
    color: $white;
  }

  &_registered {
    background-color: $wcm_green;
    color: $white;
  }

  &_suspended {
    background-color: rgba(180, 84, 0, 1);
    color: white;
  }

  &_invited {
    background-color: $black_56;
    color: $white;
  }
}

.capitalize:first-letter {
  text-transform: capitalize;
}

.accent {
  text-align: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    path {
      fill: $orange
    }
  }

  //&_ToolTip {
  //  transform: translate(15px, 30px);
  //}
}

.accent_ToolTip, .checkbox_toolTip {
  width: 160px;
  padding: toRem(10) 0 !important;
}

.checkbox_toolTip {
  top: 32px !important;
}

.accent_ToolTip {
  top: 16px !important;
}

.searchTotal {
  font-family: $font-family-plaak;
  color: black;
  font-size: toRem(13);
  line-height: toRem(16);
  padding: 5px 0;
}