@import "assets/scss/variables";

.child{
  display: flex;
  justify-content: space-between;
  .linkIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: -10px;
    width: 15px;
    .rotate{
      transform: rotate(-90deg);
    }
  }
  .el{
    width: 100%;
  }
}
.withLink{
  cursor: pointer;
}
.card{
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-249;
  background-color: $white;
  border-radius: 12px;
  padding: 15px 15px 0 15px;
  margin-bottom: 16px;
  position: relative;
  .arrow{
    position: absolute;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    &:before {
      content: "";
      width: 94%;
      height: 1px;
      background-color: rgb(146, 146, 146);
      top: 0;
      left: 0;
      position: absolute;
      right: 0;
      margin: auto;
    }
  }
}

.extendableContent{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  margin-bottom: 30px
}

.isOpen{
  max-height: 500px;
  transition: max-height 1s ease-out;
}