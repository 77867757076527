@import "../../../../assets/scss/variables";

.privacy {
  .underline {
    text-decoration: underline;
  }

  z-index: 100000000000000 !important;
  margin: toRem(44) toRem(19) toRem(120) toRem(19);
  box-sizing: border-box;

  .updateTxt {
    text-align: end;
    font-weight: 600;
    font-size: toRem(13);
    line-height: toRem(16);
    font-family: $font-family-suisse;
    color: $white;
  }

  .headTxt {
    margin-top: toRem(14);
    font-weight: 600;
    font-size: toRem(13);
    line-height: toRem(16);
    font-family: $font-family-suisse;
    color: $white;
  }

  .headTxt2 {
    font-weight: 600;
    font-size: toRem(20);
    line-height: toRem(24);
    font-family: $font-family-suisse;
    color: $white;
  }

  .orangeTxt {
    color: $wcm_warning;
  }

  .txt {
    margin-top: toRem(10);
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(20);
    font-family: $font-family-suisse;
    color: $white;
  }

  .txtUnderline {
    text-decoration: underline;
  }

  .toggleRows {
    margin-top: toRem(60);

    .rowWrapper {
      border-bottom: 1px solid $gray-146;

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: toRem(21) toRem(15);
        cursor: pointer;
        color: $white;

        .firstPart {
          display: flex;
          flex-direction: row;
          align-items: center;

          .numTxt {
            width: toRem(5);
            font-weight: 400;
            font-size: toRem(13);
            line-height: toRem(16);
            font-family: $font-family-suisse;
            color: $white;
          }

          .nameTxt {
            margin-left: toRem(30);
            font-weight: 600;
            font-size: toRem(13);
            line-height: toRem(16);
            font-family: $font-family-suisse;
            color: $white;

            &.opened {
              color: $orange;
            }
          }
        }

        &:hover {
          background-color: $wcm_dark;

          .firstPart {
            .nameTxt {
              color: $orange !important;
            }
          }
        }
      }

      .rowContent {
        padding-bottom: toRem(20);
        padding-left: toRem(26);
        font-size: toRem(13);
        font-weight: 400;
        line-height: toRem(16);

        .marginTop {
          margin-top: toRem(40);
        }

        .subContent {
          margin-left: toRem(10);
          width: 100%;

          .subsSubContentWrapper {
            display: flex;
            flex-direction: row;
            padding: toRem(15);
            border-bottom: 1px solid $gray-146;

            .subSubContent {
              width: 50%;
              padding-right: toRem(15);
              font-size: toRem(11);
              line-height: toRem(16);
              font-weight: 400;

              .smallBoldTxt {
                font-weight: 600;
                font-size: toRem(11);
                line-height: toRem(13);
                font-family: $font-family-suisse;
                color: $white;
              }

              .italicTxt {
                font-style: italic;
                font-weight: 600;
                font-size: toRem(11);
                line-height: toRem(16);
                font-family: $font-family-suisse;
              }
            }
          }
        }
      }
    }
  }
}
