@import "../../../../assets/scss/variables";

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgb(0 0 0 / 50%);

  &_inner {
    position: relative;
    padding: 40px 20px;

    .icons_parent,
    .back_icon_wrapper,
    .front_icon_wrapper,
    .loader_icon_back,
    .loader_icon_front {
      width: 30px;
      height: 42px;
    }
  }
}

.wrapper {
  text-align: center;
}

.icons_parent {
  width: toRem(106);
  height: toRem(157);
  position: relative;
  margin: 0 auto;
}

.text {
  z-index: 1;
  position: relative;
  color: $white;
  margin-top: toRem(20);
}

.back_icon_wrapper,
.front_icon_wrapper {
  width: toRem(106);
  height: toRem(157);
}

.front_icon_wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  animation: fillAnimation 2s ease-in-out infinite;
}

.loader_icon {
  &_back,
  &_front {
    position: absolute;
    bottom: 0;
    left: 0;
    width: toRem(106);
    height: toRem(157);
  }
}

@keyframes fillAnimation {
  0% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}