.headerComponent {
  display: flex;
  align-items: center;
  cursor: pointer;

  .title {
    margin-right: 8px;
    @media (max-width: 1024px) {
      white-space: break-spaces;
      text-overflow: inherit;
      overflow: visible;
    }
  }
}