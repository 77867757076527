@import "assets/scss/variables";

.row {
  border-bottom: 1px solid $gray-146;

  &:hover {
    background-color: $gray-249;
  }

  :global(.ag-cell) {
    display: flex;
    align-items: center;
    white-space: break-spaces;
    padding-left: toRem(15);
  }
}

.grid {
  position: relative;
  top: toRem(15);

  &_theme {
    background-color: #fff;
    border-radius: toRem(10);
    position: relative;
    height: 100%;

    :global(.ag-header) {
      border-bottom: 2px solid $gray-146;
      background-color: $gray-244;
      overflow: visible;
      z-index: 1;

      :global(.ag-header-viewport) {
        overflow: visible;
      }
    }

    :global(.ag-header-row) {
      overflow: visible;
    }

    :global(.ag-checkbox-input-wrapper) {
      width: toRem(15);
      height: toRem(15);
      display: inline-block;
      border: toRem(1.2) solid black;
      border-radius: toRem(2);

      input {
        -webkit-appearance: none;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: toRem(-2);
        display: inline-block;
        transform: rotate(45deg);
        height: toRem(6);
        width: toRem(3);
        border-radius: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        z-index: -1;
        border-color: black;
      }
    }

    :global(.ag-checkbox-input-wrapper.ag-indeterminate) {
      &:after {
        border: 0;
        width: toRem(6);
        height: toRem(1.2);
        background-color: black;
        transform: rotate(0);
        top: 0;
      }
    }

    :global(.ag-checkbox-input-wrapper.ag-checked) {
      &:after {
        border-bottom: toRem(1.2) solid;
        border-right: toRem(1.2) solid;
        transform: rotate(45deg);
      }
    }

    :global(.ag-header-cell-text) {
      font-weight: 600;
      font-size: 1rem;
      color: $black;
      padding-left: toRem(15);
      margin-right: toRem(10);
    }

    //turn off sort functionality , need to remove
    :global(.ag-sort-order) {
      display: none;
    }

    :global(.ag-checkbox-input-wrapper) {
      flex: auto;
    }

    :global(.ag-input-field-input) {
      width: auto;
      flex: none;
    }

    :global(.ag-selection-checkbox) {
      display: flex;
    }

    @media (max-width: 1024px) {
      min-width: 1000px;
    }
  }

  &_checkbox {
    width: 50px;
    height: 47px;
    border-bottom: 2px solid #929292;
    background-color: #f4f4f4;
  }

  @media (max-width: 1024px) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.checkbox {
  :global(.checkbox_marker) {
    background-color: #fff;
  }

  :global(svg path) {
    fill: black !important;
  }
}

.chart {
  width: toRem(46);
  height: toRem(14.5);
}


.initialLoading {
  :global {
    .ag-cell {
      div {
        opacity: 0;
      }

      &::after {
        content: "";
        animation-duration: 1.25s;
        -webkit-animation-duration: 1.25s;
        -moz-animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        -webkit-animation-name: placeHolderShimmer;
        -moz-animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        -moz-animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
        background: darkgray;
        background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
        background-size: 800px 104px;
        height: 40%;
        width: calc(100% - 4rem);
        border-radius: 10px;
        position: absolute;
        display: block;
        background-position: -468px 0
      }
    }
  }
}

.shadow {
  height: toRem(110);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, .8) 105%);
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
}

.tooltip {
  position: absolute;
  left: 0;
  right: 0;
  width: 100px;
  height: 50px;
  background: red;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}