@import "../../../../../assets/scss/variables";

.docList {
  margin-top: 20px;
  .card {
    display: flex;
    align-items: center;
    column-gap: 22px;
    padding: 20px 24px;
    background-color: $white;
    border-radius: 6px;
    height: 74px;
    &:not(:first-child) {
      margin-top: 8px;
    }
    .cardInfoWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      flex: 1;
      .yearText {
        color: #929292;
        margin-right: 4px;
      }
      .monthsText {
        color: $black;
      }
      .typeText {
        color: #646464;
      }
      .mark {
        height: 12px;
        width: 12px;
        background-color: #D9A81B;
        border-radius: 50%;
      }
    }
  }
}

.downloadBtnContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  padding: 12px;
  z-index: 100000;
  background-color: rgba(0,0,0,0.5);
  transition: transform 0.1s ease;
  transform: translateY(100%);
  &.btnVisibleMobile {
    transition: transform .3s ease;
    transform: translateY(0);
  }
  .downloadBtnMobile {
    width: 100%;
    border-radius: 6px;
  }
}
