@import "../../../assets/scss/variables";

.cookies {
  width: 100%;
  z-index: 100000000000000 !important;

  .header {
    text-align: center;
    margin-top: toRem(30);
    font-weight: 800;
    font-size: toRem(28);
    line-height: toRem(34);
    font-family: $font-family-plaak;
    color: $white;
  }

  .content {
    z-index: 100000000000000 !important;
    max-width: toRem(979);
    width: 100%;
    margin: toRem(20) auto toRem(150) auto;
    box-sizing: border-box;

    .subHeader {
      display: flex;
      justify-content: space-between;
      text-align: end;
      font-weight: 600;
      font-size: 16px;
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .headTxt {
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(24);
      font-family: $font-family-suisse;
      color: $white;
    }

    .headTxt2 {
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(24);
      font-family: $font-family-suisse;
      color: $white;
    }

    .orangeTxt {
      color: $wcm_warning;
    }

    .txt {
      margin-top: toRem(10);
      font-weight: 400;
      font-size: 16px;
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .txt2 {
      font-weight: 400;
      font-size: toRem(13);
      line-height: 16px;
      font-family: $font-family-suisse;
      color: $white;
    }

    .txt3 {
      font-weight: 600;
      font-size: toRem(13);
      line-height: 16px;
      font-family: $font-family-suisse;
      color: $white;
    }

    .smallBoldTxt {
      font-weight: 600;
      font-size: 16px;
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;
    }

    .txtUnderline {
      text-decoration: underline;
    }

    .subsSubContentWrapper {
      display: flex;
      flex-direction: row;
      padding: toRem(15);
      border-bottom: 1px solid $gray-146;

      .subSubContent1 {
        width: 33%;
        padding-right: toRem(30);
      }

      .subSubContent2 {
        width: 30%;
        padding-right: toRem(30);
      }

      .subSubContent3 {
        width: 20%;
        padding-right: toRem(30);
      }

      .subSubContent4 {
        width: 17%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .cookies {
    .content {
      max-width: toRem(800);
    }
  }
}

@media screen and (max-width: 768px) {
  .cookies {
    .content {
      max-width: toRem(620);
    }
  }
}

@media screen and (max-width: 660px) {
  .cookies {
    .header {
      margin-top: toRem(50);
    }

    .content {
      max-width: toRem(340);

      .subHeader {
        font-size: toRem(13);
        line-height: 16px;
      }

      .txt {
        margin-top: toRem(14);
      }
    }
  }
}
