@import "../../../../assets/scss/variables";

.menu {
  background: $wcm_dark;
  position: absolute;
  bottom: toRem(30);
  color: $white;
  max-width: toRem(500);
  z-index: 10000;
  overflow:auto;
  padding: toRem(4) 0;
  div {
    padding: toRem(6) toRem(20);
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: $gray_lighter;
      color: $black;
    }
  }
}
.menu::-webkit-scrollbar {
  width: 8px;
}
.menu::-webkit-scrollbar-track {
  background-color: transparent;
}
.menu::-webkit-scrollbar-thumb {
  background-color: $gray;
  border-radius:8px;
}
.dropInDown {
  top: toRem(30);
  bottom: auto;
}

.dropdown {
  position: relative;
}

.controlClassName {
  display: flex;
  cursor: pointer;
}

.arrowClassName {
  margin-left: toRem(8);
}

.closedArrowClassName {
  margin-left: toRem(8);
  transform: rotate(180deg);
}