@import "assets/scss/variables";

.btn {
  padding: 0 toRem(12);
  height: toRem(35);
  font-size: 1rem;
  font-weight: 600;
  line-height: toRem(16);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: toRem(3) solid;

  svg {
    order: 2;
    margin-left: .6rem;
    width: toRem(13);
    height: toRem(13);

  }

  &_icon {
    &_right {
      svg {
        margin-left: toRem(10);
        margin-right: 0;
      }
    }

    &_left {
      svg {
        order: 1;
        margin-right: toRem(10);
        margin-left: 0;
      }

      .btn {
        &_title {
          order: 2
        }
      }
    }

    &_loader {
      animation: loopRotate 1s infinite linear;
    }
  }

  &_title {
    order: 1;
  }

  &_default {
    color: white;
    background-color: $wcm_yellow;
    border: toRem(3) solid $wcm_yellow;

    &:hover {
      background-color: $wcm_yellow_hover;
      border: toRem(3) solid $wcm_yellow_hover;
    }

    &:disabled {
      background-color: $gray-229;
      border-color: $gray-229;
      cursor: no-drop;
      color: $gray-146
    }

    .btn_icon {
      path {
        fill: $black;
      }
    }
  }

  &_primary {
    color: $white;
    background-color: $black;
    border-color: $wcm_yellow;

    &:hover {
      background-color: $primary_btn_hovered;
      //color: $wcm_yellow_hover;
    }

    &:disabled {
      background-color: $gray_hover;
      cursor: no-drop;
      border-color: rgba(229, 229, 229, 1);
      color: $wcm_gray;

      svg {
        path {
          fill: $wcm_gray;
        }
      }
    }

    svg {
      path {
        fill: $wcm_yellow;
      }
    }
  }

  &_text {
    color: white;
    border: none;
    padding: 0 toRem(10);

    svg {
      path {
        fill: $white;
      }
    }

    &:hover {
      .btn_title {
        text-decoration: underline;
      }
    }

    &:disabled {
      .btn_title {
        color: $wcm_gray;
        cursor: no-drop;
      }

      svg {
        path {
          fill: $wcm_gray;
        }
      }
    }
  }

  .dropdownIcon {
    path {
      fill: currentColor;
    }
  }
}

.menuItem {
  align-items: center;
  color: inherit;
  display: flex;
  height: 35px;
  padding: 0 20px;
  width: 100%;
  &:hover {
    background-color: $wcm_yellow;
    border-left: 2px solid #cbcbcb;
    color: #383838;
    font-weight: 600;
  }
}

@keyframes loopRotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}