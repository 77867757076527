@import "assets/scss/variables";

.topSources{
  display: grid;
  grid-template-columns: 1.18fr 2fr;
  grid-gap: 20px;
}

.topSongsChartWrap{
  background: $black;
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 30px 30px 10px 30px;
  margin-top: 20px;
}

.earnings{
  max-width: 430px;
}

.workTitle{
  color: $black;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
}

.creators{
  color: $black;
  font-size: 11px;
}

.previousAmount{
  color: $black;
  margin-bottom: 23px;
  margin-right: 15px;
}

.table{
  min-width: 515px;
  tbody{
    tr{
      min-height: 69px;
    }
    td{
      height: 69px;
    }
  }
  :global(.customHeader) {
    justify-content: flex-end;
    @media (max-width: 1023px) {
      justify-content: flex-start;
    }
  }
}

.chevron{
  margin-bottom: 25px;
}

.end{
  justify-content: flex-end;
  padding-right: 12px !important;
}

.tableValue{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 4px;
  margin-right: 15px;
  .amount{
    margin-bottom: 4px;
    color: $black;
  }
  .noPercentage{
    margin-bottom: 18px;
  }

  p{
    margin: 0;
  }
}

.increment{
  color: $wcm_green174;
}

.decrement{
  color: $wcm_danger;
}

.table_detail{
  width: 70%;
  justify-content: space-between;
  display: flex;
  margin-left: 70px;
  padding: 10px 0;
  //height: 78px;
}

.callapsClassName{
  //height: 78px;
}

.wrap{
  font-size: 11px;
  color: $black;
  &_big {
    max-width: 60%;
  }
  .name{
    font-weight: 600;
    margin: 8px 0;
    text-transform: uppercase;
  }
}

.empty{
  border: 1px solid $gray-146;
  border-radius: 6px;
  padding: 40px;
}

@media screen and (max-width: 1270px) {
  .topSongsChartWrap{
    border: none;
    padding: 0;
    background-color: inherit;
  }
  .earningsWrap{
    border: none;
    background-color: inherit;
    padding: 0;
    min-height: fit-content;
  }
  .table{
    margin-top: 45px;
  }
}

@media screen and (max-width: 1023px) {
  .topSources{
    grid-template-columns: 1fr;
  }
  .earningsWrap{
    border: none;
    background-color: inherit;
    padding: 0;
    min-height: fit-content;
  }
  .table{
    margin-top: 0;
    th{
      padding: 0 5px;
    }
    td{
      padding: 0 5px;
    }
  }
  .topSongsChartWrap{
    background: $black;
    border: 1px solid $gray-146;
    padding: 30px;
    max-width:100%
  }
  .earnings{
    max-width:100%
  }
  .table{
    min-width: 100%;
  }
  .tableValue{
    align-items: flex-start;
  }
  .table_detail{
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    padding: 7px 0;
    height: 78px;
  }
  .end{
    justify-content: flex-start;
  }
}


@media screen and (max-width: 750px) {
  .topSongsChartWrap{
    padding: 0;
    border: none;
  }
}



