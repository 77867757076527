@import "assets/scss/variables";

.quickFilterButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: toRem(20) toRem(40);
  background-color: $gray-244;
  border-radius: toRem(6);
  cursor: pointer;
  width: fit-content;

  .title {
    font-size: toRem(13);
    color: $gray-146;
    margin-bottom: toRem(10);
    font-weight: 400;
  }

  .count {
    color: $black_56;
    font-size: toRem(26);
    line-height: toRem(32);
    font-weight: 600;
  }
  &.disabled {
    cursor: default;
  }

  &:not(.disabled):not(.active):hover {
    background-color: $gray-229;
  }
}

.active {
  background-color: $orange;

  .title {
    color: $white;
  }

  .count {
    color: $white;
  }

  &:hover {
    background-color: $orange;
  }
}