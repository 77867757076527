@import "assets/scss/variables";

.container {
  .downloadIcon {
    color: $wcm_yellow;
    height: 16px;
    margin: 0;
    width: 16px;
    path {
      fill: currentColor;
    }
  }

  .h2 {
    margin: 0;
  }
}

.docIcon {
  align-items: center;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
}

.docTitle {
  color: $white;
  flex-grow: 1;
  margin: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document {
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
}

.documents {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.header {
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.hr {
  border: none;
  border-top: 1px solid #ffffff1f;
  margin: 24px 0;
}

.link {
  outline: none;
  &:focus-visible,
  &:hover {
    color: $wcm_yellow_hover;
  }
}

.noData {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-style: italic;
  gap: 10px;
  justify-content: center;
  line-height: 20px;
  width: 100%;
}
