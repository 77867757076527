@import "assets/scss/variables";

.buttons {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  width: 100%;
  @media (min-width: 640px) {
    width: auto;
  }
}

.checkbox {
  align-items: center;
  display: flex;
}

.disabledCheckbox {
  opacity: 0.5;
}

.form {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  overflow-x: hidden;
  padding: 25px;
  @media (max-width: 750px) {
    margin-top: 20px;
  }
  @media (min-width: 640px) {
    padding: 50px;
  }
}

.toolbar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  @media (min-width: 640px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.terms {
  :global(.container) {
    padding: 0;
  }

  :global(.terms-container) {
    overflow: hidden;
  }

  :global(.terms-header) {
    font-size: 22px;
    margin-top: 0 !important;
  }

  :global(.terms-content) {
    position: relative;
    z-index: 0 !important;
    margin: 0 !important;

    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 95%;
      left: 0;
      height: auto;
      opacity: 0;
      transition: opacity .15s ease-in-out;
      z-index: 2;
    }

    &:before {
      box-shadow: 0 15px 70px 65px #000;
      top: 0;
    }

    &:after {
      box-shadow: 0 0 70px 60px #000;
      top: 360px;
    }

    @media (max-width: 1023px) {
      max-width: 100% !important;
    }
  }

  :global(.terms-content_shadowUp) {
    &:before {
      opacity: 1;
    }
  }

  :global(.terms-content_shadowDown) {
    &:after {
      opacity: 1;
    }
  }

  :global(.terms-wrapper) {
    margin-top: 35px !important;
    height: 360px;
    overflow-y: auto;
    padding-right: 18px;
    position: relative;
    z-index: 0;

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #CBCBCB;
      border-radius: 8px;
      background-clip: padding-box;
      height: 56px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #CBCBCB
    }
  }
}
