//.datePicker {
//  &-modal {
//    &-container {
//      z-index: 100;
//      position: fixed;
//      left: 50%;
//      transform: translateX(-50%);
//    }
//  }
//}
.materialui-daterange-picker-MuiGrid-root-35.materialui-daterange-picker-MuiGrid-container-36.materialui-daterange-picker-MuiGrid-wrap-xs-nowrap-42.materialui-daterange-picker-MuiGrid-justify-content-xs-center-53{
  .materialui-daterange-picker-MuiPaper-root-7.materialui-daterange-picker-makeStyles-root-184.materialui-daterange-picker-MuiPaper-elevation0-10{
    //&:first-child{
    //  [class*="materialui-daterange-picker-makeStyles-filled"] {
    //    border-bottom-left-radius: 7px !important;
    //    border-top-left-radius: 7px !important;
    //  }
    //}
    //&:first-child{
    //  opacity: .3;
    //  [class*="materialui-daterange-picker-makeStyles-filled"] {
    //    border-bottom-right-radius: 7px !important;
    //    border-top-right-radius: 7px !important;
    //  }
    //}
  }
  padding-top: 10px;

}
.materialui-daterange-picker-MuiList-root-225.materialui-daterange-picker-MuiList-padding-226 {
  display: flex;
  align-items: center;
  justify-content: center;
  .materialui-daterange-picker-MuiListItem-root-229 {
    text-align: center !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $orange !important;
    &:hover {
      background-color: transparent
    }
  }
}
.materialui-daterange-picker-MuiPaper-elevation5-15 {
  //box-shadow: none !important;
}
button[class*=materialui-daterange-picker-MuiIconButton-root] {
  border-radius: 0 !important;
}
.datePicker-modal {
  position: absolute !important;
  z-index: 5 !important;
  left: 140px;
  top: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.materialui-daterange-picker-MuiPaper-elevation5-15
.materialui-daterange-picker-MuiPaper-root-7{
  border-radius: 6px;
}
[class*="materialui-daterange-picker-MuiTouchRipple-root"]{
  display: none;
}
[class*="materialui-daterange-picker-MuiSelect-root-201"]{
  padding: 0 !important;
  text-align: center;
  background-color: transparent !important;
  font-size: 13px !important;
  font-weight: bold!important;
  color: black !important;
}
[class*="materialui-daterange-picker-MuiGrid-root"]{
  .MuiInputBase-root.MuiInput-root.MuiInput-underline{&:after,&:before {
    display: none;
  }}
}
[class*=".materialui-daterange-picker-makeStyles-highlighted"]{
  background-color: $gray-229;
}
[class*="materialui-daterange-picker-makeStyles-filled"],
[class*="materialui-daterange-picker-makeStyles-filled"]:hover {
  background-color: $orange !important;
}
.materialui-daterange-picker-MuiPaper-root-7{
  &.materialui-daterange-picker-MuiPaper-elevation5-15 >{
     .materialui-daterange-picker-MuiGrid-root-35 {
      &.materialui-daterange-picker-MuiGrid-container-36{
        &.materialui-daterange-picker-MuiGrid-wrap-xs-nowrap-42{
          flex-direction: column;
          border-radius: 6px;
          border: 1px solid #CBCBCB;
        }
      }
    }
  }
}
.materialui-daterange-picker-MuiDivider-root-177 {
  display: none !important;
}
[class*="materialui-daterange-picker-makeStyles-divider"]{
  display: none !important;
}
[class*="materialui-daterange-picker-makeStyles-header"]{
  display: none !important;
}
[class*="materialui-daterange-picker-makeStyles-outlined"] {
  border:1px solid $orange !important;
}
[class*="materialui-daterange-picker-MuiSelect-icon"] {
  display: none !important;
}
[class*="materialui-daterange-picker-MuiSelect-root"] {
  pointer-events: none !important;
}
