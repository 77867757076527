@import "assets/scss/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  line-height: 20px;
  justify-content: space-between;
  @media (min-width: 500px) {
    align-items: center;
    flex-direction: row;
  }
}

.icon {
  cursor: pointer;
  height: 16px;
  margin: 2px;
  vertical-align: middle;
  width: 16px;
}

.label {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}

.value {
  color: $white;
}