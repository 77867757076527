@import '../../../../../assets/scss/functions';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  justify-content: flex-start;
  height: 100%;
  font-size: toRem(13px);
  line-height: toRem(16px);
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px
}

.cancelButton {
  border: none;
}

.payeeCode {
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}