@import "assets/scss/variables";


.link{
  border-bottom: 1px solid $white;
  &:hover{
    border-bottom: 1px solid $orange;
  }
}

.container {
  display: flex;
  justify-content: center;
}

.noDataAnalytics {
  margin-top: 50px;
  align-items: center;
  background-color: #101010;
  color: $white;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 180px;
  width: 637px;
  justify-content: center;
  line-height: 20px;
  position: relative;
  text-align: center;
}

.noDataAnalyticsText {
  background-color: #101010;
  color: #878787;
  max-width: 230px;
  padding: 12px;
  z-index: 1;
}

.noDataAnalyticsDescription {
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  margin-top: 4px;
}

.chartIcon {
  color: #383838;
  height: 90%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}