@import "../../../assets/scss/variables";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  background: url("../../img/bg-full.png") no-repeat center 9.230769rem / 1380px;
  background-size: contain;

  .clientContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1380px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .userManagementContainer {
    background-color: $white;

    .userManagementWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      padding: 0 50px;

      .userManagementContent {
        height: 100%;
        padding-bottom: 20px;
      }

      @media (max-width: 1024px) {
        padding: 0 20px;
      }
    }
  }

  .wrapper {
    padding-bottom: toRem(24);
  }

  @media (max-width: 750px) {
    height: auto;
    min-height: auto;

    .clientWrapper {
      margin-top: 0;

      .wrapper {
        height: auto;
        min-height: calc(100vh - 280px);
        background-color: black;
        background-image: none;
      }
    }
  }
}