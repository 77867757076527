@import "../../../../../assets/scss/variables";

.enterCodeButton {
  color: #d9a81b;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
}

.fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.form {
  text-align: left;
}

.input {
  background-color: transparent;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  color: $white;
  font-size: 18px;
  font-weight: 700;
  height: 48px;
  outline: none;
  text-align: center;
  width: 48px;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.legend {
  padding: 0;
}

.submitButton {
  margin-top: 16px;
  width: 100%;
}
