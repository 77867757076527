@import "assets/scss/variables";

.totalCount{
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: $white
}

.dealId{
  font-weight: 400;
}

.card{
  padding: 30px 15px;
}