@import "assets/scss/variables";


.modalContainer {
  min-width: 500px;
  min-height: 150px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.28);
  border-radius: 6px;
  border: 1px solid #CBCBCB;
  outline: none;
  &_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    &_loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      animation: rotetLoader infinite 1000ms linear;
      transform-origin: center;
    }
  }
  &_wrapperLoading{
    background-color: rgba(128, 128, 128, 0.39);
    pointer-events: none;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent !important
}

.closeIcon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  svg {
    width: 10px;
    height: 13px;
    color: $black;
    cursor: pointer;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  .description {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .item {
      color: $black;

      .span {
        font-weight: 600;
      }
    }
  }

  .buttonContainer {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    text-decoration: none;

    .downloadButton {
      background-color: #FFFFFF;
      padding: 0 8px;
      width: 126px;
      height: 21px;
      color: #383838;
      font-size: 11px;
      border: 1px solid #383838;
      border-radius: 6px;

      &:hover {
        background-color: black;
        color: #FFFFFF;

        .downloadIcon {
          svg {
            path {
              fill: #FFFFFF;
            }
          }
        }
      }
      .downloadIcon {
        width: 13px;

        svg {
          min-width: 100%;

          path {
            fill: #383838
          }
        }
      }
    }
  }
}
@keyframes rotetLoader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}