@import "assets/scss/variables";

.line {
  height: 10px;
  border-radius: 2px;
  max-width: 0;
  transition: all 0.9s ease;
}

.active{
  max-width: 500px;
}


.lineWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.wrapCount {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80px;
}

.count {
  color: $white;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.percent {
  color: $wcm_green;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  .upIcon {
    margin-left: 3px;
  }
}

.down{
  color: $wcm_danger
}

.info {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: $white;
  line-height: 16px;
  margin-bottom: 5px;
  .desc {
    color: $gray_light;
    margin-left: 5px;
  }
}

.item {
  width: 100%;
  margin-right: 25px;
  margin-bottom: 20px;
}

.total{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  font-size: 11px;
  border-top: 1px solid $gray-146;
  padding: 10px 0;
}

@media (max-width: 568px) {
  .info {
    .desc {
      max-width: 100%;
    }
  }
  .total{
    border-top:none;
    display: none;
  }
}