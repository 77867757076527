@import '../../../../assets/scss/variables';

.tabs {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}

.tabItem {
  color: $gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 36px;
  cursor: pointer;
  background: white;

  &:hover {
    color: $orange;
    border-bottom: 1px solid $orange;
  }

  &.active {
    color: $orange;
    border-bottom: 1px solid $orange;
  }

  &:last-child {
  }
}
