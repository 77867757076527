@import "assets/scss/variables";

.title {
  position: absolute;
  color: white;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 13px;
  line-height: 16px;
}

.wrap {
  max-width: 230px;
  position: relative;
  text-align: center;
}

.innerCircle {
  min-width: 200px;
}

.isInnerPageWrap {
  padding: 10px 46px;
  position: relative;
  text-align: center;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 14px 12px;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 15px;
  color: $white
}

.index {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  min-width: 180px;

  p {
    margin-right: 9px;
  }
}

.amount {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: $white
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.percent {
  width: fit-content;
  font-size: 16px;
  line-height: 20px;
  color: $wcm_green174
}

.isIncrement {
  color: $wcm_danger
}

.load {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10% auto;
}

.hovered {
  color: $white;

  svg {
    path {
      fill: $white !important
    }
  }
}


@media (max-width: 1270px) {
  .innerCircle {
    max-width: 215px;
    max-height: 215px;
  }
  .isInnerPageWrap{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top{
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .card {
    //display: flex;
    margin-bottom: 20px;
  }
  .info {
    height: 46px;
    align-items: center;
    display: flex;
    padding: 0 15px;
    margin-bottom: 10px;
    margin-top: 0;

    .percent {
      display: none;
    }

    .amount {
      font-size: 13px;
    }

    .index {
      font-size: 13px;
    }
  }
  .isInnerPageWrap{
    padding: 0 30px 0 30px;
    //margin-right: 30px;
    margin-bottom: 20px;
  }
  .top{
    //padding-right: 30px;
  }
}

@media (max-width: 568px) {
  .wrap {
    width: 150px;
  }
  .card{
    flex-direction: column;
  }
  .top{
    padding-right: 0;
    padding-top: 20px;
  }
  .isInnerPageWrap{
    padding: 0;
    margin-right: 0;
  }
  .title{
    font-size: 11px;
    //top:35%
  }
}