@font-face {
  font-family: 'Plaak 6 Ney';
  src: url('../fonts/Plaak6Ney-56-Heavy.eot');
  src: url('../fonts/Plaak6Ney-56-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Plaak6Ney-56-Heavy.woff2') format('woff2'),
  url('../fonts/Plaak6Ney-56-Heavy.woff') format('woff'),
  url('../fonts/Plaak6Ney-56-Heavy.ttf') format('truetype'),
  url('../fonts/Plaak6Ney-56-Heavy.svg#Plaak6Ney-56-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Int'l";
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SuisseIntlRegular.woff2') format('woff2'), url('../fonts/SuisseIntlRegular.woff') format('woff');
}

@font-face {
  font-family: "Suisse Int'l";
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/SuisseIntlSemiBold.woff2') format('woff2'), url('../fonts/SuisseIntlSemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Chappell Longhand';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Chappell.ttf') format('truetype');
}
