@import "assets/scss/variables";
@import "assets/scss/variables.scss";


.tag {
  padding: toRem(2) toRem(10);
  cursor: pointer;
  border: 1px solid #383838;
  border-radius: 10px;
  background: #FFF;
  z-index: 888;
  position: relative;

  &:hover {
    color: black;
    background-color: #E4C25F;
  }
}

.match {
  color: black;
  background-color: #E4C25F;
}

.assocuatedUsers {
  width: 100%;

  div {
    user-select: all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
  }

  .link {
    user-select: none;
    cursor: pointer;
    text-decoration: underline;
    font-size: .75rem;
    line-height: 1rem;

    &:hover {
      color: $wcm_yellow;
    }
  }

}