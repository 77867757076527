//.forMobile{
//  background-image: url("../../../img/bg-full.png");
//  background-repeat: no-repeat;
//  background-position: center;
//  position: relative;
//  height: 100%;
//  width: 100%;
//}
@import "assets/scss/variables";

.info {
  z-index: 10000;
}

.image {
  width: 100%;
  top: 100px;
  pointer-events: none;
  position: fixed;
  height: 250px;
  z-index: 0;
}

.backButton {
  padding-top: 20px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  z-index: 10000;
  padding-left: 0;

  p {
    font-size: toRem(13);
    font-weight: 600;
  }

  .backIcon {
    margin-right: 8px;
  }
}

.select {
  margin-top: 29px;

  &_searchable {
    :global(.select__control--menu-is-open) {
      background-image: url("../../../img/filter_search.svg");
      background-position: calc(100% - 37px) center;
      background-size: 13px 13px;
      background-repeat: no-repeat;
      transition: none;

      :global(.select__single-value) {
        opacity: 0;
        transition: none;
      }

      :global(.select__dropdown-indicator) {
        transform: rotate(180deg);
      }
    }
  }
}


.table_filter_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
}

.table_filter {
  width: 200px;
  padding: 9px 15px;
  color: white;
  border: none;
  border-bottom: 2px solid #646464;
  outline: none;
  background-color: transparent;
}

.header {
  div {
    //overflow: revert !important;
  }

  :global(.ag-center-cols-clipper) {
    .value {
      //overflow: revert !important;
    }

    min-height: 55px !important;
    //height: 55px !important;
  }
}

.value {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .accountTitle {
    font-weight: 600;
  }

  p {
    height: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 0;
  }
}
