.modal {
  display: inline-flex;
  padding: 25px 32px;
  flex-direction: column;
  align-items: flex-start;
  min-height: 187px;
  min-width: 620px;
  max-width: 620px;
  gap: 10px;
  position: fixed;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: black;

  .contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    //display: flex;
    //gap: 10px;
    font-weight: bold;

    svg {
      fill: #D42B3A;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .head {
    font-size: 22px;
    color: black;
    font-weight: 800;
    margin-bottom: 20px;

    &_info {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .list {
    width: 100%;

    .listItem {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;

      .name {
        color: #D42B3A;
      }

      .fileError {
        color: #D42B3A;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
  }
}

.instructionsTitle {
  font-size: 16px;
  margin-top: 10px;
}

.instructionsList {
  margin-top: 10px;

  span {
    display: inline-block;
    width: 14px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0) !important;
  z-index: 1;
}

.collapse {
  width: 100%;
  margin-bottom: 10px;
  border: none !important;
  border-radius: 0 !important;

  &_header {
    font-size: 13px;
    color: #D42B3A !important;
    padding: 3px 5px !important;
    padding-right: 10px !important;
    background-color: #F4F4F4;
    justify-content: space-between;

    &[aria-expanded=true] {
      font-weight: bold;

      i {
        transform: rotate(180deg);
      }
    }
  }
  &_item {
    word-break: break-all;
  }

  :global(.rc-collapse-content-box) {
    max-height: 135px;
    overflow-y: auto;
    margin-bottom: 0 !important;
    color: black;

    &::-webkit-scrollbar-thumb {
      background: #CBCBCB;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 0px;
      background: transparent
    }

    div {
      margin-bottom: 10px;
      font-weight: 400;
    }
  }

  :global(.rc-collapse-expand-icon) {
    order: 2;
  }

  :global(.rc-collapse-content) {
    background-color: #F9F9F9;
    padding-left: 10px;
  }

  i {
    color: initial;
    width: 12px;
    height: 13px;
  }
}