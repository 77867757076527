@import "assets/scss/variables";

.description {
  font-size: 16px;
  margin-top: 30px;
}

.closeButton {
  margin-top: 20px;
}

.h1 {
  margin: 0 !important;
}

.info {
  margin-top: 40px;
}

.link {
  color: $wcm_yellow;
  text-decoration: none;
  &:focus,
  &:hover {
    color: $wcm_yellow_hover;
  }
}

.notFound {
  background-color: $black;
  border: 1px solid $wcm_yellow;
  border-radius: 20px;
  color: $white;
  max-width: 580px;
  margin: 0 auto;
  padding: 60px;
  text-align: center;
  width: 100%;
}
