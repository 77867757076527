@import "../../../../assets/scss/variables";

.responseAlert {
  border: 1px solid;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  border-radius: .5rem;
  position: relative;
  font-size: toRem(13);
  width: 100%;
  @media (max-width: 1024px) {
    width: 512px;
  }
  @media (max-width: 660px) {
    width: 100%;
  }

  &_close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: .82rem;
    height: .82rem;
    cursor: pointer;
  }

  &_icon {
    width: toRem(16);
    height: toRem(16);
    position: absolute;
    left: 20px;
    top: 1.5rem;

    &_success {
      path {
        fill: $wcm_green;
      }
    }

    &_warning {
      path {
        fill: $wcm_yellow_hover;
      }
    }

    &_error {
      path {
        fill: $wcm_danger;
      }
    }
  }

  &_wrapper {
    padding: 0 26px;
  }

  &_error {
    border-color: $wcm_danger;
  }

  &_success {
    border-color: $wcm_green;
  }

  &_warning {
    border-color: $wcm_yellow_hover;
  }
}