@import "assets/scss/variables";
.container {
  background-color: $gray-229;
  padding: 3px 4px 3px 12px;
  border-radius: 10px;
  font-size: 11px;
  color: black;
  flex: 0 0 auto;
  &_delete {
    padding: 8px;
    cursor: pointer;
  }
  &_value {
    font-style: normal;
    //font-weight: bold;
  }
}