@import 'assets/scss/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  justify-content: flex-start;
  height: 100%;
  font-size: toRem(13px);
  line-height: toRem(16px);
}

.filter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_chips {
    display: flex;
    gap: 25px;
    align-items: center;
    width: 85%;
    position: relative;
    margin-top: 10px;
    &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 100%;
      z-index: 1;
      right: 114px;
      top: 0;
      pointer-events: none;
      /* Created with https://www.css-gradient.com */
      background: #FFFFFF;
      background: -webkit-linear-gradient(to right, transparent, #FFFFFF 100%);
      background: -moz-linear-gradient(to right, transparent, #FFFFFF 100%);
      background: linear-gradient(to right, transparent, #FFFFFF 100%);
    }

    .chips {
      display: flex;
      gap: 5px;
      max-width: 90%;
      overflow-x: auto;
      overflow-y: hidden;

      ::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &_clear {
        font-style: italic;
        color: $gray;
        cursor: pointer;
      }
    }
  }
}

.numberOfFiles_container {
  display: flex;
  gap: 10px;

  svg * {
    fill: rgba(56, 56, 56, 1)
  }
}

.arrowContainer {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}
