@import "assets/scss/variables";

.wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  height: 0;
  min-height: 0;
  margin: 20px 0;
  padding: 10px;
  border-radius: 12px;
  background-color: $gray-244;
}

.tableContainer {
  overflow: auto;
  height: 100%;
  padding: 0 10px;

  input[type=checkbox] {
    accent-color: white;

    &:before {
      position: absolute;
      width: 11px !important;
      height: 11px !important;
      content: "";
      cursor: pointer;
      border: 1px solid black !important;
      border-radius: 2px;
    }
  }
}

.virtuoso {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #CBCBCB;
  }
}

.table {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: fit-content;
  height: 100%;
  min-height: 200px;

  thead {
    position: sticky;
    z-index: 20;
    top: 0;
  }

  tbody {
    height: 100%;
  }

  .row {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid $gray-146;

    &:hover {
      background: $gray-249;
    }

    &.heading {
      border-bottom: 2px solid $gray-146 !important;
      user-select: none;

      &:hover {
        background: none;
      }

      .cell {
        font-weight: 800;
        text-transform: capitalize;
        text-overflow: ellipsis;
        min-height: 44px;
        color: #181d1f;

        // TODO this style is neccessary becaouse of globbaly applied style in other tables
        // this should be removed after migrating all the other tables
        // (shared/Table/Table.module.scss)
        border-bottom: 0 !important;

        .header {
          display: flex;
          max-width: 100%;
          white-space: nowrap;
          gap: 5px;

          .title {
            flex: 0 1 auto;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }
      }
    }

    .cell {

      display: flex;
      overflow: hidden;
      align-items: center;
      flex: 1 1 auto;
      min-height: 54px;
      margin: 0;
      padding: 0 20px;
      color: #383838;

      &.actionCell {
        flex: 0 0 auto;
        overflow: visible;
        .header .title {
          overflow: visible;
        }
      }

      p {
        overflow: hidden;
        text-align: left;
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (max-width: 500px) {
        padding: 0 10px;
      }
    }
  }

  .noResult {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .skeleton {
    height: 25px;
    min-width: 25px;
    flex: 1;
    animation-name: placeHolderShimmer;
    animation-duration: 1.25s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    border-radius: toRem(12);
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 19%, #eeeeee 100%);
    background-size: 800px 104px;
    animation-fill-mode: forwards;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #CBCBCB;
  }
}

.paginationWrap {
  font-size: 13px;
  display: flex;
  overflow: visible;
  align-items: flex-end;
  flex: 0 1 auto;
  justify-content: center;
  margin: 0 auto;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}