@import "../../../../assets/scss/variables";

.checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;

  &_label {
    display: inline-block;
    vertical-align: middle;
    font-size: toRem(13);
    margin-left: .5rem;
  }

  &:checked {
    + .checkbox_marker {
      .checkbox_marker_square {
        display: none;
      }

      .checkbox_marker_check {
        display: block;
      }
    }
  }

  + .checkbox_marker {
    width: toRem(16);
    height: toRem(16);
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    flex-shrink: 0;

    .checkbox_marker_check {
      display: none;
    }

    .checkbox_marker_square {
      display: block;
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &_errorMessage {
    color: $wcm_danger;
    font-size: 0.875rem;
  }
}

// Styles for checkboxes in dark containers
.checkbox_container.dark {
  .checkbox_label {
    color: $white;
  }
  .checkbox_marker {
    .checkbox_marker_square {
      color: $white;
    }
  }
}

// Styles for checkboxes in light containers
.checkbox_container:not(.dark) {
  .checkbox_label {
    color: $black;
  }
  .checkbox_marker {
    .checkbox_marker_square {
      color: $black;
    }
  }
}