@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/functions";


:global {
  :local(.dropdown) {
    line-height: toRem(16);
    font-size: toRem(13);
    font-family: $font-family-suisse;

    & .select {
      &__control {
        flex-wrap: nowrap;
        gap: 10px;
        border-bottom: none;
        cursor: pointer;
        &--menu-is-open {
          border-bottom: none;
        }
      }

      &__value-container {
        color: $gray-244;
      }

      &__menu {
        display: flex;
        background: white;
        margin-top: 5px;
        color: black;
        max-height: toRem(150);
        border: 1px solid $gray_light;
        border-radius: 6px;
        padding: toRem(10) 0;
        box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.20);
        width: 150px;

        &-list {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }

      &__option {
        color: $wcm_dark;
        display: flex;
        padding: 10px 18px 9px 18px;
        cursor: pointer;
        border-left: 2px solid white;
        text-transform: capitalize;
        &:hover {
          border-color: black;
          background-color: $gray-244;
        }
      }
    }
  }
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
 z-index: 1000;
  width: 30px;
  height: 30px;
}

.checkbox {
  -ms-transform: scale(1.5);
  transform: scale(1);

  &:before {
    position: absolute;
    left: 0;
    top: 0;
  }
}

input[type=checkbox] {
  accent-color: white;
}

input[type=checkbox]:before {
  content: "";
  width: 11px !important;
  height: 11px !important;
  border: 1px solid black !important;
  border-radius: 2px;
}
