@import "../../../assets/scss/variables";

.faq {
  width: 100%;
  z-index: 100000000000000 !important;

  .menu {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 1180px;
    border-bottom: 1px solid $gray_light;
    padding: toRem(15) 0;

    .item {
      font-weight: 600;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $gray;

      &.margin {
        margin-left: toRem(60);
      }
    }
  }

  .header {
    text-align: center;
    margin-top: toRem(30);
    font-weight: 800;
    font-size: toRem(28);
    line-height: toRem(34);
    font-family: $font-family-plaak;
    color: $white;
  }

  .content {
    z-index: 100000000000000 !important;
    max-width: toRem(979);
    width: 100%;
    margin: toRem(50) auto;
    box-sizing: border-box;

    .headTxt {
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(24);
      font-family: $font-family-suisse;
      color: $white;
    }

    .txt {
      margin-top: toRem(10);
      margin-bottom: toRem(35);
      font-weight: 400;
      font-size: toRem(16);
      line-height: toRem(20);
      font-family: $font-family-suisse;
      color: $white;

      .link {
        color: $wcm_yellow;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .faq {
    .menu {
      max-width: toRem(1140);
      margin: 0 toRem(20);
    }
  }
}

@media screen and (max-width: 1024px) {
  .faq {
    .header {
      margin-top: toRem(10);
    }

    .menu {
      max-width: 100%;
      margin: 0 toRem(112);
    }

    .content {
      //max-width: rem(800);
      margin: toRem(40) auto;
    }
  }
}

@media screen and (max-width: 990px) {
  .faq {
    .menu {
      margin: 0 toRem(74);
    }
  }
}

@media screen and (max-width: 768px) {
  .faq {
    .menu {
      margin: 0 toRem(74);
    }

    .content {
      //max-width: rem(620);
      margin: toRem(30) auto;
    }
  }
}

@media screen and (max-width: 660px) {
  .faq {
    .content {
      //max-width: rem(340);
      margin: toRem(50) auto;
    }

    .menu {
      margin: 0 toRem(17.5);

      .item {
        &.margin {
          margin-left: toRem(20);
        }
      }
    }
  }
}
