.button {
  height: 35px;

  &:disabled {
    cursor: not-allowed;
  }
}

.approve {
  &:disabled {
    color: rgba(146, 146, 146, 1);
  }
}

.danger {
  &:disabled {
    @apply bg-gray-lighter border-gray-lighter text-wcm-gray;
  }
}

.default {
  &:focus,
  &:hover {
    background-color: #323232;
    border-color: #323232;
  }

  &:disabled {
    @apply bg-gray-lighter border-gray-light text-wcm-gray;
  }
}

.gray {
  &:disabled {
    @apply bg-gray-lighter border-gray-light text-wcm-gray;
  }
}

.primary {
  &:disabled {
    @apply bg-gray-lighter border-gray-lighter text-wcm-gray;
  }
}

.text {
  &:disabled {
    @apply text-wcm-gray
  }
}
