.modal {
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  width: 850px;
  padding: 40px;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  .announcementForm,
  .announcementReview {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 26px;
      line-height: 32px;
      margin: 0;
      color: black;
    }
  }

  .announcementForm {
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      section {
        display: flex;
        gap: 20px;
      }

      #announcmentMessage {
        height: 70px;

      }

      #timeZone {
        flex: 1 1 auto
      }


      .block {
        display: flex;
        flex-direction: column;
        gap: 0;
        width: 100%;

        .linkContainer {
          display: flex;
          width: 100%;
          flex-direction: row;
          gap: 20px;
          .linkValue {
            flex: 3 0;
          }
        }

        &>div {
          margin-top: 10px;
          width: 100%;
          flex-direction: row;
        }

        h2 {
          display: inline-flex;
          gap: 5px;
          align-items: baseline;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 5px;
          color: black;

          span {
            color: #646464;
            font-style: italic;
            font-size: 13px;
            line-height: 16px;
            font-weight: 400;
          }
        }

        h3 {
          font-size: 11px;
          line-height: 13px;
          font-weight: 400;
          margin: 0;
          color: #646464;
        }
      }

      .datesContainer {
        display: flex;
        align-items: center;
        gap: 10px;

        .arrow {
          margin: 0 10px
        }

      }
    }
  }

  .announcementReview {
    .reviewHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: 25px;

      .announcementButtonGroup {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-end;

        .announcementButton {
          gap: 7px;
        }
      }

      .subtitle {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #383838;
        margin: 0;
      }
    }

    .container {
      border: 2px solid #ccc;
      padding: 20px;
      gap: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;

      .row {
        display: flex;
        align-items: flex-start;
        gap: 40px;

        .column {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .columnTitle {
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
            color: black;
            margin: 0;
          }
          .columnDescription {
            display: flex;
            font-size: 13px;
            line-height: 16px;
            gap: 10px;
            color: black;
            font-weight: 400;
          }
          .announcementMessage {
            line-break: anywhere;
          }
          &.statusContainer {
            margin-left: auto;
          }
          .linkContainer {
            p {
              text-decoration: underline;
            }

            a {
              color: #383838;
              display: inline-block;
              max-width: 500px;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .buttonGroup {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 25px;
    justify-content: flex-end;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25) !important;
  z-index: 1;
}

.noWrap {
  white-space: nowrap;
}
