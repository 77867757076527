@import "../../../../assets/scss/variables";

.textField {
  border: 1px solid transparent;
  outline: none;
  background-color: $black;
  color: $white;
  font-size: 1rem;
  padding-left: 1rem;
  height: toRem(33);
  width: 100%;

  &_label {
    color: $wcm_gray;
  }

  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_wrapper {
    position: relative;
    border: 1px solid $gray_light;
    display: flex;
  }

  &_icon {
    position: absolute;
    right: 1rem;
    width: .82rem;
    height: .82rem;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    &_warning {
      path {
        fill: $wcm_danger;
      }
    }
  }

  &_inValid {
    border-bottom: 1px solid $wcm_danger;
  }

  &_isValid {
    &:not(:placeholder-shown) {
      border-bottom: 1px solid $wcm_green;

      + span {
        .textField_icon_success {
          display: block;
        }
      }
    }
  }

  &_errorMessage {
    color: $wcm_danger;
    font-size: 0.875rem;
  }

  &_toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: toRem(5);
    font-size: 1rem;
  }

  &_eye {
    width: 15px;
    height: 13px;
    margin-right: 8px;

    &_close {
      width: 17px;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0 1000px $black inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.textField_showText {
  display: flex;
  justify-content: space-between;
}

.disabled {
  color: $gray-146 !important;
  background-color: inherit !important;
  opacity: 1 !important;
  border-radius: 0 !important;
  border: 1px solid #CBCBCB !important;
}
