@import "assets/scss/variables";

.code {
  font-size: 11px;
}

.actions {
  display: flex;
  align-items: flex-start;

  .wrapButton {
    display: flex;
    align-items: center;
    margin-left: toRem(60);
    cursor: pointer;
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    padding: 0;

    &:hover {
      p {
        color: $orange;
      }

      svg path {
        fill: $orange;
      }
    }

    p {
      @media (max-width: 750px) {
        font-size: toRem(11) !important;
      }
    }

    @media (max-width: 750px) {
      background: #000000;
      border: 1px solid #929292;
      border-radius: 6px;
      padding: 6px 10px;
      margin-left: 0;
      &:hover {
        border: 1px solid $orange;
      }
      &:nth-of-type(1) {
        margin-right: 20px;
      }
    }
  }

  .next {
    margin-left: toRem(8);
    transform: rotate(90deg);
  }

  .back {
    margin-right: toRem(8);
    transform: rotate(-90deg);
  }

  .disabled {
    &:hover {
      p {
        color: $gray-146;
      }

      svg path {
        fill: $gray-146;
      }
    }

    color: $gray-146;
    cursor: no-drop;

    path {
      fill: $gray-146
    }
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: toRem(55);
  margin-top: 20px;

  p {
    font-size: toRem(16);
    font-weight: 600;
    @media (max-width: 750px) {
      font-size: toRem(13);
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: toRem(50);
  }
  @media (max-width: 750px) {
    margin-bottom: toRem(20);
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0 !important
    }
  }
}

.backButton {
  display: flex;
  align-items: center;
  color: white;

  &:hover {
    p {
      color: $orange;
    }

    svg path {
      fill: $orange;
    }
  }

  .backIcon {
    margin-right: 10px;
  }

  @media (max-width: 750px) {
    padding: 0;
    margin-right: 10px;
  }
}

.hasToolTip {
  :global(.payeeCodeLabel) {
    cursor: pointer;
    display: inline-block;

    &:hover {
      text-decoration: underline;
      color: $orange;
    }
  }
}

.reactTooltip {
  padding: 10px !important;
  border-radius: 0 !important;
  font-weight: 600;
  width: 120px !important;
  font-size: toRem(11) !important;

  span {
    //display: inline-block;
  }

  &_underline {
    margin-top: 5px;
    font-weight: 400;
  }

  &.show {
    opacity: 1;
  }
}

.button {
  &_showOnMobile {
    display: none;
  }

  @media (max-width: 1024px) {
    width: toRem(60);
    height: toRem(50);
    padding: 0;
    svg {
      margin-left: 0;
      width: toRem(10);
      border-width: toRem(2);

      path {
        fill: white;
      }
    }
  }
  @media (max-width: 990px) {
    width: toRem(40);
    height: toRem(35);
  }
  @media (max-width: 750px) {
    &_showOnMobile {
      display: block;
    }
    &_showOnDesktop {
      display: none;
    }
    right: 0;
    bottom: 0;
    width: auto;
    height: toRem(35);
    padding: 0 toRem(15);
    z-index: 1;
    svg {
      display: block;
    }
    span {
      display: none;
    }
  }

  &:hover {
    background-color: $wcm_yellow;

    svg path {
      fill: white
    }
  }

  &:disabled {
    background-color: transparent;
  }
}

.input {
  &_wrapper {
    margin-left: 180px;
    @media (max-width: 750px) {
      margin-left: 0;
    }
  }

  width: 400px;
  @media (max-width: 1024px) {
    width: 300px;
  }
  @media (max-width: 990px) {
    width: 225px;
  }

  &_change {
    @media (max-width: 750px) {
      &:focus {
        border: 2px solid $gray-203;
      }
      width: 100% !important;
      border: 2px solid $gray-146;
    }
  }
}

.table {
  &_nodata {
    :global(.ag-body-viewport.ag-row-animation.ag-layout-auto-height) {
      background-color: rgba(255, 255, 255, 1);
    }

    :global(.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper) {
      font-size: 16px;
      font-style: italic;
      color: black;
    }
  }

  :global(.ag-center-cols-container) {
    min-height: 55px !important;
    height: 55px !important;
  }

  :global(.ag-center-cols-viewport) {
    min-height: 55px !important;
  }

  :global(.ag-center-cols-clipper) {
    min-height: 55px !important;
  }

  &_title {
    color: white;
    font-size: toRem(20);
    margin: 0;
  }

  &_subtitle {
    color: white;
    font-size: toRem(20);
    margin: 0;
    position: relative;

    &_info {
      font-size: toRem(13);
      margin-top: toRem(10);
      position: absolute;
      bottom: -25px;
      left: 0;
    }
  }

  &_noSearch {
    font-size: toRem(16);
    font-weight: 600;
  }

  &_subHeading {
    width: 215px;
    @media (max-width: 1024px) {
      width: auto;
    }
    @media (max-width: 750px) {
      width: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      align-items: center;
    }
  }

  &_head {
    display: flex;
    align-items: center;
    margin-bottom: toRem(20);
    @media (max-width: 750px) {
      justify-content: space-between;
      display: block;
    }

    &_changed {
      justify-content: space-between;

      .input_wrapper {
        margin-left: 0;
      }
    }

    &_pre {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
      min-height: 50px;
      @media (max-width: 750px) {
        min-height: 40px;
        display: block;
        position: sticky;
        position: -webkit-sticky;
        top: 155px;
        z-index: 2;
        align-self: flex-start;
      }
    }

    &_changed {
      @media (max-width: 750px) {
        display: flex !important;
        .input_wrapper {
          //width: 100%;
          //order: 3;
          //margin-top: 17px;
        }
        .table_subHeading {
          margin-bottom: 0 !important;
        }
      }
    }
  }


  :global(.ag-row-group-expanded) {
    border-bottom: none;
  }

  :global(.ag-group-contracted) {
    :global(.ag-icon ) {
      background-image: url("../../icons/chevrons_down.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-group-expanded ) {
    :global(.ag-icon ) {
      background-image: url("../../icons/chevrons_up.svg");
      display: inline-block;
      width: 12px;
      height: 13px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:before {
        display: none;
      }
    }
  }

  :global(.ag-theme-alpine) {
    font-family: $font-family-suisse;
  }

  :global(.ag-theme-alpine .ag-root-wrapper) {
    border: none;
  }

  :global(.ag-header) {
    border-bottom: 2px solid $gray-146;
    background-color: $gray-244;
    overflow: visible;
    color: black;

    :global(.ag-header-viewport) {
      overflow: visible;
    }
  }

  :global(.ag-header-cell) {
    padding-left: toRem(35);
    @media (max-width: 1024px) {
      padding-left: 15px;
    }

    &:first-child {
      padding-left: toRem(35);
      @media (max-width: 1024px) {
        padding-left: 15px;
      }
    }
  }

  &_detail {
    display: flex;
    padding: 0 toRem(173);
    justify-content: space-between;
    height: 55px;
    font-size: 11px;
    align-items: center;

    svg {
      width: 11px;
      height: 11px;

      path {
        fill: black;
      }
    }
  }

  &_row {
    border-bottom: 1px solid $gray-146;
    background-color: #fff !important;

    &:hover {
      background-color: $gray-249 !important;
    }

    :global(.ag-cell) {
      display: flex;
      align-items: center;
      white-space: break-spaces;
      font-size: 13px;
      padding-left: toRem(35);
      color: black;
      background-color: transparent !important;
      border: none !important;
      @media (max-width: 1024px) {
        padding-left: 15px;
      }
    }
  }
}

.select {
  &_searchable {
    :global(.select__control--menu-is-open) {
      background-image: url("../../img/filter_search.svg");
      background-position: calc(100% - 37px) center;
      background-size: 13px 13px;
      background-repeat: no-repeat;
      transition: none;

      :global(.select__single-value) {
        opacity: 0;
        transition: none;
      }

      :global(.select__dropdown-indicator) {
        transform: rotate(180deg);
      }
    }
  }

  min-width: 270px;
  //height: 50px;

  :global(.select__menu-notice--no-options) {
    color: white;
    text-align: left;
  }

  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    border-left: 0;
    background-image: url("../../img/option-check.svg");
    background-repeat: no-repeat;
    background-position: 1.5rem 0.8rem;
    padding-left: calc(1.5rem + 24px);
    position: relative;

    &:hover {
      background-color: $wcm_dark;
      border-left: 0;

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $orange;
      }
    }
  }

  :global(.select__menu) {
    top: calc(100% + 5px);
    border-top: 1px solid #CBCBCB;
    border-radius: toRem(6);
  }

  @media (max-width: 790px) {
    min-width: 127px;
  }
  @media (max-width: 750px) {
    min-width: 100%;
  }
}

.payeeCodeDropDown {
  margin-bottom: 40px;
  width: 270px;
  @media (max-width: 750px) {
    width: 100%;
    margin-top: 10px;
    position: sticky;
    top: 115px;
    z-index: 1;
    position: -webkit-sticky;
  }
}

.menu {
  width: 270px;
}

.payeeCodeBlock {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 40px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }

  &_nodata {
    text-align: center;
    width: 100%;
    color: white;

    h1 {
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: 600;
      margin-top: 0;
    }

    p {
      font-size: 16px;
      font-weight: 400;

      a {
        color: white;
        text-decoration: underline;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  color: white;

  &_big {
    padding-left: 0;
    margin-top: toRem(50);
  }
}

.wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 750px) {
    display: block;
  }
}

.selectLabel {
  font-size: 13px;

  .contractingParty {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 16px;
    white-space: nowrap;
  }
}

.names {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  //height: 16px;
  white-space: nowrap;
  line-height: toRem(16);
  color: black;

  &_gray {
    color: $wcm_dark;
  }
}

.agGridReact {
  max-height: 55px !important;
}

.ag-center-cols-container {
  height: 55px;
  min-height: 55px !important;
}

.infoText {
  font-size: 13px !important;
  font-weight: 600;

  &_underline {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $orange;
    }
  }
}