@import "../../../../../assets/scss/variables";

.container {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex: 1 0 auto;

  .downloadTypeSelector {
    display: flex;
    flex: 1;
    height: 100%;
    margin-right: 24px;
    transition: opacity .25s ease, transform .3s ease, height .1s ease;
    opacity: 0;
    gap: 24px;


    &.isMobile {
      justify-content: center;
      margin-right: 0;

      .typeOptions {
        gap: 24px;
      }

    }

    &.visible {
      height: toRem(35);
      transform: translateX(0);
      opacity: 1;

      &.isMobile {
        transform: translateY(0);
      }

    }

    &.hidden {
      height: 0;
      transform: translateX(100%);
      opacity: 0;

      &.isMobile {
        transform: translateY(100%);
      }
    }

    .description {
      display: flex;
      align-items: center;
      gap: 3px;

      .text {
        font-size: 13px;
        font-weight: 600;
        line-height: toRem(16);
        white-space: nowrap;
        color: $white;
      }

      .icon {
        width: 13px;
        height: 13px;
        cursor: pointer;
      }
    }

    .typeOptions {
      display: flex;
      align-items: center;
      gap: 10px;

      .option {
        display: flex;
        cursor: pointer;

        .radioLabel {
          font-size: 13px;
          font-weight: 400;
          color: $white;
        }

        .radioButton {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          border: 2px solid $orange;
          border-radius: 50%;
          background-color: transparent;

        }

        .input {
          visibility: hidden;
          width: 0;
          height: 0;
          margin: 0;

          &:checked ~ .radioButton {
            border-width: 5px;
          }
        }

      }
    }
  }
}



