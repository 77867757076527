@import "../../../../assets/scss/variables";

.select {
  height: 35px;

  &__control {
    border-bottom: 1px solid blue;
  }

  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    :global(.select__placeholder) {
      overflow: hidden;
      white-space: nowrap;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    @media (max-width: 750px) {
      &:global(.addShadow) {
        box-shadow: 0px -30px 40px 65px black;
      }
    }
  }

  &_errorMessage {
    color: $wcm_danger;
    font-size: 0.875rem;
  }
}

.whiteSelect {
  height: 25px;

  :global(.select__control) {
    padding: 0 10px 0 0;
  }

  :global(.select__indicator) {
    padding: 0px;
  }

  :global(.select__single-value) {
    font-size: 11px !important;
    padding: 0 2px;
  }
}

.isBig {
  height: 50px;
  @media (max-width: 990px) {
    height: 40px;
  }
}

.relative {
  position: relative;
}

.selectError {
  border-bottom: 1px solid red;
}

.errorMessage {
  color: red;
}

.label {
  color: $wcm_gray;
  font-size: 1rem;
  margin-bottom: toRem(5);

}

.labelIcon {
  margin-left: toRem(5);
}

#react-select-3-listbox {
  display: none !important;
}

.noOptions {
  color: white;
  display: flex;
  justify-content: flex-start;
  font-size: 11px;
  padding: 0;
  margin: 0;
  width: 100%;
}

