@import "assets/scss/variables";

.totalCount{
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: $white
}

.loading{
  filter: blur(1.5px);
  background-color: rgba(255,255,255,0.95);
}