@import "assets/scss/variables";

.managementNavBar {
  display: flex;
  height: 100%;
  align-items: center;

  .navBarItem {
    display: flex;
    align-items: center;
    margin-right: 32px;
    cursor: pointer;
    padding: 15px 0;

    .icon {
      margin-right: 10px;
    }

    .title {
      font-size: 16px;
      color: $gray;
    }

    &:hover, &.active {
      border-bottom: 1px solid $orange;
      border-top: 1px solid $white;

      .icon {
        path {
          fill: $orange;
        }
      }

      .title {
        color: $orange;
      }
    }
  }

  .burgerIcon {
    cursor: pointer;
  }
}

.open {
  border-bottom: 1px solid $orange;
  border-top: 1px solid $white;

  .icon {
    path {
      fill: $orange;
    }
  }

  .title {
    color: $orange !important;
  }
}

.tooltip {
  width: 280px;
  padding-top: toRem(20) !important;
  padding-bottom: toRem(20) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:global(.__react_component_tooltip.show) {
  & + :global(.burgerMenu) {
    background-color: $orange;

    svg path {
      fill: $white;
    }
  }
}

.user_info {
  padding-left: toRem(20);

  &_title {
    margin-bottom: toRem(5);
    display: inline-block;
    color: black;
  }

  &_role {
    color: $gray;
  }
}
.user_info {
  padding-left: toRem(20);

  &_title {
    margin-bottom: toRem(5);
    display: inline-block;
    color: black;
  }

  &_role {
    color: $gray;
  }
}

.itemContainer {
  margin-top: toRem(40);
}


.burgerMenu {
  padding: toRem(12);
  border-radius: toRem(6);
  cursor: pointer;
}