@import "assets/scss/variables";

.resetPassword {
  max-width: 580px;
  width: 100%;
  margin: 64px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $black;
  color: $white;
  border: 1px solid $orange;
  border-radius: 20px;
  padding: 100px 16px;
  margin-top: 0;

  .title {
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    text-align: center;
  }

  .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    max-width: 380px;
    margin-bottom: 36px;
  }

  .inputs {
    max-width: 380px;
    margin-bottom: 20px;
  }

  button {
    width: 180px;
  }
}

.actions {
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: 600px) {
    flex-direction: column;
  }

  .buttonCreate {
    width: fit-content;
  }
}

.link {
  font-size: 13px;
  color: $orange;
  border-bottom: 1px solid $orange;
}

.links {
  max-width: 267px;
  line-height: 16px;
  padding-right: 20px;
  @media (max-width: 600px) {
    max-width: 100%;
    margin-bottom: 15px;
    padding-right: 0;
    text-align: center;
  }
}

.error {
  max-width: 380px;
  margin-bottom: 20px;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 630px;
  background-color: $black;
  border: 1px solid $orange;
  border-radius: 20px;
  margin: 64px auto;
  padding: 50px 47px;

  .closeSuccess {
    width: fit-content;
    margin-top: 20px;
  }
}

.decor {
  width: toRem(129);
  margin-top: 0.5rem;
}

.iconWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: toRem(19);

  h4 {
    text-transform: uppercase;
    font-size: toRem(22);
    font-family: $font-family-plaak;
    margin: 0;
    text-align: center;
    font-weight: 800;
    line-height: 26px;
    color: $white;
  }
}

@media screen and (max-width: 768px) {
  .resetPassword {
    max-width: calc(100% - 32px);
    margin: 64px 16px;
    padding-top: 50px;
  }
  .success {
    max-width: calc(100% - 32px);
    margin: 64px 16px;
  }
}