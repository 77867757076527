@import "assets/scss/variables";

.table {
  display: table;
  width: 100%;

  &_row {
    display: flex;
    border-bottom: 1px solid #929292;
    align-items: center;
  }

  &_col {
    display: flex;
    width: 50%;
    align-items: center;
    height: 100%;
    padding: 22px 5px;

    &_text {
      font-size: toRem(16);
      word-break: break-word;
    }

    &_title {
      padding: 0;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.menu {
  width: 270px;
}

.songLabel {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  min-height: 24px;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: toRem(55);
  margin-top: 20px;

  p {
    font-size: toRem(16);
    font-weight: 600;
    @media (max-width: 750px) {
      font-size: toRem(13);
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: toRem(50);
  }
  @media (max-width: 750px) {
    margin-bottom: toRem(20);
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0 !important
    }
  }
}

.select {
  &_searchable {
    :global(.select__control--menu-is-open) {
      background-image: url("../../../img/filter_search.svg");
      background-position: calc(100% - 37px) center;
      background-size: 13px 13px;
      background-repeat: no-repeat;
      transition: none;

      :global(.select__single-value) {
        opacity: 0;
        transition: none;
      }

      :global(.select__dropdown-indicator) {
        transform: rotate(180deg);
      }
    }
  }

  max-width: 270px;
  height: 50px;
  margin-bottom: 40px;

  :global(.select__menu-notice--no-options) {
    color: white;
    text-align: left;
  }

  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    border-left: 0;
    background-image: url("../../../img/option-check.svg");
    background-repeat: no-repeat;
    background-position: 1.5rem 0.8rem;
    padding-left: calc(1.5rem + 24px);
    position: relative;

    &:hover {
      background-color: $wcm_dark;
      border-left: 0;

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $orange;
      }
    }
  }

  :global(.select__menu) {
    top: calc(100% + 5px);
    border-top: 1px solid #CBCBCB;
    border-radius: toRem(6);
  }

  @media (max-width: 990px) {
    max-width: 220px;
    margin-bottom: 20px;
    height: 40px;
  }
  @media (max-width: 750px) {
    max-width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 115px;
    z-index: 1;
  }
}

.actions {
  display: flex;
  align-items: flex-start;

  .wrapButton {
    &:hover {
      p {
        color: $orange;
      }

      svg path {
        fill: $orange;
      }
    }

    display: flex;
    align-items: center;
    margin-left: toRem(60);
    cursor: pointer;
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    padding: 0;

    p {
      @media (max-width: 750px) {
        font-size: toRem(11) !important;
      }
    }

    @media (max-width: 750px) {
      background: #000000;
      border: 1px solid #929292;
      border-radius: 6px;
      padding: 6px 9px;
      margin-left: 0;
      &:hover {
        border: 1px solid $orange;
      }
      &:nth-of-type(1) {
        margin-right: 20px;
      }
    }
  }

  .next {
    margin-left: toRem(8);
    transform: rotate(90deg);
  }

  .back {
    margin-right: toRem(8);
    transform: rotate(-90deg);
  }

  .disabled {
    &:hover {
      p {
        color: $gray-146;
      }

      svg path {
        fill: $gray-146;
      }
    }

    color: $gray-146;
    cursor: no-drop;

    path {
      fill: $gray-146
    }
  }
}

.info {
  color: white;
  display: flex;
  justify-content: space-between;

  .infoWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
    font-size: toRem(16);

    :global(.info-component-block) {
      @media (max-width: 1024px) {
        margin-bottom: 30px;
      }
    }

    :global(.payeeCodeTitle) {
      @media (max-width: 1024px) {
        margin-bottom: 10px;
        display: inline-block;
      }
    }

    @media (max-width: 1024px) {
      grid-template-columns:none;
      margin-bottom: 0;
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr 1fr;
      display: block;
    }
  }

  @media (max-width: 750px) {
    display: block;
  }
}

.infoWrapSingle {
  margin-bottom: 40px;
  font-size: toRem(16);

  :global(.payeeCodeTitle) {
    @media (max-width: 1024px) {
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  :global(.info-component-block) {
    @media (max-width: 1024px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns:none;
    margin-bottom: 0;
  }
}

.inormation {
  width: 45%;

  :global(.info-component-value) {
    font-size: 16px;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
}

.alternativeName {
  :global(.info-component-title) {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
}

.procent {
  :global(.info-component-title) {
    font-weight: 600;
    font-size: 1.5384615385rem;
    @media (max-width: 1024px) {
      font-size: toRem(16);
    }
  }

  :global(.info-component-head) {
    margin-bottom: 1.5384615385rem;
  }

  width: calc(55% - 30px);
  @media (max-width: 750px) {
    width: 100%;
  }

  .lane {
    height: 64px;
    display: grid;
    grid-template-columns: 2fr 1.5fr 0.3fr;
    align-items: center;
    border-bottom: 1px solid #929292;
    padding: 0 8px;
    font-size: toRem(16);
    @media (max-width: 1024px) {
      font-size: toRem(13);
    }

    &_title {
      border-bottom: 1px solid #929292;
      padding: 20px 8px;
      max-height: 68px;
      display: flex;
      align-items: center;
      height: 55px;

      :global(.info-component-head) {
        margin-bottom: 0;
      }

      :global(.info-component-block) {
        width: 100%;
      }

      @media (max-width: 750px) {
        padding: 0 8px;
        max-height: 100%;
        min-height: 55px;
        display: flex;
        align-items: center;
        :global(.info-component-block) {
          width: 100%;
        }
        :global(.info-component-head) {
          margin-bottom: 0;
        }
      }
    }

    &_nogrid {
      grid-template-columns: none;
      @media (max-width: 990px) {
        min-height: 55px;
      }
    }

    @media (max-width: 990px) {
      font-size: toRem(13) !important;
      height: auto;
      padding: 9px 2px;
    }
  }

  .header {
    border-bottom: 1px solid #929292;

    :global(.payeeCodeTitle) {
      font-size: toRem(20);
      font-weight: 600;
      margin-bottom: toRem(20) !important;
      display: inline-block;
      @media (max-width: 1024px) {
        margin-bottom: 0 !important;
      }
    }

    &_text {
      @media (max-width: 1024px) {
        margin-bottom: 20px !important;
      }
    }
  }

  .total {
    display: flex;
    justify-content: flex-end;
    height: 64px;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 90px;
    font-size: toRem(16);
    font-weight: 600;
    @media (max-width: 1024px) {
      margin-bottom: 60px;
    }
    @media (max-width: 990px) {
      font-size: toRem(13);
      margin-bottom: 48px;
      height: auto;
      margin-top: 13px;
    }
  }

  @media (max-width: 1024px) {
    margin-right: 0;
  }
}

.hasToolTip {
  :global(.payeeCodeLabel) {
    cursor: pointer;
    display: inline-block;

    &:hover {
      text-decoration: underline;
      color: $orange;
    }
  }
}

.reactTooltip {
  padding: 10px !important;
  border-radius: 0 !important;
  font-weight: 600;
  width: 120px !important;
  font-size: toRem(11) !important;

  span {
    //display: inline-block;
  }

  &.show {
    opacity: 1;
  }
}

.underline {
  //text-decoration: underline;
  font-size: toRem(11);
  margin-top: 5px;
  font-weight: 400;
}

.alternate {
  display: flex;

  &_column {
    width: 50%;
    @media (max-width: 1024px) {
      width: 40%;
      &:nth-last-child(2) {
        width: 60%;
      }
    }
    @media (max-width: 990px) {
      display: inline-block;
      width: 55%;
      &:nth-last-child(2) {
        width: 45%;
      }
    }
    @media (max-width: 750px) {
      display: inline-block;
      width: 100% !important;
      :global(.payeeCodeElement) {
        margin-right: 0;
      }
    }
  }
}

.backButton {
  display: flex;
  align-items: center;
  color: white;

  .backIcon {
    margin-right: 10px;
  }

  &:hover {
    p {
      color: $orange;
    }

    svg path {
      fill: $orange;
    }
  }

  @media (max-width: 750px) {
    padding: 0;
    margin-right: 10px;
  }
}

.infoText {
  font-weight: 600;

  &_underline {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $orange;
    }
  }
}