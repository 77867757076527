@import "assets/scss/variables";

.container {
  font-size: 13px;
  line-height: 16px;
  margin: 30px 0;
}

.label {
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}
