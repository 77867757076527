@import "assets/scss/variables";

.barWrap{
  max-height: 250px;
}

.innerPageBar{
  padding: 0 20px;
  margin-bottom: 5px;
  overflow: hidden;
}

.innerBar{
  width: 100% !important;
}

.info{
  display: flex;
  justify-content: space-between;
  padding: 14px 12px;
  border-radius: 6px;
  margin-bottom: 10px;
  color: $white
}

.index{
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  p{
    margin-right: 9px;
  }
}

.amount{
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: $white
}

.noPercentage{
  line-height: 26px;
  margin-bottom: 26px;
}

.wrap{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.percent{
  width: fit-content;
  font-size: 16px;
  line-height: 20px;
  color: $wcm_green174
}
.isIncrement{
  color: $wcm_danger
}

.hovered{
  color: $white;
  svg{
    path{
      fill: $white !important
    }
  }
}

@media screen and (max-width: 1270px) {
  .innerPageBar{
    padding: 0;
  }
}

@media screen and (max-width: 1023px) {
  .innerWrap{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    height: 100%;
    max-height: 220px !important;
    min-height: 220px !important;
  }
  .innerBar{
    height: 100%;
    max-height: 230px !important;
    min-height: 230px !important;
  }
  .percent{
    display: none;
  }
  .amount{
    font-size: 13px;
  }
  .info{
    align-items: center;
    height: 46px;
  }
  .index{
    font-size: 13px;
  }
}

@media screen and (max-width: 568px) {
  .innerWrap{
    grid-template-columns: 1fr;
    max-height: 100% !important;
    min-height: 100% !important;
    grid-gap: 10px;
  }
  .amount{
    font-size: 11px;
  }
  .index{
    font-size: 11px;
  }
}