.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  max-height: 100%;

  svg {
    max-height: 16px;
  }
}