@import "assets/scss/variables";

.setContactUsModal {
  text-align: center;
  width: fit-content;
}

.container {
  border: 1px solid $orange;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: white;
  background-color: black;
  width: 100%;
  max-width: 650px;
  min-width: 650px;
  margin-top: 30%;
  font-size: toRem(11);
  @media (max-width: 750px) {
    max-width: calc(100% - 20px);
    min-width: calc(100% - 20px);
    margin: 30% auto;
    max-height: 485px;
    overflow: hidden;
  }
}

.shadow {
  position: absolute;
  width: 100%;
  transition: opacity 150ms ease-in-out;
  height: auto;
  left: 0;
  z-index: 2;
  opacity: 0;

  &_bottom {
    top: 300px;
    box-shadow: 0 0px 70px 60px black;
  }

  &_top {
    top: 0;
    box-shadow: 0px 15px 70px 65px black;
  }
}

.wrap {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #CBCBCB;
    borderRadius: 1px;
    backgroundClip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #CBCBCB
  }

  @media (max-width: 750px) {
    max-height: 300px;
    z-index: 0;
    position: relative;
    &_shadowUp {
      position: relative;

      .shadow_top {
        opacity: 1;
      }
    }
    &_shadowDown {
      position: relative;

      .shadow_bottom {
        opacity: 1;
      }
    }
  }
}

.body {
  padding: toRem(40);
}

.title {
  font-size: toRem(18);
  font-weight: 600;
  position: relative;
  z-index: 3;
}

.subtitle {
  font-weight: 600;
  font-size: toRem(11);
  margin-top: toRem(10);
  margin-bottom: toRem(15);
  position: relative;
  z-index: 3;
}

.contactUsModal {
  width: fit-content !important;
  padding: 0 !important;
  margin: 0 auto;
  height: fit-content;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  @media (max-width: 750px) {
    width: 100% !important;
  }
}

.wrapper {
  position: relative;
}

.closeIcon {
  width: 16px;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  color: $white;
  z-index: 3;
  @media (max-width: 750px) {
    top: 60px;
  }
}

.hide {
  display: none;
}

.buttonsWrap {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
  }
}

.show {
  display: block;
}

.infoModal {
  border: 1px solid #D9A81B;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background-color: $black;
  width: 430.5px;
  height: 179px;
  margin-top: 50%;
  padding: 40px;
  color: $white;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  overflow-y: auto;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  z-index: 1000 !important;
  background-color: rgba(20, 20, 20, 0.65) !important;
  border: none !important;
  overflow-y: auto;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
