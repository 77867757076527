.ag {

  &-row {
    align-items: center;
  }

  &-wcm {
    &-grid {
      .ag-ltr .ag-cell {
        height: auto;
      }
    }
  }
}
// @import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// @import 'ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin';

// https://www.ag-grid.com/javascript-grid/themes-customising/ - can be used this way
// via @include ag-theme-{theme-name}

// $icons: aggregation, arrows, asc, cancel, chart,
//         checkbox-checked, checkbox-indeterminate,
//         checkbox-unchecked, color-picker, column,
//         columns, contracted, copy, cross, cut, data,
//         desc, expanded, eye-slash, eye, filter, first,
//         grip, group, indeterminate, last, left, loading,
//         maximize, menu, minimize, minus, next, none,
//         not-allowed, paste, pin, pivot, plus, previous,
//         radio-button-off, radio-button-on, right, save,
//         small-down, small-left, small-right, small-up,
//         tick, tree-closed, tree-indeterminate, tree-open;

$icons: asc, desc, none, filter;

@mixin icon-background($icon, $size) {
  // should be a path to a /public/resource to avoid webpack loaders
  background: url('../../../icons/grid/#{$icon}.svg') no-repeat;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: $size $size;
  height: $size;
  width: $size;
}

// WCM AGGrid reusable theme
// Providing joined .ag-theme-material.ag-theme-wcm for higher specificity
.ag-theme-material.ag-theme-wcm {
  // @include ag-theme-material(()); // not extending this way to simple support ag-theme-material as default

  $headerBgColor: #f4f4f4;
  $scrollbarBgColor: #a0a0a0;
  $scrollbarFgColor: #e2e2e2; // #f8f8f8;
  $scrollbarSize: 3px;
  $rowsSeparatorColor: #c4c4c4;
  $selectedRowFgColor: #f8f8f8;

  --ag-range-selection-border-color: transparent; // disable cell focus colour
  --ag-row-hover-color: #fafafa;
  --grid-grid-header-color: #f4f4f4;
  --grid-header-active-color: --grid-grid-header-color; // #e5e5e5;

  @apply font-suisse-intl text-13;

  .ag-root-wrapper {
    border: 0;
    border-radius: 0;
  }

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ag-header-cell-resize:hover {
    align-items: center;
    display: flex;
    &:after {
      background-color: rgba(186, 191, 199, 0.5);
      background-color: var(--ag-header-column-resize-handle-color, rgba(186, 191, 199, 0.5));
      content: '';
      display: block;
      height: 30%;
      left: calc(50% - 1px);
      position: absolute;
      top: 35%;
      width: 2px;
      z-index: 1;
    }
  }

  // Center content vertically within cells // important to have .ag-row { @apply flex items-center }
  .ag-cell {
    > .ag-react-container {
      @apply truncate;
    }
    &.ag-cell-normal-height {
      height: auto;
    }
  }

  .ag-header {
    border-bottom-color: $headerBgColor;
  }
  .ag-header-viewport {
    background-color: $headerBgColor;
    .ag-header-cell {
      @apply font-suisse-intl text-gray-darker text-13;
    }
  }

  .ag-row {
    border-color: $rowsSeparatorColor;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    @apply flex items-center; // Center content vertically within cells
  }
  .ag-full-width-row {
    align-items: inherit;
    display: block;
  }

  // Icons via font https://www.ag-grid.com/javascript-grid/custom-icons/#change-individual-icons-using-css
  // or via SVGs https://github.com/ag-grid/ag-grid-customise-theme
  @each $icon in $icons {
    .ag-icon.ag-icon-#{$icon} {
      @include icon-background($icon, 14px);
      &:before {
        content: '' !important;
      }
    }
  }

  // Show unSorter icon on hover only
  .ag-header-cell .ag-sort-none-icon {
    display: none;
  }
  .ag-header-cell.ag-header-active .ag-sort-none-icon {
    display: inline-block;
  }

  // Checkbox styles
  // .ag-checkbox-input-wrapper {
  //   &::after {
  //     @apply text-gray-light;
  //   }
  //   &.ag-checked::after {
  //     @apply text-wcm-accent;
  //   }
  // }
  .ag-checkbox-input-wrapper {
    @include icon-background('square', 18px);
    &:before {
      content: '' !important;
    }
    &::after {
      color: transparent;
    }
    &.ag-checked {
      @include icon-background('check-square', 18px);
      &:before {
        content: '' !important;
      }
      &::after {
        color: transparent;
      }
    }
  }

  .ag-row-selected {
    background-color: $selectedRowFgColor;
  }
  // .ag-row.ag-row-level-0 {
  //   background-color: transparent;
  // }
  .ag-row.ag-row-level-1.ag-row-hover {
    background-color: transparent;
  }

  // Prevent thick horizontal scroll
  // in case of specific issues check this https://github.com/ag-grid/ag-grid/issues/958

  .ag-center-cols-viewport {
    // height: 100%;
    overflow: hidden;
  }
  // https://github.com/ag-grid/ag-grid/issues/2911
  .ag-center-cols-viewport::-webkit-scrollbar {
    height: 0px;
  }

  .ag-body-viewport,
  .ag-body-horizontal-scroll-viewport {
    // scrollbar-color: $scrollbarFgColor $scrollbarBgColor;
    // scrollbar-width: thin;
    &::-webkit-scrollbar {
      background-color: $scrollbarFgColor;
      // display: none; // ToDo: Floating scroll
      height: $scrollbarSize; // 5px;
      width: $scrollbarSize; // 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbarBgColor;
      border: none;
      // border-radius: 20px;
      border-radius: 0;
      z-index: 100;
    }
    &::-webkit-scrollbar-track {
      // background-color: $scrollbarFgColor;
      background-color: transparent;
      border-radius: 0px;
    }
  }
  .ag-horizontal-right-spacer {
    max-width: $scrollbarSize !important;
    min-width: $scrollbarSize !important;
    width: $scrollbarSize !important;
  }
  .ag-body-horizontal-scroll-viewport:hover {
    height: 10px !important;
    margin-top: -5px;
    max-height: 10px !important;
    min-height: 10px !important;
    &::-webkit-scrollbar {
      background-color: $scrollbarFgColor;
      height: 10px;
    }
  }

  // .ag-body-viewport:hover,
  // .ag-body-horizontal-scroll-viewport:hover {
  //   &::-webkit-scrollbar {
  //     // display: inherit; // ToDo: Floating scroll
  //   }
  // }

  .ag-body-viewport.ag-layout-normal {
    // overflow-y: overlay; // ToDo: Floating scroll
    // z-index: 100; // Breaks indexing
  }

  .ag-body-horizontal-scroll {
    bottom: 0;
    position: absolute;
    z-index: 100;
  }

  // Loading animation
  .loadingRow .ag-cell::before {
    background: $headerBgColor;
  }

  // When initial loading mode is on
  .initial-loading {
    .ag-cell {

      padding: 17px;

      &::before {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        background: #f4f4f4; // rgb(224, 224, 224);
        border-radius: 7px;
        content: '';
        display: block;
        height: 20px;
        margin-bottom: auto;
        margin-top: auto;
        max-height: 20px;
        position: absolute;
        top: calc(50% - 10px);
        width: calc(100% - 40px);
        left: 14px;
      }

      > div,
      > span {
        display: none;
      }
      &-index {
        &:before {
          width: 60%;
        }
      }
    }
    .ag-header-icon {
      display: none;
    }
    .ag-header-cell-text {
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      background: #fff;
      border-radius: 7px;
      color: transparent;
      padding: 2px 25px 2px 0;
    }
    .ag-header-cell-text:empty {
      display: none;
    }
    // .ag-body-viewport {
    //   &::-webkit-scrollbar {
    //     display: none;
    //   }
    // }
    // /* stylelint-disable */
    // .ag-body-horizontal-scroll-viewport {
    //   display: none;
    // }
    // /* stylelint-enable */
  }

  // Columns config panel
  .ag-icon.ag-icon-columns {
    @include icon-background('columns', 16px);
    &:before {
      content: '' !important;
    }
  }
  .panel {
    @apply shadow-sm border-gray-lighter;
  }
  .opened .ag-icon-columns {
    @include icon-background('cols-accent', 16px);
  }
  .panel .ag-checkbox-input-wrapper {
    input[type='checkbox' i] {
      cursor: pointer !important;
    }
    @include icon-background('square', 14px);
    &:before {
      content: '' !important;
    }
    &::after {
      @apply text-transparent;
    }
    &.ag-checked {
      @include icon-background('check-square', 14px);
      &:before {
        content: '' !important;
      }
      &::after {
        @apply text-transparent;
      }
    }
  }
}

.ag-theme-wcm {
  // WCM AGGrid cells theme

  .idCell {
    @apply font-suisse-intl text-wcm-accent text-13;
    &.ag-cell-value {
      overflow: inherit !important;
      text-overflow: inherit !important;
    }
    > .ag-react-container {
      overflow: inherit !important;
      text-overflow: inherit !important;
    }
  }
  .titleCell {
    @apply font-suisse-intl text-gray-darker text-13; // font-semibold;
  }
  .actionsCell {
    @apply absolute;
    display: none;
    left: inherit !important;
    min-height: 100%;
    pointer-events: none;
    right: 0 !important;
    width: inherit !important;
    z-index: 100;
    > .ag-react-container {
      height: 100%;
      overflow: initial !important;
      pointer-events: all;
      position: absolute;
      right: 0;
      width: 100px;
    }
  }
  .ag-row-hover .actionsCell {
    display: block;
  }

  // WCM AGGrid headers theme

  .no-overflow .ag-header-cell-label {
    overflow: initial !important;
  }

  .no-ellipsis {
    width: initial !important;
    .ag-header-cell-text {
      overflow: hidden;
      text-overflow: initial;
    }
  }
}
.ag-theme-alpine .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
  margin-left: 8px !important;
  vertical-align: middle;
}

.ag-center-cols-container {
  width: 100% !important;
}
.ag-theme-material.ag-theme-wcm .ag-body-horizontal-scroll {
  display: none;
}
