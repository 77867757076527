@import "../../../../assets/scss/variables";

.faq {
  z-index: 100000000000000 !important;
  margin: toRem(44) toRem(19) toRem(120) toRem(19);
  box-sizing: border-box;

  .headTxt {
    font-weight: 600;
    font-size: toRem(20);
    line-height: toRem(24);
    font-family: $font-family-suisse;
    color: $white;
  }

  .txt {
    margin-top: toRem(10);
    margin-bottom: toRem(35);
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(20);
    font-family: $font-family-suisse;
    color: $white;

    .link {
      color: $wcm_yellow;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
}
