.payeeCodeListContainer {
  color: #383838;
  margin-top: 15px;
  padding: 0 30px 15px;

  .title {
    margin-bottom: 13px;
    display: flex;
    gap: 6px;

    .fileIcon {
      margin-left: -2px;

      path {
        fill: #383838 !important;
      }
    }
  }
  .payeeCodeLists {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 13px;
    line-height: 16px;
    height: 220px;
    max-height: 220px;

    .dataHeader {
      display: flex;
      font-weight: 600;
    }
    .index {
      min-width: 30px;
      font-weight: 600;
    }
    .payeeCodeHeader {
      min-width: 140px;
    }
    .fileNameHeader {
      min-width: 390px;
    }
    .payeeCodeList {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 13px;
      line-height: 16px;
      height: 100%;

      .dataRow {
        margin-bottom: 20px;
        display: flex;
        min-height: 16px;
        height: 16px;
        overflow: hidden;
      }

      .payeeCode {
        min-width: 140px;
        text-decoration: underline;
        cursor: pointer;
        display: -webkit-box;
        padding-left: 5px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fileName {
        cursor: pointer;
        text-decoration: underline;
        max-width: 410px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background-color: #CBCBCB;
        border-radius: 8px;
      }
    }
  }
}
.toolTip {
  max-width: 500px;
  word-break: break-all;
}

.virtuoso {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CBCBCB;
    border-radius: 8px;
  }
}
