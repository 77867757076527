.container {
  display: flex;
  max-height: 200px;
  overflow: hidden;
  position: relative;
  transition: 0.75s linear;
}

.containerInit {
  max-height: 0;
}

.fullWidth {
  padding: 0 16px;
  width: 100%;
}

.nav {
  color: white;
  font-size: 12px;
  height: 100%;
  left: 0;
  line-height: 16px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.navButton {
  color: inherit;
  pointer-events: all;
  @media (max-width: 750px) {
    display: none;
  }
}

.navContainer {
  height: 100%;
}

.navControls {
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 750px) {
    top: 13px;
    transform: none;
  }
}

.navControlsContainer {
  height: 100%;
  position: relative;
}

.navPrevButton {
  transform: rotate(180deg);
}

.navText {
  font-weight: bold;
  min-width: 30px;
  text-align: center;
  @media (max-width: 750px) {
    text-align: right;
  }
}

.swiper {
  width: 100%;
}
