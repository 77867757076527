@import "assets/scss/variables";

.setAddUserModal {
  text-align: center;
}

.modal {
  max-width: 500px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  outline: none;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.3) !important;
  z-index: 1;
}

.title {
  font-weight: 600;
  line-height: 20px;
  color: $black;
  margin-bottom: 20px;
}

.description {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #929292;
  text-align: center;
}
.buttonsContainer{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 20px;
}



