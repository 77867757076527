@import "assets/scss/variables";

.column {
  display: grid;
  flex-shrink: 0;
  gap: 24px;
  height: fit-content;
  @media (min-width: 768px) {
    gap: 32px;
  }
}

.container {
  color: #878787;
}

.error {
  color: $white;
}

.row {
  display: grid;
  gap: 20px;
  @media (min-width: 1280px) {
    grid-template-columns: 1.4fr 1fr;
  }
}

.widget {
  background-color: #191919;
  border-radius: 6px;
  max-width: 100%;
  overflow: hidden;
  padding: 24px;
}
