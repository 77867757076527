.inputWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: rgba(146, 146, 146, 1);
  cursor: pointer;
  border: 1px dashed var(--gray-light, #CBCBCB);
  box-sizing: border-box;
  font-size: 13px;
  margin: 20px 0;

  &:hover {
    border: 1px solid rgba(217, 168, 27, 1);
  }
}

.uploadInput {
  position: relative;
  width: 100%;
  z-index: 1;
}


.descriptionWrap {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 0;
}

.iconUploader {
  margin-right: 16px;
  font-size: 26px;
}

.uploadTitle {
  position: relative;
  margin-left: 32px;
  padding-right: 15px;
  span {
    color: #D9A81B;
  }
  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(50%);

  }
}

input::file-selector-button {
  width: 100%;
  height: 50px;
  opacity: 0;
}



