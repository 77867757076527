@import "assets/scss/variables";

.rowWrap {
  max-height: 240px;
  overflow-y: auto;
  margin-top: 20px;

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.rowWrap::-webkit-scrollbar {
  width: 5px;
}

.rowWrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
}

.rowWrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #CBCBCB;
  background-color: #CBCBCB;
}

.iconsWrap {
  width: 25px;
  margin-left: 10px;

  svg {
    path {
      fill: $gray-146 !important;
      color: $gray-146 !important;
    }
  }
}

.iconsBig {
  display: flex;
  align-items: center;
  width: 20px;
  height: 16px;
}

.icons {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
}

.activityTitle {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $black;
}

.historyWrap {
  display: flex;
  align-items: center;

  .info {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: $wcm_dark
  }
}