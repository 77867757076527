@import '../../../../../assets/scss/variables';

ol {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;

  li {
    color: $wcm_dark;
    margin: 0;
    padding: 0;
    list-style: decimal inside;
    font-style: normal;
  }
}