@import "assets/scss/variables";

.contactUs {
  margin-top: 32px;
}

.container {
  color: $white;
  padding-bottom: 40px;
  .infoItemWithControl {
    margin: 0 0 4px;
  }
}

.hr {
  border-color: #929292;
  margin: 40px 0;
}

.link {
  color: $white;
  text-decoration: underline;
}

.saveButton {
  margin-top: 16px;
}

.textField {
  @media (min-width: 768px) {
    width: 220px;
  }
}
