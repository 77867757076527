@import "assets/scss/variables";


.payeeCodeDropDown {
  flex-shrink: 0;
  width: 100%;
  @media (min-width: 750px) {
    width: auto;
  }
}


.select {
  width: 270px;
  &_searchable {
    :global(.select__control--menu-is-open) {
      background-image: url("../../../../img/filter_search.svg");
      background-position: calc(100% - 37px) center;
      background-size: 13px 13px;
      background-repeat: no-repeat;
      transition: none;

      :global(.select__single-value) {
        opacity: 0;
        transition: none;
      }

      :global(.select__dropdown-indicator) {
        transform: rotate(180deg);
      }
    }
  }

  min-width: 270px;
  height: 50px;
  @media (max-width: 1024px) {
    height: auto;
  }

  :global(.select__menu-notice--no-options) {
    color: white;
    text-align: left;
  }

  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    border-left: 0;
    background-image: url("../../../../img/option-check.svg");
    background-repeat: no-repeat;
    background-position: 1.5rem 0.8rem;
    padding-left: calc(1.5rem + 24px);
    position: relative;
    @media (max-width: 990px) {
      background-position: 1rem 0.8rem;
      padding-left: calc(1rem + 24px);
    }

    &:hover {
      background-color: $wcm_dark;
      border-left: 0;

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $orange;
      }
    }
  }

  :global(.select__menu) {
    top: calc(100% + 5px);
    border-top: 1px solid #CBCBCB;
    border-radius: toRem(6);
    z-index: 21;
    @media (max-width: 990px) {
      width: 175px !important;
      font-size: 11px;
    }
    @media (max-width: 750px) {
      min-width: 100%;
    }
  }

  @media (max-width: 990px) {
    min-width: 175px;
    height: 40px;
  }
  @media (max-width: 750px) {
    min-width: 100%;
    height: 40px;
  }
}

.menu {
  width: 270px;
  //@media (max-width: 990px) {
  //  width: 175px;
  //}
}


.singlePayeeCode {
  &_block {
    color: white;
    display: flex;
    @media (max-width: 750px) {
      height: 40px;
      background-color: #000;
    }
    &:global(.addShadow) {
      @media (max-width: 750px) {
        box-shadow: 0px -30px 40px 65px black;
      }
    }
    svg {
      margin-right: 10px;
    }
    &_info {
      h4 {
        font-weight: 600;
        font-size: 13px;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.value {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  //height: 16px;
  white-space: nowrap;
}
@import "assets/scss/variables";


.payeeCodeDropDown {
  flex-shrink: 0;
  width: 100%;
  @media (min-width: 750px) {
    width: auto;
  }
}


.select {
  width: 270px;
  &_searchable {
    :global(.select__control--menu-is-open) {
      background-image: url("../../../../img/filter_search.svg");
      background-position: calc(100% - 37px) center;
      background-size: 13px 13px;
      background-repeat: no-repeat;
      transition: none;

      :global(.select__single-value) {
        opacity: 0;
        transition: none;
      }

      :global(.select__dropdown-indicator) {
        transform: rotate(180deg);
      }
    }
  }

  min-width: 270px;
  height: 50px;
  @media (max-width: 1024px) {
    height: auto;
  }

  :global(.select__menu-notice--no-options) {
    color: white;
    text-align: left;
  }

  :global(.select__option.select__option--is-selected) {
    background-color: transparent;
    border-left: 0;
    background-image: url("../../../../img/option-check.svg");
    background-repeat: no-repeat;
    background-position: 1.5rem 0.8rem;
    padding-left: calc(1.5rem + 24px);
    position: relative;
    @media (max-width: 990px) {
      background-position: 1rem 0.8rem;
      padding-left: calc(1rem + 24px);
    }

    &:hover {
      background-color: $wcm_dark;
      border-left: 0;

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $orange;
      }
    }
  }

  :global(.select__menu) {
    top: calc(100% + 5px);
    border-top: 1px solid #CBCBCB;
    border-radius: toRem(6);
    z-index: 21;
    @media (max-width: 990px) {
      width: 175px !important;
      font-size: 11px;
    }
    @media (max-width: 750px) {
      min-width: 100%;
    }
  }

  @media (max-width: 990px) {
    min-width: 175px;
    height: 40px;
  }
  @media (max-width: 750px) {
    min-width: 100%;
    height: 40px;
  }
}