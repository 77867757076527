@import "assets/scss/variables";

.button {
  align-items: center;
  display: flex;
  color: #cbcbcb;
  font-size: 16px;
  font-weight: 600;
  gap: 10px;
  line-height: 20px;
  padding: 0;
  &:hover {
    color: $orange;
  }
  &:hover path {
    fill: $orange;
  }
}
