@import "./assets/scss/variables";

.circleProgress {
  display: flex;
  align-items: center;
}

.label {
  //width: 60px;
  margin-right: 10px;
}

.progressWrap {
  width: 25px;
}
