@import "assets/scss/variables";

.container {
  align-items: center;
  background: black;
  border: 1px solid #929292;
  border-radius: 6px;
  color: $white;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  padding: 56px 40px;
  @media (max-width: 767px) {
    padding: 24px;
  }
  @media (max-width: 1024px) {
    display: block;
  }
}

.h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  max-width: 660px;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
}
