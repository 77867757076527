.searchBar {
  display: flex;
  align-items: center;
  margin-right: 44px;
  justify-content: flex-end;
  width: 100%;

  .loop {
    cursor: pointer;
  }

  .inputWrap {
    margin-right: 44px;
    width: 100%;
    max-width: 700px;
    height: 100%;
  }
}