@import "assets/scss/variables";

.container {
  margin: 30px 0 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .logo {
      display: flex;
      padding: 10px 0;

      .title {
        font-family: $font-family-plaak;
        font-size: 1.25rem;
        font-weight: 700;
        margin-left: .65rem;
        color: $black;
      }
    }
  }
}



