@import "assets/scss/variables";

.alert {
  margin-top: 20px;
  max-width: 100%;
  text-align: left;
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
}

.description {
  color: $white;
  font-size: 16px;
  line-height: 21px;
  margin: 20px auto 0;
  text-align: center;
}

.errorLink {
  border-bottom: 1px solid;
  border-color: $wcm_yellow;
  color: $wcm_yellow;
  &:focus,
  &:hover {
    border-color: $wcm_yellow_hover;
    color: $wcm_yellow_hover;
  }
}

.field {
  margin-top: 30px;
}

.forgotPassword {
  background-color: $black;
  border: 1px solid $orange;
  border-radius: 20px;
  color: $white;
  margin: 20px auto 64px;
  max-width: 580px;
  padding: 40px 30px;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 0;
    padding: 100px;
  }
}

.h1 {
  margin: 0 !important;
}

.success {
  background-color: $black;
  border: 1px solid $orange;
  border-radius: 20px;
  color: $white;
  margin: 64px auto;
  max-width: 580px;
  padding: 63px 16px 50px 16px;
  text-align: center;
}

.successCloseButton {
  margin-top: 20px;
}

.successInfo {
  font-size: 13px;
  line-height: 13px;
  margin-top: 40px;
}

.successLink {
  color: $wcm_yellow;
  text-decoration: underline;
  &:focus,
  &:hover {
    color: $wcm_yellow_hover;
  }
}

.toolbar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
  text-align: left;
  @media (min-width: 640px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.toolbarMainActions {
  display: flex;
  flex-direction: column;
  @media (min-width: 320px) {
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
  }
}
